import {
    Button,
    Select,
    Input,
    Space,
    Modal,
    Popconfirm,
    Switch,
    message,
    Popover,
    Table,
} from 'antd'
import {
    EditOutlined,
    DeleteOutlined,
    YoutubeFilled,
    PlayCircleOutlined,
    RedoOutlined,
    UserAddOutlined,
    LockFilled,
    NotificationOutlined,
} from '@ant-design/icons'
import Aquarius from '../../assets/horoscopeSymbol/Aquarius 1.svg'
import Pisces from '../../assets/horoscopeSymbol/Pisces 1.svg'
import Capricon from '../../assets/horoscopeSymbol/Capricon.svg'
import Sagittarius from '../../assets/horoscopeSymbol/Sagittarius.svg'
import Scorpio from '../../assets/horoscopeSymbol/Scorpio.svg'
import Libra from '../../assets/horoscopeSymbol/Libra.svg'
import Virgo from '../../assets/horoscopeSymbol/Virgo.svg'
import Leo from '../../assets/horoscopeSymbol/Leo.svg'
import Cancer from '../../assets/horoscopeSymbol/Cancer.svg'
import Gemini from '../../assets/horoscopeSymbol/Gemini.svg'
import Tarus from '../../assets/horoscopeSymbol/Tarus.svg'
import Aries from '../../assets/horoscopeSymbol/Aries.svg'

import { useEffect, useState } from 'react'
import {
    ActiveOptions,
    AddTypeOptions,
    ApprovalOptions,
    modalRoutes,
    platformOptions,
    typeOptions,
    statusOptions,
    ugctypeOptions,
    daysOptions,
    months,
} from './ManageContent.constant'
import {
    RoutesHeaders,
    RoutesPageHeaders,
    TableTypeFromRoutes,
    isReview,
} from '../App.constants'
import './ManageContent.scss'
import CommonTable from '../CommonTable/CommonTable'
import { useLocation, useNavigate } from 'react-router'
import ManageContentDateRange from './ManageContentDateRange'
import AddLanguage from '../language/AddLanguage'
import AddHashtag from '../Hashtag/AddHastag'
import AddCategory from '../Category/AddCategory'
import AddSubCategory from '../SubCategory/AddSubCategory'
import AddSource from '../Source/AddSource'
import { useSelector, useDispatch } from 'react-redux'
import {
    deleteCardRow,
    deleteCategoryRow,
    deleteEmployee,
    deleteFeature,
    deleteHashtagRow,
    deleteHtml,
    deleteLanguageRow,
    deletePollsRow,
    deleteQuizRow,
    deleteShortNewsRow,
    deleteshortVideoRow,
    deleteSourceRow,
    deleteDevotionalVideo,
    getAdvertisements,
    getCategoryById,
    getCategoryTableData,
    getEmployeeTableData,
    getReporterTableData,
    getFeatureData,
    getFeedbackTableData,
    getHashtagTableData,
    getLanguageTableData,
    getSourceTableData,
    searchDataByTitleAndType,
    getUgcContent,
    getAssemblyConstituencies,
    getDevotionalVideo,
    getAstrologyMasterData,
    getDailyHoroscope,
    getInHouseAdsData,
    deleteInhouseAdsRow,
    getSubCategories,
    getSubCategoryById,
    deleteSubCategories,
    updateContentLock,
    getRejectedReasons,
} from '../../AadhanApi'
import dayjs from 'dayjs'
import { Link } from 'react-router-dom'
import AddFeature from '../Feature/AddFeature'
import { categoryTypeOptions } from '../Category/AddCategory.constant'
import Viewicon from '../../assets/view_icon.svg'
import Uploadedicon from '../../assets/uploaded_icon.svg'
import PreviewCorousel from '../PreviewCorousel/PreviewCorousel'
import { addRejectedReasonData, changePopup } from '../../store/slice/AppSlice'
import HoroscopeTable from '../CommonTable/HoroscopeTable'
import { addWeeklyDates, addZodiacs } from '../../store/slice/HoroscopeSlice'
import {
    addPanchangamMonths,
    addPanchangamPaksha,
    addPanchangamSeasons,
    addPanchangamSolistices,
    addPanchangamYears,
} from '../../store/slice/PanchangamSlice'
const ManageContent = ({ showAddButton }) => {
    let year = useSelector((state) => state.horoscope.weeklyDatesRange)
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)
    const horoscopeType = [
        'Daily Horoscope',
        'Weekly Horoscope',
        'Monthly Horoscope',
        'Yearly Horoscope',
    ]

    const categories = useSelector((state) => state.app.categories)
    const popup = useSelector((state) => state.app.popup)
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const userLocation = useSelector((state) => state.app.userLocation)

    const locations = useSelector((state) => state.app.locations)
    let locationsData
    if (userLocation?.state && userLocation.state[0] !== '') {
        locationsData = locations.filter((e) =>
            userLocation?.state?.includes(e.state_id)
        )
    } else {
        locationsData = [{ name: 'All', state_id: 'All' }, ...locations]
    }

    const [assembly, setAssembly] = useState([])
    const [approvalOption, setSelectedApprovalOption] = useState('all')
    const [typeOption, setSelectedTypeOption] = useState('paid')

    const [statusOption, setSelectedStatusOption] = useState('submit')

    const [activeOption, setSelectedActiveOption] = useState('active')
    const [addTypeOption, setAddTypeOption] = useState('all')
    const [contentType, setContentType] = useState(
        categoryTypeOptions[0]?.value
    )
    //modified
    let optionsString = localStorage.getItem('options')
    let options = JSON.parse(optionsString)
    const [languageOption, setLanguageOption] = useState(options?.language)
    const [stateValue, setStateValue] = useState(options?.state)
    const [ugctypeOption, setSelectedUgcTypeOption] = useState(options?.ugType)
    const [selectedDistrictId, setSelectedDistrictId] = useState(
        options?.district
    )

    const [platformOption, setPlatformOption] = useState(
        platformOptions?.[0]?.value
    )
    const [horoscopeOption, setHoroscopeOption] = useState(horoscopeType[0])

    const location = useLocation()
    const [messageApi, contextHolder] = message.useMessage()
    const currentContentButton =
        RoutesHeaders[location?.pathname] ?? 'Add Short'
    const currentContentHeading =
        RoutesPageHeaders[location?.pathname] ?? 'Manage Short News'
    let currentTableType =
        TableTypeFromRoutes[location?.pathname] ?? 'shortnews'
    const navigate = useNavigate()

    const defaultContentRoute = 'shortNewsContent'
    const [showLanguageModal, setShowLanguageModal] = useState()
    const [showHashtagModal, setShowHashtagModal] = useState()
    const [showSourceModal, setShowSourceModal] = useState()
    const [showCategoryModal, setShowCategoryModal] = useState()
    const [showSubCategoryModal, setSubShowCategoryModal] = useState()
    const [showFeatureModal, setShowFeatureModal] = useState()
    const [shortNewsData, setShortNewsData] = useState([])
    const [inHouseAdsData, setInHouseAdsData] = useState([])
    const [featureTableData, setFeatureTableData] = useState([])
    const [feedbackDataResponse, setFeedbackDataResponse] = useState([])
    const [subCategorydata, setSubCategoryData] = useState([])
    const [shortVideoData, setShortVideoData] = useState([])
    const [devotionalVideoData, setDevotionalVideoData] = useState()
    const [dataLoading, setDataLoading] = useState(false)
    const [cardsData, setCardsData] = useState([])
    const [pollsData, setPollsData] = useState([])
    const [quizData, setQuizData] = useState([])
    const [languageTableData, setLanguageTableData] = useState([])
    const [htmlTableData, setHtmlTableData] = useState([])
    const [ugcData, setUgcData] = useState([])

    const [categoryOption, setCategoryOption] = useState(99999)
    const [subcategoryOption, setSubCategoryOption] = useState(99999)
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
    const [selectedLanguageRecord, setSelectedLanguageRecord] = useState(null)
    const [selectedHashtag, setSelectedHashtag] = useState(null)
    const [hashtagData, setHashtagData] = useState(null)
    const [categoryTableData, setCategoryTableData] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [selectedSubCategory, setSelectedSubCategory] = useState(null)
    const [selectedFeature, setSelectedFeature] = useState(null)
    const [selectedSource, setSelectedSource] = useState(null)
    const [sourceTableData, setSourceTableData] = useState(null)
    const [employeeTableData, setEmployeeTableData] = useState(null)
    const [reporterTableData, setReporterTableData] = useState(null)
    const [searchData, setSearchData] = useState('')
    const [searchMobileData, setSearchMobileData] = useState('')
    const [localStorageData, setLocalStorageData] = useState({})
    const [contentId, setContentId] = useState('')
    const [yearOption, SetYearOption] = useState(new Date().getFullYear())
    const [monthOption, setMonthOption] = useState(
        months[new Date().getMonth()].name
    )
    const allDistricts = useSelector((state) => state.app.districts)
    const [selectedSateId, setSelectedSateId] = useState('')

    const [districtData, setDistrictData] = useState([])
    const [districtName, setDistrictName] = useState()
    const [hashtagSearch, setHashtagSearch] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        setSearchData('')
        setHashtagSearch('')
        setShortNewsData([])
        setShortVideoData([])
        handleStateChange(options?.state)
    }, [location.pathname])
    const displayError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: errMessage,
            duration: 5,
        })
    }

    const generateYearOptions = () => {
        // SetYearOption(currentYear)
        if (year[0]) {
            let years = Object.keys(year[0])
            return years.map((year) => (
                <Option key={year} value={year}>
                    {year}
                </Option>
            ))
        }
    }

        const fetchRejectedReasons = async (language) => {
            const storedLanguageOption = JSON.parse(localStorage.getItem('options'))?.language;
            if ((location.pathname === "/home/ug" || location.pathname === "/home/review") && language !== storedLanguageOption) {
                try {
                    const rejectedDataResponse = await getRejectedReasons(language, accessToken);
                    dispatch(addRejectedReasonData(rejectedDataResponse?.data?.data?.rejected_reasons));
                } catch (error) {
                    console.error("Error fetching rejected reasons:", error);
                }
            }
        };
    
       

    
    

    const handleYearChange = (event) => {
        SetYearOption(event)
    }

    const handleMonthChange = (event) => {
        setMonthOption(months[event - 1].name)
    }

    const displayAuthenticationError = (errMessage) => {
        messageApi.open({
            type: 'error',
            content: (
                <div style={{ display: 'flex', gap: '5px' }}>
                    {errMessage}
                    <Link to="/">
                        Click here to login or will be redirected to Login page
                        in 5 seconds
                    </Link>
                </div>
            ),
            duration: 10,
        })
        setTimeout(() => {
            navigate('/')
        }, 5000)
    }

    const onReviewClicked = async (record) => {
        // const res = await updateContentLock(
        //     record.content_id,
        //     'shortnews',
        //     true,
        //     record.content_status,
        //     accessToken
        // )

        // if (!record?.isreviewing && !res?.data?.success) {
        //     const newExpiryTime = Date.now() + 4 * 60 * 1000 // timer in minutes
        //     setTimers((prev) => ({
        //         ...prev,
        //         [record?.content_id]: newExpiryTime,
        //     }))
        //     setUgcData((prevData) =>
        //         prevData.map((item) =>
        //             item?.content_id === record?.content_id
        //                 ? { ...item, isreviewing: true }
        //                 : item
        //         )
        //     )
        //     messageApi.open({
        //         type: 'info',
        //         content: 'Some one is already reviewing this content...',
        //         duration: 3,
        //     })
        //     dispatch(changePopup(false))
        // } else {
        location?.pathname !== '/home'
            ? navigate(
                  location.pathname === '/home/ug' &&
                      `${location?.pathname}Content?mode=edit&contentId=${record.content_id}`
              )
            : navigate(defaultContentRoute)
        // }
    }
    function monthToNumber(month) {
        if (month.toLowerCase() === 'january') {
            return 1
        } else if (month.toLowerCase() === 'february') {
            return 2
        } else if (month.toLowerCase() === 'march') {
            return 3
        } else if (month.toLowerCase() === 'april') {
            return 4
        } else if (month.toLowerCase() === 'may') {
            return 5
        } else if (month.toLowerCase() === 'june') {
            return 6
        } else if (month.toLowerCase() === 'july') {
            return 7
        } else if (month.toLowerCase() === 'august') {
            return 8
        } else if (month.toLowerCase() === 'september') {
            return 9
        } else if (month.toLowerCase() === 'october') {
            return 10
        } else if (month.toLowerCase() === 'november') {
            return 11
        } else if (month.toLowerCase() === 'december') {
            return 12
        } else {
            return null // Invalid month
        }
    }

    const [dailyHoroscopeData, setDailyHoroscopeData] = useState([])

    async function getDailHoroscopeData() {
        const currentMonth = monthToNumber(monthOption)
        const data = await getDailyHoroscope(
            accessToken,
            yearOption,
            currentMonth
        )
        setDailyHoroscopeData(data.data.data)
    }

    useEffect(() => {
        const astrologicalDataTypes = [
            'weeks_of_year',
            'zodiacs',
            'hindu_years',
            'hindu_months',
            'hindu_seasons',
            'hindu_solstices',
            'hindu_paksha',
        ]

        if (
            location.pathname === '/home/panchangam' ||
            location.pathname === '/home/horoscope' ||
            location.pathname === '/home/panchangam' ||
            location.pathname === '/home/daily-horoscope'
        ) {
            getDailHoroscopeData()
            astrologicalDataTypes.map((type) =>
                getAstrologData(accessToken, type)
            )
        }
    }, [location.pathname])

    const getAstrologData = async (accessToken, params) => {
        try {
            const dates = await getAstrologyMasterData(accessToken, params)
            if (params === 'weeks_of_year') {
                dispatch(addWeeklyDates([dates.data.data.data]))
            }
            if (params === 'zodiacs') {
                dispatch(addZodiacs(dates.data.data.data))
            }
            if (params === 'hindu_years') {
                dispatch(addPanchangamYears(dates.data.data.data))
            }
            if (params === 'hindu_months') {
                dispatch(addPanchangamMonths(dates.data.data.data))
            }
            if (params === 'hindu_paksha') {
                dispatch(addPanchangamPaksha(dates.data.data.data))
            }
            if (params === 'hindu_solstices') {
                dispatch(addPanchangamSolistices(dates.data.data.data))
            }
            if (params === 'hindu_seasons') {
                dispatch(addPanchangamSeasons(dates.data.data.data))
            }
        } catch (error) {
            console.log('error is ::::', error)
            setDataLoading(false)
            if (error?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get weekDays news data')
            }
        }
    }

    const getShortNewsTableData = async (searchText) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        let text = searchText === '' ? '' : searchData
        setDataLoading(true)
        try {
            const shortNewsDataResponse = await searchDataByTitleAndType(
                text,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                2000,
                accessToken
            )
            setShortNewsData(
                shortNewsDataResponse?.data?.sort((a, b) =>
                    dayjs(b?.uploaded_date).isAfter(dayjs(a?.uploaded_date))
                        ? 1
                        : -1
                )
            )
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get short news data')
            }
        }
    }

    const getFeatureTableData = async () => {
        setDataLoading(true)
        try {
            const featureDataResponse = await getFeatureData(
                languageOption,
                accessToken
            )
            setFeatureTableData(featureDataResponse?.data)
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get feedback data')
            }
        }
    }

    const getInHouseAdsTableData = async () => {
        setDataLoading(true)

        // if(approvalOption || languageOption || addTypeOption ){
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        currentTableType = 'inhousead'
        // setDataLoading(true)
        const text = searchData == '' ? '' : searchData
        try {
            const inHouseAdsDataResponse = await searchDataByTitleAndType(
                text,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                700,
                accessToken,
                addTypeOption
            )
            const updatedSearchResponse = inHouseAdsDataResponse?.data.map(
                (item) => {
                    return {
                        ...item,
                        uploaded_by_name: item.uploaded_by,
                    }
                }
            )

            setInHouseAdsData(updatedSearchResponse)

            setDataLoading(false)
        } catch (err) {
            console.log(err)
            setDataLoading(false)
        }
        // }else{
        // try {
        //     const inHouseAdsDataResponse = await getInHouseAdsData(languageOption, accessToken);
        //     setInHouseAdsData(inHouseAdsDataResponse?.data?.data);
        //     setDataLoading(false);
        //     } catch (err) {
        //         console.log('failed to fetch in-house ads', err);
        //         setDataLoading(false);
        //         if (err?.response?.status === 401) {
        //             displayAuthenticationError('You are unauthorised, Please login again');
        //         } else {
        //             displayError(err?.response?.data?.detail);
        //         }
        //     }
        // }
    }

    const getHtmlContentTable = async (searchText) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        let text = searchText == '' ? '' : searchData
        setDataLoading(true)
        try {
            const htmlContentTableResponse = await searchDataByTitleAndType(
                text,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )
            setHtmlTableData(
                htmlContentTableResponse?.data?.sort((a, b) =>
                    dayjs(b?.uploaded_date).isAfter(dayjs(a?.uploaded_date))
                        ? 1
                        : -1
                )
            )
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError(err?.response?.data?.detail)
            }
        }
    }

    const getAssemblyData = async (val) => {
        setDataLoading(true)
        try {
            const assemblyResponse = await getAssemblyConstituencies(
                val,
                accessToken
            )
            setAssembly(assemblyResponse?.data)
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get feedback data')
            }
        }
    }

    const getFeedbackTable = async () => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        try {
            const feedbackDataResponse = await getFeedbackTableData(
                platformOption,
                startDateFormat,
                endDateFormat,
                languageOption,
                accessToken
            )
            setFeedbackDataResponse(feedbackDataResponse?.data?.data)
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get feedback data')
            }
        }
    }

    const getShortVideoTableData = async (searchText) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        let text = searchText == '' ? '' : searchData
        setDataLoading(true)
        try {
            const shortVideoResponse = await searchDataByTitleAndType(
                text,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )
            setShortVideoData(
                shortVideoResponse?.data?.sort((a, b) =>
                    dayjs(b?.uploaded_date).isAfter(dayjs(a?.uploaded_date))
                        ? 1
                        : -1
                )
            )
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get short video data')
            }
        }
    }

    const getCardsData = async (searchText) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        let text = searchText === '' ? '' : searchData
        try {
            const cardsDataResponse = await searchDataByTitleAndType(
                text,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )
            setCardsData(cardsDataResponse?.data)

            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Cards data')
            }
        }
    }

    const getPollsData = async (searchText) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        let text = searchText === '' ? '' : searchData
        setDataLoading(true)
        try {
            const pollsDataResponse = await searchDataByTitleAndType(
                text,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )
            setPollsData(pollsDataResponse?.data)
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Polls data')
            }
        }
    }

    const getUgcData = async () => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        const stateName = locationsData.find(
            (lk) => lk.state_id === stateValue
        )?.name
        try {
            const ugcDataResponse = await getUgcContent(
                languageOption,
                statusOption,
                ugctypeOption,
                startDateFormat,
                endDateFormat,
                stateValue,
                accessToken,
                selectedDistrictId === 'Clear Filter' ? '' : selectedDistrictId,
                searchData
            )

            // setUgcData(
            //     ugcDataResponse?.data?.map((e, index) => {
            //         if (e.is_reviewing) {
            //             return { ...e, isreviewing: true }
            //         } else {
            //             return { ...e, isreviewing: false }
            //         }
            //     })
            // )
            setUgcData(ugcDataResponse?.data)
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setUgcData([])
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get UG Content')
            }
        }
    }

    const getDevotionalVideoData = async () => {
        setDataLoading(true)
        try {
            const devotionVideoResponse = await getDevotionalVideo(
                languageOption,
                accessToken
            )
            setDevotionalVideoData(devotionVideoResponse?.data)
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDevotionalVideoData([])
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Devotional Video Content')
            }
        }
    }

    const getQuizData = async (searchText) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        let text = searchText === '' ? '' : searchData
        setDataLoading(true)
        try {
            const quizDataResponse = await searchDataByTitleAndType(
                text,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )
            setQuizData(quizDataResponse?.data)
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Quiz data')
            }
        }
    }

    const getLanguageData = async () => {
        setDataLoading(true)
        try {
            const languageTableDataResponse = await getLanguageTableData(
                activeOption,
                accessToken
            )
            if (languageTableDataResponse?.data) {
                setLanguageTableData(languageTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get language data')
            }
        }
    }

    const getHashtagData = async () => {
        setDataLoading(true)
        try {
            const hashatgsTableResponse = await getHashtagTableData(
                languageOption,
                hashtagSearch,
                accessToken
            )
            if (hashatgsTableResponse?.data) {
                setHashtagData(hashatgsTableResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get HashTag data')
            }
        }
    }

    const getCategoryTable = async () => {
        setDataLoading(true)
        try {
            const categoryTableDataResponse = await getCategoryTableData(
                languageOption,
                contentType,
                activeOption,
                accessToken
            )
            if (categoryTableDataResponse?.data) {
                categoryTableDataResponse.data =
                    categoryTableDataResponse?.data?.map((category) => {
                        return {
                            ...category,
                            type_content: category.type_content?.join(),
                        }
                    })
                setCategoryTableData(categoryTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Catgeory data')
            }
        }
    }

    const getSubCategoryTable = async () => {
        setDataLoading(true)
        try {
            const subcategoryTableDataResponse = await getSubCategories(
                accessToken,
                languageOption,
                categoryOption
            )
            if (subcategoryTableDataResponse?.data) {
                setSubCategoryData(subcategoryTableDataResponse?.data?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Catgeory data')
            }
        }
    }

    const getSourceTable = async () => {
        setDataLoading(true)
        try {
            const sourceTableDataResponse = await getSourceTableData(
                languageOption,
                hashtagSearch,
                accessToken
            )
            if (sourceTableDataResponse?.data) {
                setSourceTableData(sourceTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get source data')
            }
        }
    }

    const getEmployeeTable = async () => {
        setDataLoading(true)
        try {
            const getEmployeeTableDataResponse = await getEmployeeTableData(
                activeOption,
                languageOption,
                accessToken,
                searchMobileData,
                searchName
            )
            if (getEmployeeTableDataResponse?.data) {
                setEmployeeTableData(getEmployeeTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Employees data')
            }
        }
    }

    const getReporterTable = async () => {
        setDataLoading(true)

        try {
            const getReporterTableDataResponse = await getReporterTableData(
                typeOption,
                searchMobileData,
                searchName,
                accessToken,
                selectedSateId,
                selectedDistrictId,undefined
            )
            if (getReporterTableDataResponse?.data) {
                setReporterTableData(getReporterTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Stringers data')
            }
        }
    }

    const getTableData = (tableType, searchResponse, searchInput) => {
        switch (tableType) {
            case 'shortnews':
                if (searchInput) {
                    setShortNewsData(searchResponse)
                } else {
                    getShortNewsTableData(searchInput)
                }
                break
            case 'shortvideo':
                if (searchInput) {
                    setShortVideoData(searchResponse)
                } else {
                    getShortVideoTableData(searchInput)
                }
                break
            case 'devotionalvideo':
                getDevotionalVideoData()
                break
            case 'ads':
                if (searchInput) {
                    const updatedSearchResponse = searchResponse.map((item) => {
                        return {
                            ...item,
                            uploaded_by_name: item.uploaded_by,
                        }
                    })

                    setInHouseAdsData(updatedSearchResponse)
                } else {
                    getInHouseAdsTableData()
                }
                break
            case 'card':
                if (searchInput) {
                    setCardsData(searchResponse)
                } else {
                    getCardsData(searchInput)
                }
                break
            case 'poll':
                if (searchInput) {
                    setPollsData(searchResponse)
                } else {
                    getPollsData(searchInput)
                }
                break
            case 'quiz':
                if (searchInput) {
                    setQuizData(searchResponse)
                } else {
                    getQuizData(searchInput)
                }
                break
            case 'ug':
                getUgcData()
                break
            case 'language':
                getLanguageData()
                break
            case 'hashtag':
                getHashtagData()
                break
            case 'categories':
                getCategoryTable()
                break
            case 'subcategories':
                getSubCategoryTable()
                break
            case 'source':
                getSourceTable()
                break
            case 'employees':
                getEmployeeTable()
                break
            case 'stringers':
                getReporterTable()
                break
            case 'features':
                getFeatureTableData()
                break
            case 'feedback':
                getFeedbackTable()
                break
            case 'html':
                getHtmlContentTable(searchInput)
                break
            default:
                getShortNewsTableData()
                break
        }
    }

    const canMakeAPICall = () => {
        switch (location.pathname) {
            case '/home/languages':
                return activeOption
            case '/home/hashtags':
                return languageOption
            case '/home/categories':
                return activeOption && languageOption && contentType
            case '/home/subcategories':
                return activeOption && languageOption && contentType
            // case '/home/ads':
            //     return approvalOption || languageOption || addTypeOption;
            case '/home/sources':
                return languageOption
            case '/home/features':
                return languageOption
            case '/home/employees':
                return languageOption
            case '/home/stringers':
                return true
            case '/home/feedback':
                return (
                    platformOption &&
                    dates?.startDate &&
                    dates?.endDate &&
                    languageOption
                )
            default:
                return (
                    languageOption &&
                    (location.pathname.includes('/home/ug')
                        ? dates?.startDate ===
                          JSON.parse(localStorage.getItem('ug'))?.dates
                              ?.startDate
                        : location.pathname.includes('/home/devotionalVideo')
                        ? dates?.startDate ===
                          JSON.parse(localStorage.getItem('devotionalvideo'))
                              ?.dates?.startDate
                        : location.pathname.includes('/home/shortVideo')
                        ? dates?.startDate ===
                          JSON.parse(localStorage.getItem('shortvideo'))?.dates
                              ?.startDate
                        : location.pathname.includes('/home/quiz')
                        ? dates?.startDate ===
                          JSON.parse(localStorage.getItem('quiz'))?.dates
                              ?.startDate
                        : location.pathname.includes('/home/poll')
                        ? dates?.startDate ===
                          JSON.parse(localStorage.getItem('poll'))?.dates
                              ?.startDate
                        : location.pathname.includes('/home/html')
                        ? dates?.startDate ===
                          JSON.parse(localStorage.getItem('html'))?.dates
                              ?.startDate
                        : location.pathname.includes('/home/card')
                        ? dates?.startDate ===
                          JSON.parse(localStorage.getItem('card'))?.dates
                              ?.startDate
                        : location.pathname.includes('/home/ads')
                        ? dates?.startDate ===
                          JSON.parse(localStorage.getItem('ads'))?.dates
                              ?.startDate
                        : dates?.startDate ===
                          JSON.parse(localStorage.getItem('shortnews'))?.dates
                              ?.startDate) &&
                    categoryOption &&
                    approvalOption
                )
        }
    }

    useEffect(() => {
        const getContentData =
            localStorage.getItem(currentTableType) &&
            JSON.parse(localStorage.getItem(currentTableType))
        if (getContentData && Object.keys(getContentData)?.length) {
            const {
                approvalOption,
                languageOption,
                categoryOption,
                searchData,
                dates,
                addTypeOption,
            } = getContentData

            setSelectedApprovalOption(approvalOption)
            // setLanguageOption(languageData[0]?.language_id)
            setCategoryOption(categoryOption)
            setSearchData(searchData)
            addDates(dates?.startDate, dates?.endDate)
            setAddTypeOption(addTypeOption)
        } else {
            let obj = {
                approvalOption: 'all',
                languageOption: languageData[0]?.language_id,
                categoryOption: 99999,
                searchData: '',
                dates: {
                    startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                    endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                },
                addTypeOption: 'all',
            }
            localStorage.setItem(currentTableType, JSON.stringify(obj))
            setLocalStorageData(obj)
        }
    }, [location.pathname, localStorageData])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            localStorage.removeItem(currentTableType)
        }
        window.addEventListener('beforeunload', handleBeforeUnload)
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload)
        }
    }, [])

    //call API on value changed
    useEffect(() => {
        if (canMakeAPICall()) {
            getTableData(currentTableType)
        }
    }, [
        approvalOption,
        activeOption,
        languageOption,
        dates,
        categoryOption,
        platformOption,
        contentType,
        // location,
        statusOption,
        stateValue,
        ugctypeOption,
        selectedDistrictId,
        addTypeOption,
    ])

    const updateLocalStorageData = (type, value) => {
        const storedJsonString = localStorage.getItem(currentTableType)
        if (storedJsonString !== null) {
            const storedObj = JSON.parse(storedJsonString)
            storedObj[type] = value
            const updatedJsonString = JSON.stringify(storedObj)
            localStorage.setItem(currentTableType, updatedJsonString)
        }
    }

    const addDates = (startDate, endDate) => {
        let updatedDates = { startDate: startDate, endDate: endDate }
        setDates(updatedDates)
        updateLocalStorageData('dates', updatedDates)
    }
    const clearDates = () => {
        const updatedDates = {
            startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        }
        setDates(updatedDates)
        updateLocalStorageData('dates', updatedDates)
    }

    const handleApprovalChange = (event) => {
        setSelectedApprovalOption(event)
        updateLocalStorageData('approvalOption', event)
    }

    const handleTypeChange = (event) => {
        setSelectedTypeOption(event)
    }

    const handleStateChange = async (event) => {
        if (event !== 'All') {
            setDistrictName('')
            const id = allDistricts?.[event]?.find(
                (e) => e.district_id === options.district
            )
            if (id) {
                // Handle case when id is found
            } else {
                setSelectedDistrictId('')
            }
            setDistrictData('')
            setSelectedSateId(event)

            const districts = allDistricts?.[event] ?? []
            let filteredDistricts = []

            if (userLocation?.district && userLocation.district.length > 0) {
                if (userLocation.district[0] !== '') {
                    filteredDistricts = districts.filter((e) =>
                        userLocation.district.includes(e.district_id)
                    )
                } else {
                    filteredDistricts = [
                        { name: 'Clear Filter', district_id: 'Clear Filter' },
                        ...districts,
                    ]
                }
            } else {
                filteredDistricts = [
                    { name: 'Clear Filter', district_id: 'Clear Filter' },
                    ...districts,
                ]
            }

            setDistrictData([...filteredDistricts])
        }
    }

    const handleugcTypeChange = (event) => {
        setSelectedUgcTypeOption(event)
        options.ugType = event
        optionsString = JSON.stringify(options)
        localStorage.setItem('options', optionsString)
    }
    const handleStatusChange = (event) => {
        setSelectedStatusOption(event)
    }

    const handleActiveChange = (event) => {
        setSelectedActiveOption(event)
    }

    const handleLanguageChange = async(event) => {
        await fetchRejectedReasons(event);
        setCategoryOption(99999)
        setLanguageOption(event)
        options.language = event
        optionsString = JSON.stringify(options)
        localStorage.setItem('options', optionsString)
        updateLocalStorageData('languageOption', event)
    }

    const handleHoroscopeChange = (event) => {
        setCategoryOption(99999)
        setHoroscopeOption(event)
    }

    const handleCategoryChange = (event) => {
        setCategoryOption(event)
        updateLocalStorageData('categoryOption', event)
    }

    const handleAddAdTypeChange = (event) => {
        setAddTypeOption(event)
        updateLocalStorageData('addTypeOption', event)
    }

    const onSearch = async (event) => {
        if (currentTableType === 'ads') {
            currentTableType = 'inhousead'
        }
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setSearchData(event)
        updateLocalStorageData('searchData', event)
        setDataLoading(true)
        try {
            const response = await searchDataByTitleAndType(
                event,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken,
                addTypeOption
            )
            setDataLoading(false)
            if (currentTableType === 'inhousead') {
                getTableData('ads', response?.data, event)
            } else {
                getTableData(currentTableType, response?.data, event)
            }
        } catch (err) {
            setDataLoading(false)
            console.log(err)
        }
    }

    const onMobileNumberSearch = async (event) => {
        setSearchMobileData(event)
        setDataLoading(true)
        try {
            const getReporterTableDataResponse = await getReporterTableData(
                typeOption,
                event,
                searchName,
                accessToken
            )
            if (getReporterTableDataResponse?.data) {
                setReporterTableData(getReporterTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Stringers data')
            }
        }
    }
    const onNameSearch = async (event) => {
        setDataLoading(true)
        try {
            const getReporterTableDataResponse = await getReporterTableData(
                typeOption,
                searchMobileData,
                event,
                accessToken
            )
            if (getReporterTableDataResponse?.data) {
                setReporterTableData(getReporterTableDataResponse?.data)
            }
            setDataLoading(false)
        } catch (err) {
            console.log('error is ::::', err)
            setDataLoading(false)
            if (err?.response?.status === 401) {
                displayAuthenticationError(
                    'You are unauthorised, Please login again'
                )
            } else {
                displayError('Failed to get Stringers data')
            }
        }
    }

    const changeHandler = (event) => {
        setSearchData(event.target.value)
        // updateLocalStorageData('searchData', event.target.value)
    }

    const changeMobileHandler = (event) => {
        setSearchMobileData(event.target.value)
    }
    const [searchName, setSearchName] = useState('')
    const changeSearchHandler = (event) => {
        setSearchName(event.target.value)
    }

    const { Option } = Select
    const { Search } = Input

    const ShortVideoTableColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 450,
            sorter: (a, b) => a.title.localeCompare(b.title),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                    }}
                >
                    <img
                        onClick={() => onEditClicked(location, record)}
                        style={{ width: '4rem' }}
                        src={record?.image_url}
                        alt="video"
                    />
                    <p
                        style={{ color: 'blue', cursor: 'pointer' }}
                        href="/"
                        onClick={() => viewPopup(record.content_id)}
                    >
                        {record?.title}
                    </p>
                </div>
            ),
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            render: (_, record) =>
                dayjs(record?.uploaded_date).format('DD MMM YYYY, hh:mm a'),
        },
        {
            title: 'Posted By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: 'Proof Read By',
            key: 'proof_read_by',
            dataIndex: 'proof_read_by',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ marginRight: '5px' }}
                        onClick={() => onEditClicked(location, record)}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ marginLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]
    //panchangam table
    const panchangamTable = [
        {
            title: 'Date',
            dataIndex: 'Date',
            key: 'Date',
            width: 200,
            align: 'center',
        },
        {
            title: '\u00A0\u00A0\u00A0\u00A0\u00A0 Year, Month, Tithi, Nakshatram....',
            dataIndex: 'Add',
            key: 'Add',
        },
    ]

    //astrology table
    const horoscopeTable = [
        {
            title: Array(10).fill('\u00A0').join(''),
            dataIndex: 'day',
            key: 'day',
            width: '12rem',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Aries}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>1</p>
                </div>
            ),
            dataIndex: 'Aries',
            key: 'Aries',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Tarus}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>2</p>
                </div>
            ),
            dataIndex: 'Taurus',
            key: 'Taurus',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Gemini}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>3</p>
                </div>
            ),
            dataIndex: 'Gemini',
            key: 'Gemini',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Cancer}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>4</p>
                </div>
            ),
            key: 'Cancer',
            dataIndex: 'Cancer',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Leo}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>5</p>
                </div>
            ),
            key: 'Leo',
            dataIndex: 'Leo',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Virgo}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>6</p>
                </div>
            ),
            key: 'Virgo',
            dataIndex: 'Virgo',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Libra}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>7</p>
                </div>
            ),
            key: 'Libra',
            dataIndex: 'Libra',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Scorpio}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>8</p>
                </div>
            ),
            key: 'Scorpio',
            dataIndex: 'Scorpio',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Sagittarius}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>9</p>
                </div>
            ),
            key: 'Sagittarius',
            dataIndex: 'Sagittarius',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Capricon}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>10</p>
                </div>
            ),
            key: 'Capricon',
            dataIndex: 'Capricon',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Aquarius}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>11</p>
                </div>
            ),
            key: 'Aquarius',
            dataIndex: 'Aquarius',
        },
        {
            title: (
                <div style={{ height: '4rem' }}>
                    {' '}
                    <img
                        src={Pisces}
                        style={{ width: '3.1rem' }}
                        alt="fimg"
                    />{' '}
                    <p style={{ textAlign: 'center' }}>12</p>
                </div>
            ),
            key: 'Pisces',
            dataIndex: 'Pisces',
        },
    ]
    // const [timers, setTimers] = useState({})

    // useEffect(() => {
    //     const updateTimers = () => {
    //         const newTimers = {}
    //         ugcData.forEach((item) => {
    //             if (item.isreviewing) {
    //                 // const timeValue = parseInt(item.time)
    //                 // const timeUnit = item?.time?.split(' ')[1]
    //                 // // console.log(timeUnit)
    //                 let givenTimeString = item?.locked_time
    //                 let givenDate = new Date(givenTimeString)
    //                 givenDate = new Date(givenDate.getTime() + 4 * 60 * 1000)

    //                 let currentDate = new Date().toLocaleString('en-US', {
    //                     timeZone: 'Asia/Kolkata',
    //                 })
    //                 currentDate = new Date(currentDate) // Ensure currentDate is a Date object

    //                 // Calculate the difference in seconds
    //                 let differenceInMilliseconds = givenDate - currentDate
    //                 let differenceInSeconds = Math.floor(
    //                     differenceInMilliseconds / 1000
    //                 )
    //                 let durationInSeconds = differenceInSeconds
    //                 console.log(durationInSeconds)
    //                 if (durationInSeconds > 0) {
    //                     const expiryTime = Date.now() + durationInSeconds * 1000
    //                     newTimers[item.content_id] = expiryTime
    //                     //if positive start the time
    //                 } else {
    //                     //if negative timer completed

    //                     newTimers[item.content_id] = ''
    //                 }
    //             }
    //         })
    //         setTimers(newTimers)
    //     }

    //     updateTimers()
    // }, [dataLoading])

    // useEffect(() => {
    //     let tempflag = false
    //     const interval = setInterval(() => {
    //         const currentTime = Date.now()
    //         const temp = ugcData.map((item) => {
    //             if (
    //                 item.isreviewing &&
    //                 timers[item.content_id] <= currentTime
    //             ) {
    //                 tempflag = true
    //                 return { ...item, isreviewing: false }
    //             }
    //             return item
    //         })
    //         if (tempflag) {
    //             setUgcData(temp)
    //         }
    //         // Example: Update state based on tempflag
    //         if (tempflag) {
    //             // Perform some action based on tempflag
    //             // Update state or trigger some effect
    //         }
    //     }, 1000)

    //     return () => clearInterval(interval)
    // }, [timers, ugcData])
    // breaking_news
    const ugcColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: (_, record) => (
                <div className="ugc_title">
                    <div className="">
                        {/* <NotificationOutlined style={{color:"yellow",boxShadow:"2px 2px 2px red",fontSize:"1.5rem",fontWeight:"700"}} /> */}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                        }}
                    >
                        <p
                            style={{
                                color: 'blue',
                                margin: 0,
                                cursor: 'pointer',
                            }}
                            href="/"
                            onClick={() => viewPopup(record.content_id)}
                        >
                            {record?.title}
                        </p>
                        <span style={{ fontSize: '1.5rem' }}>
                            {record?.stream_video_url?.length ? (
                                <PlayCircleOutlined style={{ color: 'blue' }} />
                            ) : record?.yt_video_url ? (
                                <YoutubeFilled />
                            ) : (
                                ''
                            )}
                        </span>
                    </div>
                </div>
            ),
        },
        {
            title: 'Submitted Date',
            dataIndex: 'uploaded_date',
            render: (_, record) =>
                dayjs(record?.uploaded_date).format('DD MMM YYYY, hh:mm A'),
            key: 'uploadedDate',
        },
        {
            title: 'District',
            dataIndex: 'district_name',
            key: 'district_name',
        },
        {
            title: 'Constituency',
            dataIndex: 'constituency_names',
            key: 'constituency_names',
            render: (_, record) => (
                <span style={{ fontSize: '1rem' }}>
                    {record?.constituency_names?.join(', ')}
                </span>
            ),
        },
        {
            title: 'Rejected By',
            dataIndex: 'rejected_by',
            key: 'rejectedBy',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    {statusOption == 'rejected' && record.proof_read_by}
                </div>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
        },
        {
            title: `${
                statusOption == 'rejected'
                    ? 'Rejected Reason'
                    : statusOption == 'approved'
                    ? 'Status'
                    : 'Actions'
            }`,
            dataIndex: `${
                statusOption === 'rejected' ? 'rejected_reason' : 'action'
            }`,
            key: `${
                statusOption === 'rejected' ? 'rejected_reason' : 'action'
            }`,
            render: (_, record) => (
                <div size="middle">
                    {statusOption === 'rejected' ? (
                        <Popover
                            content={record?.rejected_reason}
                            title="Rejected Reason"
                        >
                            <Button className="review-btn">Rejected</Button>
                        </Popover>
                    ) : statusOption === 'approved' ? (
                        'Approved'
                    ) : (
                        isReview(userPermissions, 'Content', 'UG Content') && (
                            <div style={{ fontSize: '1.2rem' }}>
                                {/* <Button
                                    className="review-btn"
                                    onClick={() =>
                                        onReviewClicked(location, record)
                                    }
                                >
                                    Review
                                </Button> */}
                                {/* {!record.isreviewing ? ( */}
                                <Button
                                    className="review-btn"
                                    disabled={record.isreviewing}
                                    onClick={() => onReviewClicked(record)}
                                >
                                    {'Review'}
                                </Button>
                                {/* ) : (
                                    <Button
                                        style={{
                                            color: 'white',
                                            backgroundColor: 'red',
                                            border: 'none',
                                            padding: '5px 10px',
                                        }}
                                        // disabled={record.isreviewing}
                                        type="primary"
                                        onClick={() => onReviewClicked(record)}
                                    >
                                        In Review
                                    </Button>
                                )} */}
                            </div>
                        )
                    )}
                </div>
            ),
        },
    ]

    if (statusOption === 'submit' || statusOption === 'approved') {
        const rejectedByIndex = ugcColumns.findIndex(
            (column) => column.key === 'rejectedBy'
        )
        if (rejectedByIndex !== -1) {
            ugcColumns.splice(rejectedByIndex, 1)
        }
    }
    const [expandedRows, setExpandedRows] = useState({})
    const toggleExpanded = (key) => {
        setExpandedRows((prev) => ({
            ...prev,
            [key]: !prev[key],
        }))
    }

    const renderDescription = (description, key) => {
        const maxLength = 100
        const isExpanded = expandedRows[key]
        const shortDescription =
            description.length <= maxLength
                ? description
                : `${description.substring(0, maxLength)}...`

        return (
            <div>
                <p style={{ color: 'blue' }}>
                    {isExpanded ? description : shortDescription}
                </p>
                {description.length > maxLength && (
                    <div
                        onClick={() => toggleExpanded(key)}
                        style={{
                            color: 'blue',
                            border: 'none',
                            background: 'none',
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}
                    >
                        {isExpanded ? 'Show less' : 'Show more'}
                    </div>
                )}
            </div>
        )
    }

    const feedbackTableColumns = [
        {
            title: 'Review',
            sorter: (a, b) => a.review.localeCompare(b.review),
            sortDirections: ['ascend', 'descend'],
            render: (row, index) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                        }}
                    >
                        {row.image_url && (
                            <img
                                style={{ width: '4rem' }}
                                src={row.image_url}
                                alt="feedback_image"
                            />
                        )}
                        <p style={{ color: 'blue' }} href="/">
                            {renderDescription(
                                row.description,
                                index?.feedback_id
                            )}
                        </p>
                    </div>
                )
            },
            width: 350,
        },
        {
            title: 'Created At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (_, record) =>
                dayjs(record?.created_at).format('DD MMM YYYY'),
        },
        {
            title: 'Email ID',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Platform',
            dataIndex: 'platform',
            key: 'platform',
        },
        {
            title: 'Device Model',
            dataIndex: 'model',
            key: 'model',
        },
        {
            title: 'Brand',
            dataIndex: 'brand',
            key: 'brand',
        },
        {
            title: 'Os Version',
            dataIndex: 'os_version',
            key: 'os_version',
        },
        {
            title: 'App Version',
            dataIndex: 'app_version',
            key: 'app_version',
        },
    ]

    const handleCancel = () => {
        setShowLanguageModal(false)
    }

    const onEditClicked = (location, record) => {
        location?.pathname !== '/home'
            ? navigate(
                  location.pathname === '/home/employees'
                      ? `${location?.pathname}Content?mode=edit&employeeId=${record?.employee_id}`
                      : `${location?.pathname}Content?mode=edit&contentId=${record?.content_id}`
              )
            : navigate(defaultContentRoute)
    }

    const onEditReporter = (location, record) => {
        navigate(
            `${location?.pathname}Content?mode=edit&reporterId=${record?.user_id}`
        )
    }

    const openModal = (route) => {
        switch (route) {
            case '/home/languages':
                setShowLanguageModal(true)
                setSelectedLanguageRecord(null)
                break
            case '/home/hashtags':
                setShowHashtagModal(true)
                setSelectedHashtag(null)
                break
            case '/home/sources':
                setShowSourceModal(true)
                setSelectedSource(null)
                break
            case '/home/categories':
                setShowCategoryModal(true)
                setSelectedCategory(null)
                break
            case '/home/subcategories':
                setSubShowCategoryModal(true)
                setSelectedSubCategory(null)
                break
            case '/home/features':
                setShowFeatureModal(true)
                setSelectedFeature(null)
                break
            default:
                break
        }
    }

    const languageModal = (
        <Modal
            open={showLanguageModal}
            title="LANGUAGE INFORMATION"
            onCancel={handleCancel}
            closable={true}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    Cancel
                </Button>,
                <Button
                    form="languageForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Submit
                </Button>,
            ]}
        >
            <AddLanguage
                getLanguageData={getLanguageData}
                accessToken={accessToken}
                selectedLanguageRecord={selectedLanguageRecord}
                setShowModal={setShowLanguageModal}
            />
        </Modal>
    )

    const featureModal = (
        <Modal
            open={showFeatureModal}
            title="Add Feature"
            onCancel={() => setShowFeatureModal(false)}
            closable={true}
            footer={[
                <Button key="back" onClick={() => setShowFeatureModal(false)}>
                    Cancel
                </Button>,
                <Button
                    form="featureForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Submit
                </Button>,
            ]}
        >
            <AddFeature
                getFeatureTableData={getFeatureTableData}
                accessToken={accessToken}
                selectedFeature={selectedFeature}
                setShowModal={setShowFeatureModal}
            />
        </Modal>
    )

    const hashtagModal = (
        <Modal
            open={showHashtagModal}
            closable={true}
            title={selectedHashtag ? 'Edit Hashtag' : 'Add Hashtag'}
            onCancel={() => setShowHashtagModal(false)}
            footer={[
                <Button
                    form="hashtagForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Submit
                </Button>,
            ]}
        >
            <AddHashtag
                selectedHashtag={selectedHashtag}
                getHashtagData={getHashtagData}
                accessToken={accessToken}
                setShowModal={setShowHashtagModal}
            />
        </Modal>
    )

    const sourceModal = (
        <Modal
            open={showSourceModal}
            closable={true}
            title={selectedSource ? 'Edit Source' : 'Add Source'}
            onCancel={() => setShowSourceModal(false)}
            footer={[
                <Button
                    form="sourceForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Submit
                </Button>,
            ]}
        >
            <AddSource
                selectedSource={selectedSource}
                getSourceTable={getSourceTable}
                accessToken={accessToken}
                selectedLanguage={languageOption}
                setShowModal={setShowSourceModal}
            />
        </Modal>
    )

    const categoryModal = (
        <Modal
            open={showCategoryModal}
            title={selectedCategory ? 'Edit category' : 'Add Category'}
            onCancel={() => setShowCategoryModal(false)}
            closable={true}
            footer={[
                <Button
                    form="categoryForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Submit
                </Button>,
            ]}
        >
            <AddCategory
                selectedCategory={selectedCategory}
                getCategoryTable={getCategoryTable}
                accessToken={accessToken}
                setShowModal={setShowCategoryModal}
            />
        </Modal>
    )
    const subcategoryModal = (
        <Modal
            open={showSubCategoryModal}
            title={
                selectedSubCategory ? 'Edit  Sub category' : 'Add Sub Category'
            }
            onCancel={() => setSubShowCategoryModal(false)}
            closable={true}
            footer={[
                <Button
                    form="categoryForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Submit
                </Button>,
            ]}
        >
            <AddSubCategory
                selectedSubCategory={selectedSubCategory}
                getSubCategoryTable={getSubCategoryTable}
                accessToken={accessToken}
                setShowModal={setSubShowCategoryModal}
            />
        </Modal>
    )

    const deleteShortNews = async (record) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        const deleteResponse = await deleteShortNewsRow(
            record?.content_id,
            accessToken
        )

        if (deleteResponse?.data === 'Short news deleted successfully') {
            const shortNewsDataResponse = await searchDataByTitleAndType(
                searchData,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )

            setShortNewsData(shortNewsDataResponse?.data)
            setDataLoading(false)
        }
    }

    const deleteInhouseAds = async (record) => {
        try {
            setDataLoading(true)
            const deleteResponse = await deleteInhouseAdsRow(
                record.content_id,
                accessToken
            )

            if (deleteResponse?.status === 200) {
                getInHouseAdsTableData()
            }
            setDataLoading(false)
        } catch (err) {
            console.log('failed to delete inhouse ads', err)
        }
    }

    const deleteShortVideo = async (record) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        const deleteResponse = await deleteshortVideoRow(
            record?.content_id,
            accessToken
        )
        if (deleteResponse?.status === 200) {
            const shortVideoResponse = await searchDataByTitleAndType(
                searchData,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )
            setShortVideoData(shortVideoResponse?.data)
            setDataLoading(false)
        }
    }

    const deleteCard = async (record) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        const deleteResponse = await deleteCardRow(
            record?.content_id,
            accessToken
        )
        if (deleteResponse?.data === 'Card  deleted successfully') {
            const cardsDataResponse = await searchDataByTitleAndType(
                searchData,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )
            setCardsData(cardsDataResponse?.data)
            setDataLoading(false)
        }
    }

    const deleteCategory = async (record) => {
        setDataLoading(true)
        const languageId = languageData?.find(
            (l) => l.language_name === record.language_name
        )?.language_id
        const deleteResponse = await deleteCategoryRow(
            record?.category_id,
            languageId,
            accessToken
        )
        if (deleteResponse?.data === 'category deleted successfully') {
            getCategoryTable()
        }
        setDataLoading(false)
    }
    const deleteSubCategory = async (record) => {
        setDataLoading(true)
        const deleteSubCategory = await deleteSubCategories(
            accessToken,
            record?.sub_category_id
        )

        getSubCategoryTable()
        setDataLoading(false)
    }

    const deleteSources = async (record) => {
        setDataLoading(true)
        const deleteResponse = await deleteSourceRow(
            record?.source_id,
            accessToken
        )
        if (deleteResponse?.data === 'source deleted successfully') {
            getSourceTable()
        }
        setDataLoading(false)
    }

    const deleteEmployeeRow = async (record) => {
        setDataLoading(true)
        const deleteResponse = await deleteEmployee(
            record?.employee_id,
            accessToken
        )
        if (deleteResponse?.data === 'employee deleted successfully') {
            getEmployeeTable()
        }
        setDataLoading(false)
    }

    const deleteReporterRow = async (record) => {
        setDataLoading(true)
        const deleteResponse = await deleteEmployee(
            record?.employee_id,
            accessToken
        )
        if (deleteResponse?.data === 'reporter deleted successfully') {
            getReporterTable()
        }
        setDataLoading(false)
    }

    const deleteFeatureRow = async (record) => {
        setDataLoading(true)
        const deleteResponse = await deleteFeature(
            record?.feature_id,
            accessToken
        )
        if (deleteResponse?.data === 'Feature deleted successfully') {
            getFeatureTableData()
        }
        setDataLoading(false)
    }

    const deleteHtmlContent = async (record) => {
        setDataLoading(true)
        const htmlContentResponse = await deleteHtml(
            record?.content_id,
            accessToken
        )
        if (htmlContentResponse?.status == 200) {
            getHtmlContentTable()
        }
        setDataLoading(false)
    }

    const deletePolls = async (record) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        const deletePollResponse = await deletePollsRow(
            record?.content_id,
            accessToken
        )
        if (deletePollResponse?.status == 200) {
            const pollsDataResponse = await searchDataByTitleAndType(
                searchData,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )
            setPollsData(pollsDataResponse?.data)
            setDataLoading(false)
        }
    }

    const deleteQuiz = async (record) => {
        const startDateFormat = dayjs(dates?.startDate).format('YYYY-MM-DD')
        const endDateFormat = dayjs(dates?.endDate).format('YYYY-MM-DD')
        setDataLoading(true)
        const deleteQuizResponse = await deleteQuizRow(
            record?.content_id,
            accessToken
        )
        if (deleteQuizResponse?.data === 'quiz deleted successfully') {
            const deleteQuizResponse = await searchDataByTitleAndType(
                searchData,
                languageOption,
                currentTableType,
                categoryOption,
                approvalOption,
                startDateFormat,
                endDateFormat,
                1,
                500,
                accessToken
            )
            setQuizData(deleteQuizResponse?.data)
            setDataLoading(false)
        }
    }

    const deleteLanguage = async (record) => {
        setDataLoading(true)
        const deleteLanguageResponse = await deleteLanguageRow(
            record?.language_id,
            accessToken
        )
        if (deleteLanguageResponse?.data === 'language deleted successfully') {
            const langugaeRsponse = await getLanguageTableData(
                activeOption,
                accessToken
            )
            setLanguageTableData(langugaeRsponse?.data)
        }
        setDataLoading(false)
    }

    const deleteHashtag = async (record) => {
        setDataLoading(true)
        const deleteHashtagResponse = await deleteHashtagRow(
            record?.hashtag_id,
            accessToken
        )
        if (deleteHashtagResponse?.status === 200) {
            await getHashtagData()
        }
        setDataLoading(false)
    }

    const deleteVideo = async (record) => {
        setDataLoading(true)
        const deleteDevotionalVideoResponse = await deleteDevotionalVideo(
            record?.content_id,
            accessToken
        )
        if (deleteDevotionalVideoResponse?.status === 200) {
            await getDevotionalVideoData()
        }
        setDataLoading(false)
    }
    const onEditCategory = async (record) => {
        const languageId = languageData?.find(
            (l) => l.language_name === record.language_name
        )?.language_id
        const response = await getCategoryById(
            record.category_id,
            languageId,
            accessToken
        )

        setSelectedCategory(response?.data)
        setShowCategoryModal(true)
    }
    const onEditSubCategory = async (record) => {
        const response = await getSubCategoryById(
            accessToken,
            record.sub_category_id
        )

        setSelectedSubCategory(response?.data?.data)
        setSubShowCategoryModal(true)
    }

    const deleteRow = (location, record) => {
        switch (location?.pathname) {
            case '/home/add-short-news':
                deleteShortNews(record)
                break
            case '/home/shortNews':
                deleteShortNews(record)
                break
            case '/home/ads':
                deleteInhouseAds(record)
                break
            case '/home/card':
                deleteCard(record)
                break
            case '/home/poll':
                deletePolls(record)
                break
            case '/home/quiz':
                deleteQuiz(record)
                break
            case '/home/languages':
                deleteLanguage(record)
                break
            case '/home/hashtags':
                deleteHashtag(record)
                break
            case '/home/categories':
                deleteCategory(record)
                break
            case '/home/subcategories':
                deleteSubCategory(record)
                break
            case '/home/shortVideo':
                deleteShortVideo(record)
                break
            case '/home/sources':
                deleteSources(record)
                break
            case '/home/employees':
                deleteEmployeeRow(record)
                break
            case '/home/features':
                deleteFeatureRow(record)
                break
            case '/home/html':
                deleteHtmlContent(record)
                break
            case '/home/devotionalVideo':
                deleteVideo(record)
            default:
                break
        }
    }

    const viewPopup = (id) => {
        dispatch(changePopup(true))
        setContentId(id)
    }

    const ShortNewsTableColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <p
                        style={{ color: 'blue', margin: 0, cursor: 'pointer' }}
                        href="/"
                        onClick={() => viewPopup(record.content_id)}
                    >
                        {record?.title}
                    </p>
                    <span style={{ fontSize: '1.5rem' }}>
                        {record?.stream_video_url ? (
                            <PlayCircleOutlined style={{ color: 'blue' }} />
                        ) : record?.yt_video_url ? (
                            <YoutubeFilled />
                        ) : (
                            ''
                        )}
                    </span>
                </div>
            ),
            width: 100,
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            width: 100,
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: 100,
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            width: 120,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.uploaded_date).format(
                        'DD MMM YYYY, hh:mm a'
                    )}
                    {dayjs(record?.published_date).diff(dayjs(), 'min') > 0 && (
                        <div
                            style={{
                                backgroundColor: '#a4c9a4',
                                border: '0.5px solid black',
                                borderRadius: '5px',
                                paddingLeft: '4px',
                                width: '80%',
                            }}
                        >
                            Scheduled :{' '}
                            {dayjs(record?.published_date).format(
                                'DD MMM YYYY, hh:mm a'
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Proof Read By',
            key: 'proof_read_by',
            dataIndex: 'proof_read_by',
            width: 50,
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: 50,
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        onClick={() => onEditClicked(location, record)}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {' '}
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const ShortNewsColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <p
                        style={{ color: 'blue', margin: 0, cursor: 'pointer' }}
                        href="/"
                        onClick={() => viewPopup(record.content_id)}
                    >
                        {record?.title}
                    </p>
                    <span style={{ fontSize: '1.5rem' }}>
                        {record?.stream_video_url ? (
                            <PlayCircleOutlined style={{ color: 'blue' }} />
                        ) : record?.yt_video_url ? (
                            <YoutubeFilled />
                        ) : (
                            ''
                        )}
                    </span>
                </div>
            ),
            width: 100,
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            width: 100,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <p style={{ margin: '0px' }}>
                        {record?.created_type == 'reporter' && (
                            <img
                                style={{ width: '1rem', marginRight: '9px' }}
                                alt="ViewIcon"
                                src={Uploadedicon}
                            />
                        )}
                        {record?.uploaded_by}
                    </p>
                </div>
            ),
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            width: 100,
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            width: 120,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.uploaded_date).format(
                        'DD MMM YYYY, hh:mm a'
                    )}
                    {dayjs(record?.published_date).diff(dayjs(), 'min') > 0 && (
                        <div
                            style={{
                                backgroundColor: '#a4c9a4',
                                border: '0.5px solid black',
                                borderRadius: '5px',
                                paddingLeft: '4px',
                                width: '80%',
                            }}
                        >
                            Scheduled :{' '}
                            {dayjs(record?.published_date).format(
                                'DD MMM YYYY, hh:mm a'
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Proof Read By',
            key: 'proof_read_by',
            dataIndex: 'proof_read_by',
            width: 50,
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: 50,
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <a
                        href={record.dynamic_url}
                        target="blank"
                        className="view-icon"
                    >
                        <img
                            style={{ width: '1rem' }}
                            alt="ViewIcon"
                            src={Viewicon}
                        />
                    </a>
                    <EditOutlined
                        onClick={() => onEditClicked(location, record)}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {' '}
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const InHouseAdsColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            sorter: (a, b) => a.title.localeCompare(b.title),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <p
                        style={{ color: 'blue', margin: 0, cursor: 'pointer' }}
                        href="/"
                        onClick={() => viewPopup(record.content_id)}
                    >
                        {record?.title}
                    </p>
                    <span style={{ fontSize: '1.5rem' }}>
                        {record?.stream_video_url ? (
                            <PlayCircleOutlined style={{ color: 'blue' }} />
                        ) : record?.yt_video_url ? (
                            <YoutubeFilled />
                        ) : (
                            ''
                        )}
                    </span>
                </div>
            ),
            width: 100,
        },
        {
            title: 'Advertiser',
            dataIndex: 'advertiser_name',
            key: 'advertiser',
            width: 100,
        },
        {
            title: 'CTA Name',
            dataIndex: 'cta_name',
            key: 'cta_name',
            width: 100,
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            width: 100,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '5px',
                    }}
                >
                    <p style={{ margin: '0px' }}>
                        {record?.created_type == 'reporter' && (
                            <img
                                style={{ width: '1rem', marginRight: '9px' }}
                                alt="ViewIcon"
                                src={Uploadedicon}
                            />
                        )}
                        {record?.uploaded_by_name}
                    </p>
                </div>
            ),
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            width: 120,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.uploaded_date).format(
                        'DD MMM YYYY, hh:mm a'
                    )}
                    {dayjs(record?.published_date).diff(dayjs(), 'min') > 0 && (
                        <div
                            style={{
                                backgroundColor: '#a4c9a4',
                                border: '0.5px solid black',
                                borderRadius: '5px',
                                paddingLeft: '4px',
                                width: '80%',
                            }}
                        >
                            Scheduled :{' '}
                            {dayjs(record?.published_date).format(
                                'DD MMM YYYY, hh:mm a'
                            )}
                        </div>
                    )}
                </div>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            width: 50,
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    {/* <a
                        href={record.dynamic_url}
                        target="blank"
                        className="view-icon"
                    >
                        <img
                            style={{ width: '1rem' }}
                            alt="ViewIcon"
                            src={Viewicon}
                        />
                    </a> */}
                    <EditOutlined
                        onClick={() => onEditClicked(location, record)}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {' '}
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const DevotionalVideoTableColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            align: 'flex',
            sorter: (a, b) => a.title.localeCompare(b.title),
            sortDirections: ['ascend', 'descend'],
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        gap: '5px',
                        alignItems: 'center',
                    }}
                >
                    <img
                        onClick={() => onEditClicked(location, record)}
                        style={{ width: '4rem' }}
                        src={record?.thumbnailimage_url}
                        alt="video"
                    />
                    <p
                        style={{ color: 'blue', cursor: 'pointer' }}
                        href="/"
                        onClick={() => viewPopup(record.content_id)}
                    >
                        {record?.title}
                    </p>
                </div>
            ),
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'created_at',
            key: 'uploaded_date',
            width: 200,
            align: 'center',
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.created_at).format('DD MMM YYYY, hh:mm a')}
                </div>
            ),
        },
        {
            title: 'Days',
            dataIndex: 'days',
            key: 'days',
            align: 'center',
            render: (days) => (
                <span>
                    {days?.map((dayValue, index) => (
                        <span key={index} className="manage-days">
                            {daysOptions
                                .find((option) => option.value === dayValue)
                                ?.label.slice(0, 3)}{' '}
                        </span>
                    ))}
                </span>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ marginRight: '5px' }}
                        onClick={() => onEditClicked(location, record)}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ marginLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const languagesTableColumns = [
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: 'Regional name',
            dataIndex: 'regional_name',
            key: 'regional_name',
            align: 'center',
        },
        {
            title: 'Language',
            dataIndex: 'language_name',
            key: 'language_name',
            align: 'center',
        },
        {
            title: 'Date Created',
            // dataIndex: 'created_date',
            render: (_, record) => {
                return dayjs(record?.created_date).format(
                    'DD MMM YYYY, hh:mm a'
                )
            },
            key: 'created_date',
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ paddingRight: '5px' }}
                        onClick={() => {
                            setShowLanguageModal(true)
                            setSelectedLanguageRecord(record)
                        }}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const hashtagColumns = [
        {
            title: 'Hashtags',
            dataIndex: 'hashtag_name',
            key: 'hashtag_name',
            align: 'center',
        },
        {
            title: 'Language',
            dataIndex: 'language_name',
            key: 'language_name',
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ paddingRight: '5px' }}
                        onClick={() => {
                            setSelectedHashtag(record)
                            setShowHashtagModal(true)
                        }}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const categoryColumns = [
        {
            title: 'Category',
            sorter: (a, b) => a.category_name.localeCompare(b.category_name),
            sortDirections: ['ascend', 'descend'],
            render: (row) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                        }}
                    >
                        <img
                            style={{ width: '4rem' }}
                            src={row?.image_url}
                            alt="video"
                        />
                        <p
                            style={{ color: 'blue', cursor: 'pointer' }}
                            href="/"
                        >
                            {row?.category_name}
                        </p>
                    </div>
                )
            },
            width: 450,
        },
        {
            title: 'Language',
            dataIndex: 'language_name',
            key: 'language_name',
            align: 'center',
        },
        {
            title: 'Content Type',
            dataIndex: 'type_content',
            key: 'type_content',
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ paddingRight: '5px' }}
                        onClick={() => onEditCategory(record)}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]
    const subCategoryColumns = [
        {
            title: 'Sub Category',
            dataIndex: 'sub_category_name',
            key: 'sub_category_name',
        },
        {
            title: 'Language',
            key: 'language_id',
            align: 'center',
            render: (_, record) => {
                const language = languageData.find(
                    (language) => language.language_id === record.language_id
                )
                return language ? language.language_name : 'Unknown'
            },
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ paddingRight: '5px' }}
                        onClick={() => onEditSubCategory(record)}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const sourcesColumns = [
        {
            title: 'Source',
            dataIndex: 'source_name',
            key: 'source_name',
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ paddingRight: '5px' }}
                        onClick={() => {
                            setSelectedSource(record)
                            setShowSourceModal(true)
                        }}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const employeeTableColumns = [
        {
            title: 'Name',
            dataIndex: 'employee_name',
            key: 'employee_name',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: 'Role',
            dataIndex: 'role_name',
            key: 'role_name',
            align: 'center',
        },
        {
            title: 'Email ID',
            dataIndex: 'email_id',
            key: 'email_id',
            align: 'center',
        },
        {
            title: 'Mobile',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
            align: 'center',
        },
        {
            title: 'Language',
            dataIndex: 'language_name',
            key: 'language_name',
            align: 'center',
        },
        {
            title: 'Created Date',
            render: (_, record) =>
                dayjs(record?.created_date).format('DD MMM YYYY, hh:mm a'),
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ paddingRight: '5px' }}
                        onClick={() => {
                            onEditClicked(location, record)
                        }}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const reportersTableColumns = [
        {
            title: 'Name',
            dataIndex: 'full_name',
            key: 'full_name',
            align: 'center',
        },
        {
            title: 'Constituency',
            dataIndex: 'constituency',
            key: 'constituency',
            align: 'center',
            render: (_, record) => (
                <div>
                    {record?.reporter_information?.constituencies?.join(' ')}
                </div>
            ),
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            align: 'center',
            render: (_, record) => (
                <div>{record?.reporter_information?.state}</div>
            ),
        },
        {
            title: 'Mobile number',
            dataIndex: 'mobile_number',
            key: 'mobile_number',
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ paddingRight: '5px' }}
                        onClick={() => {
                            onEditReporter(location, record)
                        }}
                    />
                    {/* <Popconfirm
                        title="Block Row"
                        description="Are you sure to block this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >

                        <BlockOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm> */}
                </div>
            ),
        },
    ]

    const featureTableColumns = [
        {
            title: 'Feature Name',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
        },
        {
            title: 'Image',
            dataIndex: 'image_url',
            key: 'image_url',
            align: 'center',
            render: (_, record) => (
                <img
                    alt="feature"
                    style={{ width: '4rem' }}
                    src={record?.image_url}
                />
            ),
        },
        {
            title: 'Type',
            dataIndex: 'feature_type',
            key: 'feature_type',
            align: 'center',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
        },
        {
            title: 'Ranking',
            dataIndex: 'rank',
            key: 'rank',
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <div size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        style={{ paddingRight: '5px' }}
                        onClick={() => {
                            setShowFeatureModal(true)
                            setSelectedFeature(record)
                        }}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <DeleteOutlined style={{ paddingLeft: '5px' }} />
                    </Popconfirm>
                </div>
            ),
        },
    ]

    const htmlTableColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            align: 'center',
            render: (_, record) => (
                <p
                    style={{ color: 'blue', margin: 0, cursor: 'pointer' }}
                    onClick={() => viewPopup(record.content_id)}
                >
                    {record?.title}
                </p>
            ),
        },
        {
            title: 'Url',
            dataIndex: 'web_url',
            key: 'web_url',
            align: 'center',
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            align: 'center',
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            key: 'category_name',
            align: 'center',
        },
        {
            title: 'Uploaded Date',
            dataIndex: 'uploaded_date',
            key: 'uploaded_date',
            width: 120,
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                    }}
                >
                    {dayjs(record?.uploaded_date).format(
                        'DD MMM YYYY, hh:mm a'
                    )}
                    {record?.published_date > record?.uploaded_date &&
                        new Date(record?.published_date) >
                            new Date(
                                dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                            ) && (
                            <div
                                style={{
                                    backgroundColor: '#a4c9a4',
                                    border: '0.5px solid black',
                                    borderRadius: '5px',
                                    paddingLeft: '4px',
                                    width: '80%',
                                }}
                            >
                                Scheduled :{' '}
                                {dayjs(record?.published_date).format(
                                    'DD MMM YYYY, hh:mm a'
                                )}
                            </div>
                        )}
                </div>
            ),
        },
        {
            title: 'Proof Read By',
            dataIndex: 'proof_read_by',
            key: 'proof_read_by',
            align: 'center',
        },
        {
            title: 'Actions',
            key: 'actions',
            align: 'center',
            render: (_, record) => (
                <Space size="middle" style={{ fontSize: '1.2rem' }}>
                    <EditOutlined
                        onClick={() => {
                            onEditClicked(location, record)
                        }}
                    />
                    <Popconfirm
                        title="Delete Row"
                        description="Are you sure to delete this row?"
                        onConfirm={() => deleteRow(location, record)}
                        okText="Yes"
                        cancelText="No"
                    >
                        {' '}
                        <DeleteOutlined style={{ paddingLeft: '16px' }} />
                    </Popconfirm>
                </Space>
            ),
        },
    ]
    const [current, setCurrent] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const getTable = (route) => {
        switch (route) {
            case '/home/shortVideo':
                return (
                    <CommonTable
                        data={shortVideoData}
                        columns={ShortVideoTableColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/devotionalVideo':
                return (
                    <CommonTable
                        data={devotionalVideoData}
                        columns={DevotionalVideoTableColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/ads':
                return (
                    <CommonTable
                        data={inHouseAdsData}
                        columns={InHouseAdsColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/card':
                return (
                    <CommonTable
                        data={cardsData}
                        columns={ShortNewsTableColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/poll':
                return (
                    <CommonTable
                        data={pollsData}
                        columns={ShortNewsTableColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/quiz':
                return (
                    <CommonTable
                        data={quizData}
                        columns={ShortNewsTableColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/hashtags':
                return (
                    <CommonTable
                        data={hashtagData}
                        columns={hashtagColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/feedback':
                return (
                    <CommonTable
                        data={feedbackDataResponse}
                        columns={feedbackTableColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/languages':
                return (
                    <CommonTable
                        data={languageTableData}
                        columns={languagesTableColumns}
                        dataLoading={dataLoading}
                        disablePagination={true}
                    ></CommonTable>
                )
            case '/home/categories':
                return (
                    <CommonTable
                        data={categoryTableData}
                        columns={categoryColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/subcategories':
                return (
                    <CommonTable
                        data={subCategorydata}
                        columns={subCategoryColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/sources':
                return (
                    <CommonTable
                        data={sourceTableData}
                        columns={sourcesColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/employees':
                return (
                    <CommonTable
                        data={employeeTableData}
                        columns={employeeTableColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/horoscope':
                return (
                    <HoroscopeTable
                        token={accessToken}
                        columns={horoscopeTable}
                        dataLoading={dataLoading}
                        setDataLoading={setDataLoading}
                        language={languageOption}
                        year={yearOption}
                        month={monthOption}
                        horoscopeType={horoscopeOption}
                        option={'horoscope'}
                        setLanguageOption={setLanguageOption}
                    ></HoroscopeTable>
                )
            case '/home/panchangam':
                return (
                    <HoroscopeTable
                        token={accessToken}
                        option={'panchangam'}
                        columns={panchangamTable}
                        dataLoading={dataLoading}
                        setDataLoading={setDataLoading}
                        language={languageOption}
                        year={yearOption}
                        month={monthOption}
                        horoscopeType={horoscopeOption}
                    ></HoroscopeTable>
                )
            case '/home/stringers':
                return (
                    <CommonTable
                        data={reporterTableData}
                        columns={reportersTableColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/features':
                return (
                    <CommonTable
                        data={featureTableData}
                        columns={featureTableColumns}
                        dataLoading={dataLoading}
                        disablePagination={true}
                    ></CommonTable>
                )
            case '/home/html':
                return (
                    <CommonTable
                        data={htmlTableData}
                        columns={htmlTableColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
            case '/home/ug':
                return (
                    // <CommonTable
                    //     data={ugcData}
                    //     columns={ugcColumns}
                    //     dataLoading={dataLoading}
                    // ></CommonTable>
                    <Table
                        pagination={{
                            current: current,
                            pageSize: pageSize,
                            total: ugcData.length,
                            showSizeChanger: true,
                            pageSizeOptions: ['5', '10', '20', '50'],
                            showQuickJumper: true,
                            onChange: (page, pageSize) => {
                                setCurrent(page)
                                setPageSize(pageSize)
                            },
                        }}
                        columns={ugcColumns}
                        dataSource={ugcData}
                        loading={dataLoading}
                    />
                )
            default:
                return (
                    <CommonTable
                        data={shortNewsData}
                        columns={ShortNewsColumns}
                        dataLoading={dataLoading}
                    ></CommonTable>
                )
        }
    }

    const getPreviewData = (route) => {
        switch (route) {
            case '/home/shortVideo':
                return (
                    <PreviewCorousel
                        data={shortVideoData}
                        contentId={contentId}
                        tableType={currentTableType}
                    />
                )
            case '/home/ads':
                return (
                    <PreviewCorousel
                        data={inHouseAdsData}
                        contentId={contentId}
                        tableType={currentTableType}
                    />
                )
            case '/home/devotionalVideo':
                return (
                    <PreviewCorousel
                        data={devotionalVideoData}
                        contentId={contentId}
                        tableType={currentTableType}
                    />
                )
            case '/home/card':
                return (
                    <PreviewCorousel
                        data={cardsData}
                        contentId={contentId}
                        tableType={currentTableType}
                    />
                )
            case '/home/poll':
                return (
                    <PreviewCorousel
                        data={pollsData}
                        contentId={contentId}
                        tableType={currentTableType}
                    />
                )
            case '/home/quiz':
                return (
                    <PreviewCorousel
                        data={quizData}
                        contentId={contentId}
                        tableType={currentTableType}
                    />
                )
            case '/home/html':
                return (
                    <PreviewCorousel
                        data={htmlTableData}
                        contentId={contentId}
                        tableType={currentTableType}
                    />
                )
            case '/home/ug':
                return (
                    <PreviewCorousel
                        data={ugcData}
                        contentId={contentId}
                        tableType={currentTableType}
                        onReviewClicked={onReviewClicked}
                    />
                )
            default:
                return (
                    <PreviewCorousel
                        data={shortNewsData}
                        contentId={contentId}
                        tableType={currentTableType}
                    />
                )
        }
    }

    const changeStateVal = (val) => {
        handleStateChange(val)
        setStateValue(val)
        options.state = val
        optionsString = JSON.stringify(options)
        localStorage.setItem('options', optionsString)
        // getAssemblyData(val)
    }

    const handleClick = () => {
        const { pathname } = location

        switch (true) {
            case pathname.includes('add-short-news'):
                getShortVideoTableData()
                break
            case pathname.includes('shortVideo'):
                getShortVideoTableData()
                break
            case pathname.includes('shortNews'):
                getShortNewsTableData()
                break
            case pathname.includes('card'):
                getCardsData()
                break
            case pathname.includes('html'):
                getHtmlContentTable()
                break
            case pathname.includes('poll'):
                getPollsData()
                break
            case pathname.includes('quiz'):
                getQuizData()
                break
            case pathname.includes('ads'):
                getInHouseAdsTableData()
            default:
                // No action for other paths
                break
        }
    }

    return (
        <div className="manage-content-container">
            {contextHolder}
            <div className="manage-content-header">
                <h2>{currentContentHeading}</h2>
                {![
                    '/home/languages',
                    '/home/hashtags',
                    '/home/features',
                    '/home/sources',
                    '/home/employees',
                    '/home/categories',
                    '/home/subcategories',
                    '/home/devotionalVideo',
                    '/home/stringers',
                    '/home/horoscope',
                    '/home/panchangam',
                    './home/pulse',
                ].includes(location.pathname) && (
                    <div className="date-picker-container">
                        <span style={{ marginRight: '5px' }}>Date Range :</span>
                        <ManageContentDateRange
                            dates={dates}
                            addDates={addDates}
                            clearDates={clearDates}
                        />
                    </div>
                )}
            </div>

            <div className="manage-content-inputs">
                {[
                    '/home/languages',
                    '/home/employees',
                    '/home/categories',
                ].includes(location.pathname) ? (
                    <div>
                        {' '}
                        <Select
                            value={activeOption}
                            onChange={handleActiveChange}
                            style={{ width: 200 }}
                        >
                            {ActiveOptions.map((option, index) => {
                                return (
                                    <Option key={index} value={option?.value}>
                                        {option?.label}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                ) : (
                    ![
                        '/home/hashtags',
                        '/home/sources',
                        '/home/categories',
                        '/home/subcategories',
                        '/home/features',
                        '/home/devotionalVideo',
                        '/home/feedback',
                        '/home/ug',
                        '/home/stringers',
                        '/home/horoscope',
                        '/home/panchangam',
                        './home/pulse',
                    ].includes(location.pathname) && (
                        <Select
                            value={approvalOption}
                            onChange={handleApprovalChange}
                            style={{ width: 200 }}
                        >
                            {ApprovalOptions.map((option, index) => {
                                return (
                                    <Option key={index} value={option?.value}>
                                        {option?.label}
                                    </Option>
                                )
                            })}
                        </Select>
                    )
                )}

                {location?.pathname === '/home/stringers' && (
                    <div style={{ display: 'flex' }}>
                        <Select
                            value={typeOption}
                            onChange={(e) => handleTypeChange(e)}
                            style={{ width: 100, marginRight: '2rem' }}
                        >
                            {typeOptions.map((option, index) => {
                                return (
                                    <Option key={index} value={option?.value}>
                                        {option?.label}
                                    </Option>
                                )
                            })}
                        </Select>

                        <Select
                            placeholder="Select State"
                            onChange={(e) => handleStateChange(e)}
                            value={
                                selectedSateId != '' ? (
                                    selectedSateId !== 'Select State' &&
                                    selectedSateId === 'Clear Filter' ? (
                                        ''
                                    ) : (
                                        selectedSateId
                                    )
                                ) : (
                                    <span style={{ color: 'gray' }}>
                                        Select State
                                    </span>
                                )
                            }
                            style={{ width: 150, marginRight: '2rem' }}
                        >
                            {locationsData.map((option, index) => {
                                return (
                                    <Option
                                        key={option.state_id}
                                        value={option.state_id}
                                    >
                                        {option?.name}
                                    </Option>
                                )
                            })}
                        </Select>
                        <Select
                            placeholder="Select District"
                            value={
                                selectedDistrictId === 'Clear Filter'
                                    ? 'Select District '
                                    : districtName
                            }
                            onChange={(e) => {
                                setDistrictName(e)
                                setSelectedDistrictId(
                                    districtData.filter(
                                        (ee) => ee.name === e
                                    )[0]?.district_id || ''
                                )
                            }}
                            style={{ width: 170, marginRight: '1rem' }}
                        >
                            {districtData &&
                                districtData?.map((option, index) => {
                                    return (
                                        <Option
                                            key={option?.district_id}
                                            value={option?.name}
                                        >
                                            {option?.name}
                                        </Option>
                                    )
                                })}
                        </Select>

                        <Button
                            type="primary"
                            onClick={() => {
                                getTableData(currentTableType)
                            }}
                        >
                            Apply
                        </Button>
                        <Search
                            placeholder="Search name"
                            enterButton="Search"
                            size="middle"
                            allowClear
                            onChange={changeSearchHandler}
                            onSearch={onNameSearch}
                            value={searchName}
                            style={{ width: 200, margin: '0 1rem' }}
                            type="string"
                        />
                        <Search
                            placeholder="Search with mobile number"
                            enterButton="Search"
                            size="middle"
                            allowClear
                            onChange={changeMobileHandler}
                            onSearch={onMobileNumberSearch}
                            value={searchMobileData}
                            style={{ width: 260 }}
                            type="number"
                        />
                    </div>
                )}
                {location?.pathname === '/home/pulse' ? (
                    <div style={{ display: 'flex' }}>
                        <Select
                            placeholder="Select State"
                            onChange={(e) => handleStateChange(e)}
                            value={
                                selectedSateId !== '' ? (
                                    selectedSateId !== '' &&
                                    selectedSateId === 'Clear Filter' ? (
                                        ''
                                    ) : (
                                        selectedSateId
                                    )
                                ) : (
                                    <span style={{ color: 'gray' }}>
                                        Select State
                                    </span>
                                )
                            }
                            style={{ width: 150, marginRight: '2rem' }}
                        >
                            {locationsData.map((option, index) => {
                                return (
                                    <Option
                                        key={option.state_id}
                                        value={option.state_id}
                                    >
                                        {option?.name}
                                    </Option>
                                )
                            })}
                        </Select>
                    </div>
                ) : null}
                {location?.pathname === '/home/ads' ? (
                    <Select
                        value={addTypeOption}
                        onChange={handleAddAdTypeChange}
                        style={{ width: 200 }}
                    >
                        {AddTypeOptions.map((option, index) => {
                            return (
                                <Option key={index} value={option?.value}>
                                    {option?.label}
                                </Option>
                            )
                        })}
                    </Select>
                ) : null}
                {location.pathname === '/home/categories' && (
                    <Select
                        value={contentType}
                        onChange={(e) => setContentType(e)}
                        style={{ width: 200 }}
                    >
                        {categoryTypeOptions.map((option, index) => {
                            return (
                                <Option key={index} value={option?.value}>
                                    {option?.label}
                                </Option>
                            )
                        })}
                    </Select>
                )}

                {!['/home/languages', '/home/stringers'].includes(
                    location.pathname
                ) && (
                    <Select
                        value={languageOption}
                        onChange={handleLanguageChange}
                        style={{ width: 200 }}
                        placeholder="Select language"
                    >
                        {languageData?.map((option) => {
                            return (
                                <Option
                                    key={option?.language_id}
                                    value={option?.language_id}
                                >
                                    {option?.language_name}
                                </Option>
                            )
                        })}
                    </Select>
                )}
                {(location.pathname === '/home/hashtags' ||
                    location.pathname === '/home/sources') && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginRight: '1rem',
                        }}
                    >
                        <Input
                            placeholder={
                                location.pathname === '/home/hashtags'
                                    ? 'Search by Hashtag'
                                    : 'Search by Source Name'
                            }
                            allowClear
                            onChange={(e) => setHashtagSearch(e.target.value)}
                            value={hashtagSearch}
                            style={{
                                width: 180,
                                border: 'none',
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                            }}
                            onPressEnter={
                                location.pathname === '/home/hashtags'
                                    ? getHashtagData
                                    : getSourceTable
                            }
                        />
                        <Button
                            type="primary"
                            style={{
                                border: 'none',
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                            }}
                            onClick={
                                location.pathname === '/home/hashtags'
                                    ? getHashtagData
                                    : getSourceTable
                            }
                        >
                            Search
                        </Button>
                    </div>
                )}
                {location.pathname.includes('home/employees') && (
                    <div style={{ display: 'flex' }}>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginRight: '1rem',
                            }}
                        >
                            <Input
                                placeholder="Search Name.."
                                allowClear
                                onChange={changeSearchHandler}
                                style={{
                                    width: 180,
                                    border: 'none',
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                                onPressEnter={getEmployeeTable}
                            />
                            <Button
                                type="primary"
                                style={{
                                    border: 'none',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                }}
                                onClick={getEmployeeTable}
                            >
                                Search
                            </Button>
                        </div>

                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Input
                                placeholder="Search mobile no.."
                                allowClear
                                onChange={changeMobileHandler}
                                style={{
                                    width: 220,
                                    border: 'none',
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                                onPressEnter={getEmployeeTable}
                                type="number"
                            />
                            <Button
                                type="primary"
                                style={{
                                    border: 'none',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                }}
                                onClick={getEmployeeTable}
                            >
                                Search
                            </Button>
                        </div>
                    </div>
                )}

                {['/home/horoscope'].includes(location.pathname) && (
                    <Select
                        onChange={handleHoroscopeChange}
                        value={horoscopeOption}
                        placeholder="-Select Horoscope Type-"
                    >
                        {horoscopeType.map((month) => (
                            <Option key={month} value={month}>
                                {month}
                            </Option>
                        ))}
                    </Select>
                )}
                {(location.pathname === '/home/horoscope' ||
                    location.pathname === '/home/panchangam') && (
                    <Select
                        onChange={handleYearChange}
                        value={yearOption}
                        placeholder="-Select year-"
                        style={{ width: '6rem' }}
                    >
                        {generateYearOptions()}
                    </Select>
                )}
                {(location.pathname === '/home/horoscope' ||
                    location.pathname === '/home/panchangam') &&
                    horoscopeOption === 'Daily Horoscope' && (
                        <Select
                            onChange={handleMonthChange}
                            value={monthOption}
                            placeholder="Select month"
                            style={{ width: '6rem' }}
                        >
                            {months.map((month) => (
                                <Option key={month.value} value={month.value}>
                                    {month.name}
                                </Option>
                            ))}
                        </Select>
                    )}

                {location?.pathname === '/home/ug' ? (
                    <Select
                        value={statusOption}
                        onChange={handleStatusChange}
                        style={{ width: 200 }}
                    >
                        {statusOptions.map((option, index) => {
                            return (
                                <Option key={index} value={option?.value}>
                                    {option?.label}
                                </Option>
                            )
                        })}
                    </Select>
                ) : null}
                {location?.pathname === '/home/ug' ? (
                    <Select
                        value={ugctypeOption}
                        onChange={handleugcTypeChange}
                        style={{ width: 200 }}
                    >
                        {ugctypeOptions.map((option, index) => {
                            return (
                                <Option key={index} value={option?.value}>
                                    {option?.label}
                                </Option>
                            )
                        })}
                    </Select>
                ) : null}
                {location?.pathname === '/home/ug' ? (
                    <Select
                        value={stateValue}
                        onChange={(val) => changeStateVal(val)}
                        style={{ width: 250 }}
                    >
                        {locationsData?.map((option) => (
                            <Option
                                key={option.state_id}
                                value={option.state_id}
                            >
                                {option.name}
                            </Option>
                        ))}
                    </Select>
                ) : null}
                {location?.pathname === '/home/ug' ? (
                    <>
                        {districtData !== '' ? (
                            <Select
                                defaultValue={'Select District'}
                                value={
                                    selectedDistrictId !== ''
                                        ? selectedDistrictId
                                        : 'Select District'
                                }
                                placeholder="Delect District"
                                onChange={(e) => {
                                    setSelectedDistrictId(e)
                                    options.district = e // New value for language

                                    optionsString = JSON.stringify(options)

                                    localStorage.setItem(
                                        'options',
                                        optionsString
                                    )
                                }}
                                style={{ width: 250 }}
                            >
                                {districtData?.map((option) => (
                                    <Option
                                        key={option.district_id}
                                        value={option.district_id}
                                    >
                                        {option.name}
                                    </Option>
                                ))}
                            </Select>
                        ) : (
                            <Select placeholder="select district"></Select>
                        )}
                        <div style={{ display: 'flex' }}>
                            <Input
                                placeholder="Search By Title..."
                                allowClear
                                onChange={(e) => setSearchData(e.target.value)}
                                style={{
                                    width: 130,
                                    border: 'none',
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                                onPressEnter={getUgcData}
                            />
                            <Button
                                type="primary"
                                style={{
                                    border: 'none',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                }}
                                onClick={getUgcData}
                            >
                                Search
                            </Button>
                        </div>
                    </>
                ) : null}

                {location.pathname === '/home/feedback' && (
                    <Select
                        value={platformOption}
                        onChange={setPlatformOption}
                        style={{ width: 200 }}
                        placeholder="Select language"
                    >
                        {platformOptions?.map((option) => {
                            return (
                                <Option
                                    key={option?.value}
                                    value={option?.value}
                                >
                                    {option?.label}
                                </Option>
                            )
                        })}
                    </Select>
                )}

                {![
                    '/home/hashtags',
                    '/home/languages',
                    '/home/categories',
                    '/home/sources',
                    '/home/employees',
                    '/home/devotionalVideo',
                    '/home/stringers',
                    '/home/features',
                    '/home/feedback',
                    '/home/ug',
                    '/home/horoscope',
                    '/home/panchangam',
                    '/home/ads',
                ].includes(location.pathname) && (
                    <Select
                        value={categoryOption}
                        onChange={handleCategoryChange}
                        style={{ width: 200 }}
                        placeholder="Select category"
                    >
                        <Option key={'all'} value={99999}>
                            All Categories
                        </Option>
                        {categories
                            ?.filter(
                                (ct) =>
                                    ct?.language_name ===
                                    languageData?.filter(
                                        (l) => l.language_id === languageOption
                                    )?.[0]?.language_name
                            )
                            .map((option) => {
                                return (
                                    <Option
                                        key={option?.category_name}
                                        value={option?.category_id}
                                    >
                                        {option?.category_name}
                                    </Option>
                                )
                            })}
                    </Select>
                )}
                {location.pathname !== '/home/languages' &&
                    location?.pathname !== '/home/hashtags' &&
                    location?.pathname !== '/home/categories' &&
                    location?.pathname !== '/home/subcategories' &&
                    location?.pathname !== '/home/sources' &&
                    location?.pathname !== '/home/features' &&
                    location?.pathname !== '/home/employees' &&
                    location?.pathname !== '/home/stringers' &&
                    location?.pathname !== '/home/devotionalVideo' &&
                    location?.pathname !== '/home/horoscope' &&
                    location.pathname !== '/home/panchangam' &&
                    location?.pathname !== '/home/feedback' &&
                    location?.pathname !== '/home/ug' && (
                        <div style={{ display: 'flex' }}>
                            <Input
                                placeholder="Search by Title.."
                                allowClear
                                value={searchData}
                                onChange={changeHandler}
                                style={{ width: 160, border: 'none' }}
                                onPressEnter={(e) => {
                                    onSearch(e.target.value)
                                }}
                            />
                            <Button
                                type="primary"
                                style={{ borderLeft: 'none' }}
                                onClick={() => onSearch(searchData)}
                            >
                                Search
                            </Button>
                            <Button
                                className="refresh-button"
                                style={{ marginLeft: '1rem' }}
                                onClick={handleClick}
                            >
                                <RedoOutlined />
                                Refresh
                            </Button>
                        </div>
                        // <input/>
                    )}

                {showAddButton &&
                    location?.pathname !== '/home/horoscope' &&
                    location.pathname !== '/home/panchangam' && (
                        <Button
                            type="primary"
                            className="manage-button"
                            onClick={() =>
                                location?.pathname !== '/home'
                                    ? modalRoutes.includes(location.pathname)
                                        ? openModal(location.pathname)
                                        : location?.pathname ===
                                          '/home/employees'
                                        ? navigate(`/home/createEmployee`)
                                        : navigate(
                                              `${location?.pathname}Content?mode=add`
                                          )
                                    : navigate(defaultContentRoute)
                            }
                        >
                            {currentContentButton}
                        </Button>
                    )}
            </div>
            {popup && getPreviewData(location.pathname)}
            {getTable(location.pathname)}
            {languageModal}
            {hashtagModal}
            {categoryModal}
            {subcategoryModal}
            {sourceModal}
            {featureModal}
        </div>
        // rmgfd
    )
}
export default ManageContent
