import {
    FontSizeOutlined,
    LoadingOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import {
    Button,
    DatePicker,
    Form,
    Input,
    Radio,
    Select,
    Upload,
    message,
} from 'antd'
import { useEffect, useRef, useState } from 'react'
import './AddShortVideoForm.scss'
import {
    expiryHoursOptions,
    isPublish,
    isSubmitAndDraft,
    TableTypeFromRoutesContent,
} from '../../App.constants'
import { getImageUrl, getVideoUrl } from '../../../AadhanApi'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import Speaker from '../../Speaker'

const AddShortVideoForm = ({
    initialFormData,
    languageData,
    categories,
    onSave,
    onPublish,
    setVideoPath,
    accessToken,
    setImagePath,
    isLoading,
    setIsLoading,
    contentStatus,
    setYoutubeLink,
    setOtherLink,
}) => {
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const [form] = Form.useForm()
    const loading = false
    const [thumbnailImageLoading, setThumbnailImageLoading] = useState()
    const [titleError, setTitleError] = useState()
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const inputVideoRef = useRef(null)

    const [isVideoUploading, setIsVideoUploading] = useState(false)
    const [imagePathUrl, setImagePathUrl] = useState(initialFormData?.image_url)
    const [messageApi, contextHolder] = message.useMessage()
    const [title, setTitle] = useState('')
    const [englishTitle, setEnglishTitle] = useState('')
    const [englishTitleError, setEnglishTitleError] = useState()
    const [mediaValue, setMediaValue] = useState('youtubeLink')
    const location = useLocation()

    const currentTableType =
        TableTypeFromRoutesContent[location?.pathname] ?? 'shortnews'

    useEffect(() => {
        form.setFieldsValue(initialFormData)
        setImagePathUrl(form.getFieldValue('thumbnailImage'))
        setTitle(initialFormData?.title)
        handleLanguageChange(initialFormData.language)
        handleTitleChange(initialFormData?.title)
        handleEnglishTitleChange(initialFormData?.englishTitle)
    }, [form, initialFormData])

    const handleLanguageChange = (id) => {
        setLanguageOption(id)
    }

    const getImagePath = async (path) => {
        if(path.percent!==100){
            return
        }
        setIsLoading(true)
        setThumbnailImageLoading(true)

        try {
            const pathUriArr = await getImageUrl(
                path?.originFileObj,
                'shortvideo',
                accessToken,
                languageOption
            )
            setIsLoading(false)
            if (pathUriArr?.data) {
                setImagePath(
                    pathUriArr?.data
                )
                setImagePathUrl(
                    pathUriArr?.data
                )
                setThumbnailImageLoading(false)
            }
            
        } catch (err) {
            setIsLoading(false)
            setThumbnailImageLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to upload image',
                duration: 5,
            })
        }
    }

    const onFinish = (values) => {
        onPublish(values)
    }

    const onFinishFailed = (errorInfo) => {
        errorInfo?.errorFields?.length &&
            errorInfo.errorFields.forEach((ele) => {
                if (
                    ele?.name == 'title' ||
                    ele?.name == 'category' ||
                    ele?.name == 'video'
                ) {
                    window.scrollTo(0, 0)
                }
            })
        console.log('Failed:', errorInfo)
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>Upload</div>
        </div>
    )

    const getVideoPath = async (path) => {
        setIsLoading(true)
        if (path && path?.size / (1024 * 1024) <= 50) {
            setIsVideoUploading(true)
            try {
                const pathUri = await getVideoUrl(path, accessToken)
                if (pathUri?.data) {
                    setIsLoading(false)
                    setVideoPath(pathUri?.data)
                    setIsVideoUploading(false)
                    form.setFieldValue('video', pathUri?.data)
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to upload Video, Please try again',
                    duration: 5,
                })
                setIsVideoUploading(false)
                setVideoPath('')
            }
        } else {
            setIsLoading(false)
            alert('Video Size should be less than or Equal to 50Mb')
            inputVideoRef.current.value = null
        }
    }

    const handleTitleChange = (value) => {
        if (value) {
            let cleanedValue = value
            setTitle(cleanedValue)
            form.setFieldValue('title', cleanedValue)
            setTitleError(cleanedValue.length > 75)
        } else {
            setTitle('')
            form.setFieldValue('title', '')
            setTitleError(false)
        }
    }

    const handleEnglishTitleChange = (value) => {
        form.setFieldValue('englishTitle', value)
        setEnglishTitle(value)
        setEnglishTitleError(value?.length > 75)
    }

    const handleSave = async (type) => {
        try {
            const validation = await form.validateFields([
                'title',
                'video',
                'language',
                'category',
                'expiryHours',
                'thumbnailImage',
            ])
            if (validation?.errorFields?.length > 0) {
                return
            }
        } catch (e) {
            e?.errorFields?.length &&
                e.errorFields.forEach((ele) => {
                    if (
                        ele?.name == 'title' ||
                        ele?.name == 'category' ||
                        ele?.name == 'video'
                    ) {
                        window.scrollTo(0, 0)
                    }
                })
            return
        }

        onSave({ ...form.getFieldsValue() }, type)
    }

    return (
        <div id="form" style={{ width: '100%' }}>
            {contextHolder}
            <Form
                form={form}
                name="basic"
                colon={false}
                scrollToFirstError
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                initialValues={initialFormData}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select language' },
                    ]}
                >
                    <Select onChange={handleLanguageChange}>
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Category"
                    name="category"
                    rules={[{ required: true, message: 'Please enter value' }]}
                >
                    <Select placeholder="--Select Category--">
                        {categories
                            ?.filter(
                                (ct) =>
                                    ct?.language_name ===
                                        languageData?.filter(
                                            (l) =>
                                                l.language_id === languageOption
                                        )?.[0]?.language_name &&
                                    ct?.type_content?.includes(currentTableType)
                            )
                            ?.map((option) => {
                                return (
                                    <Select.Option
                                        key={option?.category_name}
                                        value={option?.category_id}
                                    >
                                        {option?.category_name}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'please input title' }]}
                >
                    <Input.TextArea
                        className={titleError ? 'error' : ''}
                        maxLength={75}
                        value={title}
                        onChange={(e) => handleTitleChange(e.target.value)}
                        placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                    />
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        className="description-subtext"
                    >
                        <span
                            style={{
                                color: title?.length > 75 ? 'red' : null,
                            }}
                        >
                            {' '}
                            {75 - (title?.length ?? 0)} characters
                        </span>
                        <span
                            style={{
                                display: 'flex',
                                color: 'green',
                                fontSize: '1rem',
                                fontWeight: '500',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                type="primary"
                                ghost={true}
                                className="format-button"
                                onClick={() => {
                                    if (title) {
                                        let cleanedValue = title
                                            ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                                            .replace(
                                                /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                                                '$1 '
                                            ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                                            .replace(
                                                /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/,
                                                '$1'
                                            ) // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                                            .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                                            .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                                            .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                                            .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                                            .trim() // Trim leading and trailing spaces

                                        cleanedValue = cleanedValue.trimStart() // Trim leading spaces

                                        if (/[.!?]$/.test(cleanedValue)) {
                                            cleanedValue =
                                                cleanedValue.trimEnd() // Ensure no trailing punctuation space
                                        }

                                        setTitle(cleanedValue)
                                        form.setFieldValue(
                                            'title',
                                            cleanedValue
                                        )
                                        setTitleError(cleanedValue.length > 75)
                                    } else {
                                        setTitle('')
                                        form.setFieldValue('title', '')
                                        setTitleError(false)
                                    }
                                }}
                            >
                                <FontSizeOutlined />
                            </Button>
                            <span
                                style={{ marginTop: '3px', marginLeft: '5px' }}
                            >
                                <Speaker
                                    text={title}
                                    language={
                                        languageOption === 1
                                            ? 'en-US'
                                            : languageOption === 2
                                            ? 'hi-IN'
                                            : languageOption === 4
                                            ? 'ta-IN'
                                            : 'hi-IN'
                                    }
                                />
                            </span>
                        </span>
                    </span>
                </Form.Item>

                <Form.Item label="Media" name="media">
                    <Radio.Group>
                        <Radio value="youtubeLink"> Youtube Link </Radio>
                        <Radio value="otherLink"> Other Link </Radio>
                        <Radio disabled={true} value="video">
                            {' '}
                            Video{' '}
                        </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.media !== currentValues.media
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('media') === 'video' ? (
                            <Form.Item name="video" label="Video">
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <input
                                        accept="video/mp4"
                                        ref={inputVideoRef}
                                        disabled={true}
                                        onChange={(e) => {
                                            getVideoPath(e.target.files?.[0])
                                        }}
                                        type="file"
                                    />
                                    <div>
                                        {isVideoUploading && (
                                            <LoadingOutlined />
                                        )}
                                    </div>
                                </div>
                                <div>Max size 50Mb and 1080*2340</div>
                            </Form.Item>
                        ) : getFieldValue('media') === 'youtubeLink' ? (
                            <Form.Item
                                label="Youtube Link"
                                name="youtubeLink"
                                rules={[
                                    {
                                        type: 'url',
                                        message: 'Please enter valid link',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Youtube Link"
                                    onChange={(e) => {
                                        setYoutubeLink(e?.target?.value)
                                        setVideoPath(e?.target?.value)
                                    }}
                                />
                            </Form.Item>
                        ) : (
                            getFieldValue('media') === 'otherLink' && (
                                <Form.Item
                                    label="Other Link"
                                    name="otherLink"
                                    rules={[
                                        {
                                            type: 'url',
                                            message: 'Please enter valid link',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Other Link"
                                        onChange={(e) =>
                                            setOtherLink(e?.target?.value)
                                        }
                                    />
                                </Form.Item>
                            )
                        )
                    }
                </Form.Item>
                <Form.Item label="Disturbing Visuals" name="disturbingVisuals">
                    <Radio.Group>
                        <Radio value={true}> Yes </Radio>
                        <Radio value={false}> No </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="expiryHours"
                    label="Expiry Hours"
                    rules={[{ required: true }]}
                >
                    <Select style={{ width: 200 }}>
                        {expiryHoursOptions.map((option, index) => (
                            <Select.Option key={index} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {/* {isPublish(userPermissions, 'Content', 'Short Video') &&
                    languageOption !== 2 && (
                        <Form.Item
                            label="Proof read"
                            name="proofRead"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an option',
                                },
                            ]}
                            className="custom-form-item"
                        >
                            <Radio.Group>
                                <Radio value={true}> Yes </Radio>
                                <Radio value={false}> No </Radio>
                            </Radio.Group>
                        </Form.Item>
                    )} */}
                <Form.Item
                    rules={[
                        { required: true, message: 'publish date is required' },
                    ]}
                    label="Publish"
                    name="publish"
                >
                    <DatePicker
                        showTime
                        format="DD/MM/YYYY h:mm A"
                        disabledDate={(currentDate) => currentDate < new Date()}
                    />
                </Form.Item>
                <Form.Item
                    label="Thumbnail Image"
                    name="thumbnailImage"
                    rules={[
                        { required: true, message: 'please select an image' },
                    ]}
                >
                    <Upload
                        name="avatar"
                        style={{ width: '100%' }}
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        accept="image/jpeg,image/png"
                        onChange={(e) => {
                            getImagePath(e?.file)
                        }}
                    >
                        {imagePathUrl ? (
                            <img
                                src={imagePathUrl}
                                alt="avatar"
                                style={{ width: '100%', height: '100%' }}
                            />
                        ) : thumbnailImageLoading === true ? (
                            <LoadingOutlined />
                        ) : (
                            uploadButton
                        )}
                    </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 48 }}>
                    {isSubmitAndDraft(
                        userPermissions,
                        'Content',
                        'Short Video'
                    ) && (
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            onClick={() => handleSave('draft')}
                            disabled={isLoading || contentStatus == 'approved'}
                        >
                            Draft
                        </Button>
                    )}
                    {isSubmitAndDraft(
                        userPermissions,
                        'Content',
                        'Short Video'
                    ) && (
                        <Button
                            className="bg-color width-150 submit-buttons"
                            type="primary"
                            onClick={() => handleSave('submit')}
                            disabled={isLoading || contentStatus == 'approved'}
                        >
                            Submit
                        </Button>
                    )}
                    {isPublish(userPermissions, 'Content', 'Short Video') && (
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            htmlType="submit"
                            disabled={isLoading}
                        >
                            Publish
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}
export default AddShortVideoForm
