import React, { useEffect, useState } from 'react'
import CommonTableWithBorder from '../CommonTable/CommonTableWithBorder'
import { Button, Select, Switch } from 'antd'
import { getEditorContent } from '../../AadhanApi'
import ReassignModal from './ReassignModal'
import dayjs from 'dayjs'
import './dashboard.scss'
import { FieldTimeOutlined, RedoOutlined } from '@ant-design/icons'
import { render } from '@testing-library/react'
const EmployeeTable = ({ language, locations, token }) => {
    let optionsString = localStorage.getItem('options')
    let options = JSON.parse(optionsString)
    const [selectedLanguage, setSelectedLanguage] = useState(options?.language ? options?.language : 1)
    const [selectedState, setSelectedState] = useState(
        ''
    )
    const[unassignedCount,setUnassignedCount] = useState([{title:"Unassigned Content Count",count:0}])
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'))
    const [data, setData] = useState([])
    const [dates, setDates] = useState({
        startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
        endDate: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
    })
    const [dataLoading, setDataLoading] = useState(false)
    const totalApproved = data.reduce((sum, record) => sum + record.approved, 0);
    const totalRejected = data.reduce((sum, record) => sum + record.rejected, 0);
    const totalPending = data.reduce((sum, record) => sum + record.pending, 0);
    const deadlinecrossed = data.reduce((sum, record) => sum + record.deadline_crossed_contents, 0);
    const columns = [
        {
            title: 'Name',
            dataIndex: 'employee_name',
            width:"35%",
            render: (_, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width:"100%"
                    }}
                >
                    <div>{record?.employee_name}</div>
                    <Switch
                        value={record?.is_availdable===true}
                        disabled
                        checkedChildren="Active"
                        unCheckedChildren="Inactive"
                        defaultChecked
                        className="active_status"
                    />
                </div>
            ),
            sorter: (a, b) => {
                const A = a.is_availdable ? 1 : 0;
                const B = b.is_availdable ? 1 : 0;
                return A - B;
              },
              defaultSortOrder: 'descend',
              sortDirections: ['descend', 'ascend'],
        },
        {
            title:`Approved (${totalApproved})`,
            dataIndex:"approved",
            render: (_, record) => (
                <div
                    style={{
                        color: 'green',
                        fontSize: '1rem',
                        fontWeight: '500',
                    }}
                >
                    {record?.approved === undefined
                        ? 0
                        : record?.approved}
                </div>
            ),
        },
        {
            title:`Rejected (${totalRejected})`,
            dataIndex:"rejected",
            render: (_, record) => (
                <div
                    style={{
                        color: 'orange',
                        fontSize: '1rem',
                        fontWeight: '500',
                    }}
                >
                    {record?.rejected === undefined
                        ? 0
                        : record?.rejected}
                </div>
            ),
        },
        {
            title: `Pending (${totalPending})`,
            dataIndex: 'pending',
            render: (_, record) => (
               <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                                <div
                    style={{
                        color: 'blue',
                        fontSize: '1rem',
                        fontWeight: '500',
                    }}
                >
                    {record?.pending === undefined
                        ? 0
                        : record?.pending}
                </div>
                
               </div>
            ),
        },
       
        {
            title: `Deadline Crossed (${deadlinecrossed})`,
            dataIndex: 'deadline_crossed_contents',
            render: (_, record) => (
                <span
                    style={{
                        color: 'red',
                        fontSize: '1rem',
                        fontWeight: '500',
                    }}
                >
                    {record?.deadline_crossed_contents}
                </span>
            ),
        },
        {title:"Action",
            render:(_,record)=>(
                <ReassignModal
                language={selectedLanguage}
                    employees={data}
                    content={
                        record?.pending === undefined
                            ? 0
                            : record?.pending
                    }
                    getEmployeeData={getEmployeeData}
                    user={{
                        name: record?.employee_name,
                        user_id: record?.employee_id,
                    }}
                
                />
            )
        }
    ]

    async function getEmployeeData() {
        setDataLoading(true)
        const res = await getEditorContent(
            token,
            selectedLanguage,
            dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'),
            dayjs().startOf('day').format('YYYY-MM-DD'),
        )
        setData(res?.data?.data) 
        setUnassignedCount([{title:"Unassigned Content Count",count:res?.data?.unassigned_count}])
        setDataLoading(false)
    }
   
    useEffect(() => {
        if (dates?.startDate && dates?.endDate) {
            getEmployeeData()
        }
    }, [selectedLanguage, selectedState, date])
    return (
        <div style={{paddingBottom:"1.5rem"}}>
            <div
                style={{
                    marginBottom: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <Select
                    value={selectedLanguage}
                    onChange={(e) => setSelectedLanguage(e)}
                    style={{ width: '120px', marginRight: '2rem' }}
                    defaultValue={1}
                >
                    {language?.map((e) => {
                        return (
                            <Select.Option
                                key={e.language_id}
                                value={e.language_id}
                            >
                                {e.language_name}
                            </Select.Option>
                        )
                    })}
                </Select>
                <Button type='primary' style={{border:"none",cursor:"auto",fontSize:"1rem"}} ghost><FieldTimeOutlined />Last 2 day's </Button>
                <div style={{display:"flex",alignItems:"center",fontSize:"1.1rem",fontWeight:"500",margin:"auto"}}>
                    <label style={{ marginRight: '.5rem',color:"orange" }}>
                    Pending :
                    </label>
                    <span style={{color:"red",paddingTop:"1.5%",fontSize:"1.3rem"}}>{unassignedCount?.[0]?.count}</span>
                </div>
                <Button
                    style={{ marginLeft: 'auto' }}
                    type="primary"
                    ghost
                    onClick={() => getEmployeeData()}
                >
                    <RedoOutlined />
                    Refresh
                </Button>
            </div>
            <CommonTableWithBorder
            disablePagination={true}
                className={'no-padding-table'}
                columns={columns}
                data={data}
                dataLoading={dataLoading} 
            />
        </div>
    )
}
export default EmployeeTable
