import { useEffect, useState } from 'react'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'
import AddCardForm from './AddCardForm'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import dayjs from 'dayjs'
import { createCard, getCardByContentId, updateCard } from '../../AadhanApi'
import { Button, message } from 'antd'
import { CloseSquareFilled } from '@ant-design/icons'

const AddCard = () => {
    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    const [fileList, setFileList] = useState([])
    const [code, setCode] = useState('')
    const [contentStatus, setContentStatus] = useState('')
    const titleChange = (newTitle) => {
        setTitle(newTitle)
    }
    const descriptionChange = (newDesc) => {
        setDescription(newDesc)
    }

    const location = useLocation()
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('contentId')
    const mode = searchparam.get('mode')
    const languageData = useSelector((state) => state.app.languageData)
    const categories = useSelector((state) => state.app.categories)
    const accessToken = useSelector((state) => state.app.accessToken)
    const employeeId = useSelector((state) => state.app.employeeId)
    const employeeName = useSelector((state) => state.app.employeeName)
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const [isLoading, setIsLoading] = useState(false)

    const [initialFormData, setInitialFormData] = useState({
        language: languageData?.[0]?.language_id,
        publish: dayjs(),
        expiryHours: 'Never',
        popularCard: false,
        imageUrls: [],
    })

    const updateFormData = async (contentId, accessToken) => {
        const response = await getCardByContentId(contentId, accessToken)

        if (response?.data) {
            setCode(response?.data[0].code)
            setTitle(response?.data?.[0]?.title)
            setFileList(
                response?.data?.[0]?.image_urls?.map((e)=>{
                    return {...e,url:e.response}
                })
            )
            setContentStatus(response?.data?.[0]?.content_status)
            setInitialFormData({
                ...initialFormData,
                language: response?.data?.[0]?.language_id,
                category: response?.data?.[0]?.category_id,
                publish: dayjs(
                    dayjs(response?.data?.[0]?.published_date).format(
                        'YYYY-MM-DD HH:mm:ss'
                    )
                ),
                expiryHours:
                    response?.data?.[0]?.expiry_hours == 0
                        ? 'Never'
                        : response?.data?.[0]?.expiry_hours,
                title: response?.data?.[0]?.title,
                popularCard: response?.data?.[0]?.popular_card,
                uploadedBy: response?.data?.[0]?.uploaded_by,
                uploadedByName: response?.data?.[0]?.uploaded_by_name,
                uploadedDate: response?.data?.[0]?.uploaded_date,
                proofRead:
                    response?.data?.[0]?.content_status !== 'draft' &&
                    response?.data?.[0]?.content_status !== 'submit'
                        ? response?.data?.[0]?.is_proof_read
                        : '',
            })
        }
    }
    useEffect(() => {
        if (contentId) {
            updateFormData(contentId, accessToken)
        }
    }, [contentId])

    const createRequest = (values, type) => {
        let languageName = languageData
            ?.map((ele, i) => {
                if (ele.language_id == values?.language) {
                    return ele.language_name
                }
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]
        let categoryName = categories
            ?.map((ele, i) => {
                if (
                    ele.language_name == languageName &&
                    ele.category_id == values.category
                ) {
                    return ele.category_name
                }
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]

        return {
            title: values?.title
                ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                .replace(
                    /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”])\s*(?=\S)/g,
                    '$1 '
                ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                .replace(/(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”])$/, '$1') // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                .trim(), // Trim leading and trailing spaces
            source_id: 0,

            source_url: values?.sourceLink ?? '',
            category_id: values?.category,
            content_type: 'card',
            expiry_hours:
                values?.expiryHours == 'Never' ? 0 : values?.expiryHours,
            // expiry_date: dayjs(values?.publish)
            //     .add(values?.expiryHours, 'hour')
            //     ?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            published_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            popular_card: values?.popularCard,
            dynamic_url: '',
            is_active: true,
            content_status: type,
            language_id: values?.language,
            image_urls: fileList,
            thumbnailimage_url: '',
            code,
            state: '',
            district: '',
            city: '',
            keywords: '',
            uploaded_by_name: initialFormData?.uploadedByName || employeeName,
            proof_read_by_name: employeeName,
            category_name: categoryName,
        }
    }

    const onSave = async (values, type) => {
        setIsLoading(true)
        const request = createRequest(values, type)

        if (mode === 'edit') {
            request.updated_by = employeeId
            request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            request.uploaded_by = initialFormData?.uploadedBy
            request.uploaded_date = initialFormData?.uploadedDate
            try {
                const cardResponse = await updateCard(
                    request,
                    contentId,
                    accessToken
                )
                if (cardResponse?.data === 'Card updated successfully') {
                    setIsLoading(false)
                    navigate('/home/card')
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'failed to Update Card',
                    duration: 5,
                })
            }
        } else if (mode === 'add') {
            request.uploaded_by = employeeId
            request.updated_by = 0
            request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            try {
                const cardResponse = await createCard(request, accessToken)
                if (cardResponse?.data?.status === 'success') {
                    setIsLoading(false)
                    navigate('/home/card')
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to Create Card',
                    duration: 5,
                })
            }
        }
    }

    const onPublish = async (values) => {
        setIsLoading(true)
        const request = createRequest(values)
        request.content_status = 'approved'
        if (true) {
            request.proof_read_by = employeeId
            request.proof_read_date = dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
            request.is_proof_read = true
        }

        if (mode === 'edit') {
            request.updated_by = employeeId
            request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            request.uploaded_by = initialFormData?.uploadedBy
            request.uploaded_date = initialFormData?.uploadedDate
            try {
                const cardResponse = await updateCard(
                    request,
                    contentId,
                    accessToken
                )
                if (cardResponse?.data === 'Card updated successfully') {
                    setIsLoading(false)
                    navigate('/home/card')
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'failed to Update Card',
                    duration: 5,
                })
            }
        } else if (mode === 'add') {
            request.uploaded_by = employeeId
            request.updated_by = 0
            request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            try {
                const cardResponse = await createCard(request, accessToken)
                if (cardResponse?.data === 'Card inserted successfully') {
                    setIsLoading(false)
                    navigate('/home/card')
                }
            } catch (err) {
                setIsLoading(false)
                console.log(err)
                messageApi.open({
                    type: 'error',
                    content: 'Failed to Create Card',
                    duration: 5,
                })
            }
        }
    }

    return (
        <div style={{ padding: '0 1rem' }}>
            {contextHolder}
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <h3>{mode === 'edit' ? 'Edit Card' : 'Add Card'}</h3>
                <Button
                    onClick={() => navigate(-1)}
                    ghost={true}
                    style={{ border: 'none' }}
                    type="primary"
                    icon={
                        <CloseSquareFilled
                            style={{ color: 'LightCoral', fontSize: '1.8rem' }}
                        />
                    }
                ></Button>
            </div>
            <hr></hr>

            <div className="add-ad-container row">
                <div className="col-md">
                    <AddCardForm
                        descriptionChange={descriptionChange}
                        titleChange={titleChange}
                        initialFormData={initialFormData}
                        accessToken={accessToken}
                        setFileList={setFileList}
                        fileList={fileList}
                        onSave={onSave}
                        onPublish={onPublish}
                        languageData={languageData}
                        categories={categories}
                        isLoading={isLoading}
                        setIsLoading={setIsLoading}
                        contentStatus={contentStatus}
                    />
                </div>
                <div className="col-md">
                    <SmartphonePreview
                        title={title}
                        description={description}
                        cardImages={fileList}
                    />
                </div>
            </div>
        </div>
    )
}

export default AddCard
