
import React, { useState, useCallback } from 'react';
import { Button, Modal, Slider } from 'antd';
import Cropper from 'react-easy-crop';
import { EditOutlined } from '@ant-design/icons';
import { getImageUrl } from '../../../AadhanApi';
const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // Needed to avoid cross-origin issues
    image.src = url;
  });

// Helper function to get cropped image
const getCroppedImg = async (imageSrc, crop) => {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // Set canvas size to safe area
  canvas.width = safeArea;
  canvas.height = safeArea;

  // Translate canvas context to a central location to allow rotating around the center
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // Draw rotated image and store data
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);
  
  // Set canvas width to final desired crop size - this will clear existing context
  canvas.width = crop.width;
  canvas.height = crop.height;

  // Paste generated rotated image with correct offsets for x, y crop values
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - crop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - crop.y)
  );

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      resolve(file);
    }, 'image/jpeg',1);
  });
};

const ImageCropper = ({setFileList,setSelectedImageUrl,imageUrl,languageOption,accessToken,fileList,index ,onRadioChange}) => {
  const [loading,setLoading] = useState(false)
  const [visible, setVisible] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = async () => {
    setLoading(true)
    const croppedImage = await getCroppedImg(imageUrl, croppedAreaPixels);
    const pathUri = await getImageUrl(croppedImage, 'shortnews', accessToken,languageOption)
    if (pathUri?.data) {
        let url =
            pathUri?.data
            const updateFile = fileList.map((e,ind)=>{
                if(ind===index){
                    return url
                }
                else{
                    return e
                }
            })
            setFileList(updateFile)
            onRadioChange({target:{name:"image1"}}, index)
            setSelectedImageUrl([url])
            
    }
    setVisible(false);
    setLoading(false)
  };

  const handleCancel = () => {
    setVisible(false);
  };



  return (
    <div>
      <div onClick={showModal}>
        <EditOutlined/>
      </div>
      <Modal
        title="Uploaded Image"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={358}
        okButtonProps={{ disabled: loading }} 
        okText={'Save'}
      >
        <div className="crop-container" style={{ position: 'relative', width: '100%', height: 300 }}>
          <Cropper
            image={imageUrl}
            crop={crop}
            aspectRatio={540 / 525}
            zoom={zoom}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className="controls">
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            onChange={(value) => setZoom(value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ImageCropper;
