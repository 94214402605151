import {
    PictureFilled,
    PlusOutlined,
    VideoCameraFilled,
} from '@ant-design/icons'
import {
    AutoComplete,
    Button,
    Form,
    Input,
    message,
    Modal,
    Radio,
    Select,
    Spin,
    Tag,
} from 'antd'
import Checkbox from 'antd/es/checkbox/Checkbox'
import dayjs from 'dayjs'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useNavigate } from 'react-router'
import {
    createShortNews,
    getHashtagTableData,
    getSourcesTableData,
    updateShortNews,
    getSubCategories,
    getSimilarContent,
} from '../../../AadhanApi'
import { breakingNewsOptions } from './AddShortNewsConstant'
import './AddShortNewsForm.scss'
import {
    isPublish,
    isSubmitAndDraft,
    TableTypeFromRoutesContent,
} from '../../App.constants'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import AddSource from '../../Source/AddSource'
import generateKeyWord from '../../AIResponseFilter/KeywordsResponse'
import { checkContentForDay } from '../../CheckContentForDay/checkContentForDay'
const AddShortNewsFormNew = forwardRef(
    (
        {
            setIsVideoAdding,
            setIsImageAdding,
            imagePath,
            generate,
            isLoading,
            setIsLoading,
            setGenerate,
            titleChange,
            descriptionChange,
            initialFormData,
            languageData,
            categories,
            accessToken,
            mode,
            employeeLanguage,
            contentId,
            hashTagId,
            setHashTagId,
            sourceId,
            setSourceId,
            code,
            contentStatus,
            languageOption,
            setLanguageOption,
            pro,
            anti,
            videoPath,
            resetWriterDashboard
        },
        ref
    ) => {
        const [form] = Form.useForm()
        const [showSourceModal, setShowSourceModal] = useState()
        const userPermissions = useSelector(
            (state) => state.app.userPermissions
        )
        const employeeId = useSelector((state) => state.app.employeeId)
        const roleId = localStorage.getItem('ROLE_ID')
        const employeeName = useSelector((state) => state.app.employeeName)
        const [shortDescription, setShortDescription] = useState(
            initialFormData?.shortDescription
        )
        const [remainingCharCount, setRemaingCharCount] = useState(400)
        const [hashTagOptions, setHashtagOptions] = useState([])
        const [allHashTagOptions, setAllHashtagOptions] = useState([])
        const [sourcesOptions, setSourcesOptions] = useState([])
        const [allSourcesOptions, setAllSourcesOptions] = useState([])
        const [keywords, setKeywords] = useState([])
        const [keywords_g, setKeywords_g] = useState(false)
        const [keywordInput, setKeywordInput] = useState('')
        const [hashData, setHashData] = useState([])
        const [isSourceAvailable, setIsSourceAvailable] = useState(
            initialFormData?.sourc
        )
        const [loading, setLoading] = useState(false)
        const [similarContents, setSimilarContents] = useState({
            data: [],
            api: false,
        })
        
        const [sourceData, setSourceData] = useState([])
        const [hashTagValue, setHashTagValue] = useState('')
        const [sourceValue, setSourceValue] = useState('')
        const navigate = useNavigate()
        const [titleError, setTitleError] = useState()
        const [title, setTitle] = useState('')
        const [sourceError, setSourceError] = useState(false)
        const location = useLocation()
        const currentTableType =
            TableTypeFromRoutesContent[location?.pathname] ?? 'shortnews'
        const locationsData = useSelector((state) => state.app.locations)
        const [selectedStateId, setSelectedStateId] = useState(
            initialFormData?.state_id
        )
        const [subCategory, setSubCategory] = useState([])
        const [originalSubcategory, setOriginalSubcategory] = useState([])
        const [selectedCategory, setSelectedCategory] = useState(
            initialFormData?.category_id
        )
        const [entities, setEntities] = useState('')
        const [iab, setIab] = useState('')
        useEffect(() => {
            handleCategory(initialFormData?.category)
        }, [locationsData])
        async function generateKeyWords(title, description) {
            if (title !== '' || description !== '') {
                setGenerate(true)
                const data = await generateKeyWord(
                    title,
                    description,
                    languageOption
                )
                let newKeywords=[]
                newKeywords = data?.keywords?.split(',')
                const uniqueArray = [
                    ...new Set([
                        ...newKeywords?.map((keyword) => keyword?.trim()), // Trim each keyword
                    ]),
                ]
                const cleanKeywords = uniqueArray.filter(
                    (keyword) => keyword !== '' && !keyword?.includes('\n')
                )
                setGenerate(false)
                if (cleanKeywords.length === 0) {
                    setKeywords([])
                    setKeywords_g(true)
                    return { data: [], entity: '', iab_: '' }
                } else {
                    setEntities(data?.entities)
                    setKeywords_g(false)
                    setKeywords(cleanKeywords)
                    setIab(data?.iab)
                    return {
                        data: cleanKeywords,
                        entity: data?.entities,
                        iab_: data?.iab,
                    }
                }
            }
        }
        useEffect(() => {
            form.setFieldsValue(initialFormData)
            changeDescriptionValue(initialFormData?.shortDescription ?? '')
            handleTitleChange(initialFormData?.title)
            handleFindSimilar(initialFormData?.title,initialFormData?.shortDescription)
            handleEnglishTitleChange(initialFormData?.englishTitle)
           
            if (
                initialFormData?.keywords !== '' &&
                initialFormData?.keywords?.split(',')?.length > 0
            ) {
            }
            if (
                initialFormData.category &&
                initialFormData.category !== undefined
            ) {
                handleCategory(initialFormData?.category)
            }
            setIsSourceAvailable(initialFormData?.source_name ? true : false)
            setKeywords(
                initialFormData?.keywords !== '' &&
                    initialFormData?.keywords !== undefined
                    ? initialFormData?.keywords.split(',')
                    : []
            )
        }, [form, initialFormData, locationsData])
        useEffect(() => {
            setSelectedStateId(initialFormData?.state_id)
            setSelectedStates(
                initialFormData?.state_id?.map((e, index) => {
                    return { id: e, name: initialFormData?.state?.[index] }
                })
            )
            
        }, [initialFormData, locationsData])
       useEffect(()=>{
       
        handleLanguageChange(
            initialFormData?.language !== '' ? initialFormData?.language : 1
        )
       
       
       if(initialFormData?.source_name){
        updateSource( initialFormData?.language !== '' ? initialFormData?.language : 1)
    }
       },[initialFormData])
        
        const createRequest = (values, type) => {
            const language = languageData.filter(
                (e) => e.language_id === languageOption
            )[0].language_name
            let categoryName = categories
                ?.map((ele) => {
                    if (
                        ele.category_id === values.category &&
                        ele.language_name === language
                    ) {
                        return ele.category_name
                    }
                })
                .filter((Undefined) => Undefined !== undefined)?.[0]

            if (selectedStateId?.length === 0 && !selectedStateId) {
                setSelectedStateId(initialFormData.state_id)
            }
            const checkforday = checkContentForDay(title, languageOption)
            const resposeObject = {
                title: values?.title
                    ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                    .replace(
                        /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                        '$1 '
                    ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                    .replace(/(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/, '$1') // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                    .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                    .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                    .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                    .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                    .trim(), // Trim leading and trailing spaces
                source_id:
                    (values?.source_name !== '' ||
                        values?.source_name !== ' ') &&
                    isSourceAvailable
                        ? sourceId
                        : 0,
                source_url: isSourceAvailable
                    ? values?.sourceLink !== ''
                        ? values?.sourceLink
                        : ''
                    : '',

                category_id: values?.category,
                expiry_hours: 0,
                content_type: 'shortnews',
                published_date: dayjs(new Date()).format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                ),

                relevancy: values?.relevancy,
                dynamic_url: '',
                is_active: true,
                content_status: type,
                language_id: languageOption,
                description:
                    !values?.shortDescription.includes('*') &&
                    !values?.shortDescription.includes('**')
                        ? values?.shortDescription
                              ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                              .replace(
                                  /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                                  '$1 '
                              ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                              .replace(
                                  /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/,
                                  '$1'
                              ) // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                              .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                              .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                              .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                              .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                              .trim() // Trim leading and trailing spaces
                        : values?.shortDescription,
                image_url: imagePath,
                code,
                yt_video_url: videoPath?.yt_url,
                breaking_news: values?.breakingNews,
                state: selectedStates?.map((e) => e.id)?.join(','),
                constituency_ids: initialFormData?.constituency_ids,
                constituency_names: initialFormData?.assemblyconstituencies,
                district: initialFormData?.district_id,
                mandal: initialFormData?.mandal_id,
                district_name: initialFormData?.districts,
                mandal_name: initialFormData?.mandal,
                state_name: selectedStates?.map((e) => e.name),
                gender: values?.gender,
                city: '',
                stream_video_url: videoPath?.stream_url,
                mp4_video_url:
                    values.media === 'otherLink' ? values?.otherLink : '',
                image_url_16_9: '',
                image_url_3_4: '',
                thumbnailimage_url: '',
                hashtag_id: hashTagId,
                keywords: '',
                entities: '',
                iab_category: '',
                disturbing_visuals: values?.disturbingVisuals,
                left_color: '',
                right_color: '',
                upvote: 0,
                linked_content_id: '',
                uploaded_by_name:
                    initialFormData?.uploadedByName || employeeName,
                proof_read_by_name: employeeName,
                assigned_to: initialFormData?.assigned_to,
                assigned_at: initialFormData?.assigned_at,
                category_name: categoryName,
            }
            if (checkforday?.today || checkforday?.tomorrow) {
                resposeObject['expiry_hours'] = checkforday?.expiry
            }
            if (!subCategory.find((item) => item?.pro === true)) {
                resposeObject['sub_category_id'] = null

                resposeObject['sub_category_name'] = null
            } else {
                if (!subCategory.find((item) => item?.pro === true)) {
                    resposeObject['sub_category_id'] = null

                    resposeObject['sub_category_name'] = null
                } else {
                    const selectedSubcategory = subCategory.find(
                        (item) => item?.pro === true
                    )
                    resposeObject['sub_category_id'] = selectedSubcategory
                        ? selectedSubcategory.sub_category_id
                        : ''

                    resposeObject['sub_category_name'] =
                        selectedSubcategory.sub_category_name
                            ? selectedSubcategory?.sub_category_name
                            : ''
                }
            }
            if (!subCategory.find((item) => item?.anti === true)) {
                resposeObject['anti_sub_category_id'] = null
                resposeObject['anti_sub_category_name'] = null
                return resposeObject
            } else {
                if (!subCategory.find((item) => item?.anti === true)) {
                    resposeObject['anti_sub_category_id'] = null
                    resposeObject['anti_sub_category_name'] = null
                    return resposeObject
                } else {
                    const selectedSubcategory = subCategory.find(
                        (item) => item?.anti === true
                    )
                    resposeObject['anti_sub_category_id'] = selectedSubcategory
                        ? selectedSubcategory.sub_category_id
                        : ''

                    resposeObject['anti_sub_category_name'] =
                        selectedSubcategory.sub_category_name
                            ? selectedSubcategory?.sub_category_name
                            : ''
                    return resposeObject
                }
            }
        }
        const handleSave = async (type) => {
            setIsLoading(true)
            try {
                const validation = await form.validateFields([
                    'title',
                    'language',
                    'category',
                    'expiryHours',
                    'state',
                    'shortDescription',
                    'image',
                    'multimedia',
                    'relevancy',
                ])

                if (
                    validation?.errorFields?.length > 0 ||
                    (form.getFieldsValue()?.sourceLink?.length && !sourceId)
                ) {
                    setIsLoading(false)
                    setSourceError(true)
                    return
                }
            } catch (e) {
                setIsLoading(false)
                return
            }

            const request = await createRequest(
                { ...form.getFieldsValue() },
                type
            )

            if (mode === 'edit') {
                request.updated_by = employeeId
                request.updated_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.uploaded_by = initialFormData?.uploadedBy
                request.uploaded_date = initialFormData?.uploadedDate

                const shortNewsReponse = await updateShortNews(
                    request,
                    contentId,
                    accessToken
                )
                if (shortNewsReponse?.status === 200) {
                    setIsLoading(false)
                    navigate('/home/add-short-news')
                }
            } else if (mode === 'add') {
                request.uploaded_by = employeeId
                request.updated_by = 0
                request.uploaded_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                const shortNewsReponse = await createShortNews(
                    request,
                    accessToken
                )
                if (shortNewsReponse?.status===200) {
                    setIsLoading(false)
                    if(location?.pathname?.includes("writer-dashboard")){
                        resetWriterDashboard()
                        message.success("short news added successfully")
                        return
                    }
                    navigate('/home/add-short-news')
                    message.success("short news added successfully")
                }
            }
        }
        useEffect(() => {
            if (hashTagId) {
                setHashTagValue(
                    hashData?.filter((h) => h.hashtag_id === hashTagId)?.[0]
                        ?.hashtag_name ?? ''
                )
            }
        }, [hashData])

        useEffect(() => {
            if (sourceId) {
                setSourceValue(
                    sourceData?.filter((h) => h.source_id === sourceId)?.[0]
                        ?.source_name ?? ''
                )
            }
        }, [sourceData])
        const onFinish = async (values, publish) => {
            setIsLoading(true)
            const request = await createRequest(values)
            if (true) {
                request.proof_read_by = employeeId
                request.proof_read_date = dayjs(values?.publish).format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.is_proof_read = true
            }

            if (mode === 'edit' || mode === 'review') {
                request.content_status = 'approved'
                request.updated_by = employeeId
                request.updated_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.uploaded_by = initialFormData?.uploadedBy
                request.uploaded_date = initialFormData?.uploadedDate
                if (
                    publish &&
                    keywords.length === 0 &&
                    (!initialFormData?.keywords ||
                        !initialFormData?.entities ||
                        !initialFormData?.iab_category)
                ) {
                    const { data, entity, iab_ } = await generateKeyWords(
                        title,
                        shortDescription
                    )
                    if (data.length === 0) {
                        setIsLoading(false)
                        message.error(
                            'Keyword generation failed. Please try regenerating or enter the keywords manually.'
                        )
                        return
                    }
                    request.keywords = data.toString()
                    request.entites = entity
                    request.iab_category = iab_
                } else {
                    request.entites = initialFormData?.entities
                    request.keywords =
                        initialFormData?.keywords === '' ||
                        initialFormData?.keywords === undefined
                            ? keywords.toString()
                            : initialFormData?.keywords
                    request.iab_category = initialFormData?.iab_category
                }
                const shortNewsReponse = await updateShortNews(
                    request,
                    contentId,
                    accessToken
                )
                if (
                    shortNewsReponse?.data === 'Short news updated successfully'
                ) {
                    setIsLoading(false)
                    message.success('short news updated successfully')
                    if (mode === 'review') {
                        navigate('/home/review')
                    } else {
                        navigate('/home/add-short-news')
                    }
                }
                setTimeout(() => console.log('Initial timeout!'), 1000)
            } else if (mode === 'add') {
                if (publish && keywords.length === 0) {
                    const { data, entity, iab_ } = await generateKeyWords(
                        title,
                        shortDescription
                    )
                    if (data.length === 0) {
                        setIsLoading(false)
                        message.error(
                            'Keyword generation failed. Please try regenerating or enter the keywords manually.'
                        )
                        return
                    }
                    request.keywords = data.toString()
                    request.entites = entity
                    request.iab_category = iab_
                } else {
                    request.entites = initialFormData?.entities
                    request.keywords =
                        initialFormData?.keywords === '' ||
                        initialFormData?.keywords === undefined
                            ? keywords.toString()
                            : initialFormData?.keywords
                    request.iab_category = initialFormData?.iab_category
                }
                request.content_status = 'approved'
                request.uploaded_by = employeeId
                request.updated_by = 0
                request.uploaded_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )

                const shortNewsReponse = await createShortNews(
                    request,
                    accessToken
                )
                if (shortNewsReponse?.status === 200) {
                    setIsLoading(false)
                    message.success('short news added successfully')
                    navigate('/home/add-short-news')
                }
            }
        }
        const updateHashtag = async (language_id)=>{
            if (language_id) {
                const hashtagDataResponse = await getHashtagTableData(
                    language_id,
                    '',
                    accessToken
                )
              

                setHashData(hashtagDataResponse?.data)
              
                setHashtagOptions(
                    hashtagDataResponse?.data?.map((option) => ({
                        value: option.hashtag_name,
                    })) ?? []
                )
                setAllHashtagOptions(
                    hashtagDataResponse?.data?.map((option) => ({
                        value: option.hashtag_name,
                    })) ?? []
                )
               
            }
        }
        const updateSource = async (language_id) => {
            if (language_id) {
               
                const sourceDataResponse = await getSourcesTableData(
                    language_id,
                    accessToken
                )  
                setSourceData(sourceDataResponse?.data)
                setSourcesOptions(
                    sourceDataResponse?.data?.map((option) => ({
                        value: option.source_name,
                    })) ?? []
                )
                setAllSourcesOptions(
                    sourceDataResponse?.data?.map((option) => ({
                        value: option.source_name,
                    })) ?? []
                )
            }
        }

        const handleLanguageChange = (id) => {
            updateHashtag(id)
            if(isSourceAvailable){
                updateSource(id)
            }
            setLanguageOption(id)
            setOriginalSubcategory([])
            setSubCategory([])
        }

        const onFinishFailed = (errorInfo) => {
            console.log('Failed:', errorInfo)
        }

        const onHashTagSelect = (val) => {
            setHashTagValue(val)
            setHashTagId(
                hashData?.filter((h) => h.hashtag_name === val)?.[0]?.hashtag_id
            )
        }

        const onSelectSource = (val) => {
            setSourceValue(val)

            setSourceId(
                sourceData?.filter((h) => h.source_name === val)?.[0]?.source_id
            )
        }
        const getRemainingWords = (value, type, max) => {
            if (type === 'words') {
                const remaingWords = max - value?.split(' ')?.length
                return remaingWords
            } else {
                const remainingChar = max - value.length
                return remainingChar
            }
        }

        const changeDescriptionValue = (descriptionValue) => {
            let cleanedValue = descriptionValue
            if (cleanedValue?.split('\n')?.length <= 9) {
                form.setFieldValue('shortDescription', cleanedValue)
                descriptionChange(cleanedValue)
                const remainingWords = getRemainingWords(
                    cleanedValue,
                    'words',
                    60
                )
                const remainingChars = getRemainingWords(
                    cleanedValue,
                    'char',
                    400
                )
                if (remainingWords > -1 && remainingChars > -1) {
                    form.setFieldValue('shortDescription', cleanedValue)
                    setShortDescription(cleanedValue)
                    setRemaingCharCount(remainingChars)
                } else if (cleanedValue === '') {
                    setRemaingCharCount(400)
                } else {
                    if (remainingWords < 0) {
                        const newDescription = cleanedValue
                            .split(' ')
                            .slice(0, remainingWords)
                            .join(' ')
                        changeDescriptionValue(newDescription)
                    } else if (remainingChars < 0) {
                        const newDescription = cleanedValue
                            .split('')
                            .slice(0, remainingChars)
                            .join('')
                        changeDescriptionValue(newDescription)
                    }
                }
            }
        }

        const handleClose = (index) => {
            const key = [...keywords]
            key.splice(index, 1)
            setKeywords(key)
        }

        const addKeyword = (keyword) => {
            setKeywords([...keywords, keyword])
            setKeywords_g(true)
        }
        const handleTitleChange = (value) => {
            if (value) {
                let cleanedValue = value
                setTitle(cleanedValue)
                titleChange(cleanedValue)
                form.setFieldValue('title', cleanedValue)
                setTitleError(cleanedValue.length > 75)
            } else {
                setTitle('')
                form.setFieldValue('title', '')
                titleChange('')
                setTitleError(false)
            }
        }

        const handleEnglishTitleChange = (value) => {
            form.setFieldValue('englishTitle', value)
        }

        const updateSources = () => {
            updateSource(languageOption)
        }
       
        const handleFindSimilar = async (title,shortDescription) => {
            setLoading(true)
           if(!title || title==="" || title.trim()===""){
            return
           }
              
            try {
                const response = await getSimilarContent(languageOption, `${title} ${shortDescription}`,accessToken)
                setSimilarContents({ data: response?.data?.data, api: true })
            } catch (error) {
                console.error('Error fetching similar content:', error)
            } finally {
                setLoading(false)
            }
        }
        const sourceModal = (
            <Modal
                open={showSourceModal}
                title={'Add Source'}
                onCancel={() => setShowSourceModal(false)}
                footer={[
                    <Button
                        form="sourceForm"
                        key="submit"
                        htmlType="submit"
                        type="primary"
                    >
                        Submit
                    </Button>,
                ]}
            >
                <AddSource
                    getSourceTable={updateSources}
                    accessToken={accessToken}
                    selectedLanguage={languageOption}
                    setShowModal={setShowSourceModal}
                />
            </Modal>
        )
        useImperativeHandle(ref, () => ({
            publish_review() {
                if (form.getFieldsValue()?.sourceLink?.length && !sourceId) {
                    setSourceError(true)
                    return
                }
                setSourceError(false)
                form.validateFields()?.then(
                    (res) => {
                        onFinish(res, true)
                    },
                    (error) => {
                        console.log(error)
                    }
                )
            },
        }))
        const handlePublish = () => {
            if (form.getFieldsValue()?.sourceLink?.length && !sourceId) {
                setSourceError(true)
                return
            }
            setSourceError(false)
            form.validateFields()?.then(
                (res) => {
                    onFinish(res, true)
                },
                (error) => {
                    console.log(error)
                }
            )
        }
        async function handleCategory(e) {
            setSelectedCategory(e)
        }
        async function subCategoryfunc(e, first) {
            if (e !== null && e !== undefined) {
                await getSubCategories(accessToken, languageOption, e).then(
                    (resp) => {
                        let updatedSubCategories = resp?.data?.data
                        setOriginalSubcategory(
                            updatedSubCategories?.map((subCat) => ({
                                ...subCat,
                                anti: false,
                                pro: false,
                            }))
                        )
                        if (
                            selectedCategory === 137 &&
                            ((initialFormData?.state_id !== undefined &&
                                initialFormData?.state_id?.length !== 0) ||
                                selectedStateId?.length > 0)
                        ) {
                            updatedSubCategories = updatedSubCategories?.filter(
                                (e) =>
                                    initialFormData?.state_id?.includes(
                                        e.state_id
                                    ) || selectedStateId?.includes(e.state_id)
                            )
                        }
                        const data = updatedSubCategories?.map((subCat) => ({
                            ...subCat,
                            anti: false,
                            pro: false,
                        }))
                        setSubCategory(data)
                    }
                )
            }
            if (first) {
                updateProStatus(pro)
                updateAntiStatus(anti)
            }
        }
        const updateProStatus = (id) => {
            setSubCategory((prevSubCategory) =>
                prevSubCategory.map((subCat) =>
                    subCat.sub_category_id === parseInt(id)
                        ? { ...subCat, pro: !subCat?.pro, anti: false }
                        : { ...subCat, pro: false }
                )
            )
        }
        const updateAntiStatus = (id) => {
            setSubCategory((prevSubCategory) =>
                prevSubCategory.map((subCat) =>
                    subCat.sub_category_id === parseInt(id)
                        ? { ...subCat, anti: !subCat?.anti, pro: false }
                        : { ...subCat, anti: false }
                )
            )
        }

        const [selectedStates, setSelectedStates] = useState([])
        const handleGroupChange = (checkedIds) => {
            let temp = []
            const selectedStates = languageData
                ?.filter((item) => item?.language_id === languageOption)
                ?.flatMap((item) =>
                    item?.states
                        ?.filter((state) =>
                            checkedIds.includes(state?.state_id)
                        )
                        ?.map((state) => {
                            temp.push(state?.state_id)
                            return {
                                id: state?.state_id,
                                name: state?.name,
                            }
                        })
                )
            if (selectedCategory === 137 && temp.length !== 0) {
                const updatedSubCategories = originalSubcategory?.filter((e) =>
                    temp?.includes(e.state_id)
                )
                setSubCategory(updatedSubCategories)
            } else {
                setSubCategory(originalSubcategory)
            }

            setSelectedStateId(temp)
            setSelectedStates(selectedStates)
        }
        useEffect(() => {
            subCategoryfunc(selectedCategory, true)
        }, [languageOption, selectedCategory])
        return (
            <div id="form" style={{ width: '100%', position: 'relative' }}>
                {sourceModal}
                <Form
                    form={form}
                    name="basic"
                    colon={false}
                    scrollToFirstError
                    labelWrap
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={(values) => onFinish(values, false)}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    {employeeLanguage.length > 1 && (
                        <Form.Item
                            label="Language"
                            name="language"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select language',
                                },
                            ]}
                        >
                            <Select onChange={handleLanguageChange}>
                                {languageData?.map((option) => {
                                    if (
                                        employeeLanguage?.includes(
                                            option?.language_id
                                        )
                                    ) {
                                        return (
                                            <Select.Option
                                                key={option?.language_id}
                                                value={option?.language_id}
                                            >
                                                {option?.language_name}
                                            </Select.Option>
                                        )
                                    }
                                })}
                            </Select>
                        </Form.Item>
                    )}
                    <Form.Item label="State Specific" value="state">
                        <Checkbox.Group
                            onChange={handleGroupChange}
                            value={selectedStates?.map((item) => item.id)} // Set selected values based on id
                        >
                            {languageData
                                ?.filter(
                                    (item) =>
                                        item?.language_id === languageOption
                                )
                                ?.flatMap((item) =>
                                    item?.states?.map((state) => (
                                        <Checkbox
                                            key={state?.state_id}
                                            value={state?.state_id}
                                        >
                                            {state?.name}
                                        </Checkbox>
                                    ))
                                )}
                        </Checkbox.Group>
                    </Form.Item>

                    <Form.Item
                        label="Category"
                        name="category"
                        rules={[
                            {
                                required: true,
                                message: 'Please select category',
                            },
                        ]}
                    >
                        <Select
                            placeholder="--Select Category--"
                            onChange={(e) => handleCategory(e)}
                        >
                            {categories
                                ?.filter(
                                    (ct) =>
                                        ct?.language_name ===
                                            languageData?.filter(
                                                (l) =>
                                                    l.language_id ===
                                                    languageOption
                                            )?.[0]?.language_name &&
                                        ct?.type_content?.includes(
                                            currentTableType
                                        )
                                )
                                ?.map((option) => {
                                    return (
                                        <Select.Option
                                            key={option?.category_name}
                                            value={option?.category_id}
                                        >
                                            {option?.category_name}
                                        </Select.Option>
                                    )
                                })}
                        </Select>
                    </Form.Item>
                    {selectedCategory &&
                        roleId !== 5 &&
                        roleId !== 3 &&
                        subCategory?.length !== 0 && (
                            <Form.Item name="subcategory">
                                {subCategory?.map((subcat) => {
                                    return (
                                        <div
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                alignItems: 'center',
                                                margin: '1% 0 1% 30%',
                                            }}
                                            key={subcat.sub_category_id}
                                        >
                                            <span
                                                style={{
                                                    marginRight: '1rem',
                                                    maxWidth: '10rem',
                                                }}
                                            >
                                                {subcat.sub_category_name}
                                            </span>
                                            <div
                                                style={{
                                                    diplay: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Radio
                                                    onClick={(e) =>
                                                        updateProStatus(
                                                            e.target.value
                                                        )
                                                    }
                                                    checked={subcat.pro}
                                                    type="radio"
                                                    name="pro"
                                                    value={
                                                        subcat.sub_category_id
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        margin: '0 .5rem 0 0',
                                                        color: 'gray',
                                                    }}
                                                >
                                                    pro
                                                </label>

                                                <Radio
                                                    onClick={(e) =>
                                                        updateAntiStatus(
                                                            e.target.value
                                                        )
                                                    }
                                                    checked={subcat.anti}
                                                    type="radio"
                                                    name="anti"
                                                    value={
                                                        subcat.sub_category_id
                                                    }
                                                />
                                                <label
                                                    style={{
                                                        margin: '0 0 0 0',
                                                        color: 'gray',
                                                    }}
                                                >
                                                    anti
                                                </label>
                                            </div>
                                        </div>
                                    )
                                })}
                            </Form.Item>
                        )}

                    <Form.Item label="Breaking News" name="breakingNews">
                        <Radio.Group>
                            <Radio value={true}> Yes </Radio>
                            <Radio value={false}> No </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, currentValues) =>
                            prevValues.breakingNews !==
                            currentValues.breakingNews
                        }
                    >
                        {({ getFieldValue }) =>
                            getFieldValue('breakingNews') === true ? (
                                <Form.Item
                                    name="relevancy"
                                    label="Relevancy"
                                    // className="custom-form-item"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'please select relevancy',
                                        },
                                    ]}
                                >
                                    <Radio.Group>
                                        {breakingNewsOptions?.map(
                                            (option, index) => (
                                                <Radio
                                                    value={option.value}
                                                    key={index}
                                                >
                                                    {option.label}
                                                </Radio>
                                            )
                                        )}
                                    </Radio.Group>
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    <Form.Item
                        label="Headline"
                        name="title"
                        rules={[
                            {
                                required: true,
                                whitespace: true,
                                message:
                                    'Value is required and should be less than 75 characters',
                            },
                        ]}
                    >
                        <Input.TextArea
                            className={titleError ? 'error' : ''}
                            maxLength={75}
                            value={title}
                            onChange={(e) => {
                                // titleChange(e.target.value)
                                handleTitleChange(e.target.value)
                            }}
                            placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                        />

                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            className="description-subtext"
                        >
                            <span
                                style={{
                                    color:
                                        title?.length > 75 ? 'red' : '#555555',
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    marginLeft: '10px',
                                }}
                            >
                                {75 - (title?.length ?? 0)} characters
                            </span>
                        </span>
                    </Form.Item>

                    <Form.Item
                        label="Story Details"
                        name="shortDescription"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Value is required and should be less than 400 characters',
                                whitespace: true,
                            },
                        ]}
                    >
                        <Input.TextArea
                            value={shortDescription}
                            rows={2}
                            autoSize
                            style={{ minHeight: '10rem' }}
                            onChange={(e) => {
                                changeDescriptionValue(e.target.value)
                            }}
                            placeholder="Upto 60 words"
                        />

                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                            }}
                            className="description-subtext"
                        >
                            <span
                                style={{
                                    color:
                                        remainingCharCount === 0
                                            ? 'red'
                                            : '#555555',
                                }}
                            >
                                {remainingCharCount} characters
                            </span>
                        </span>

                        <span
                            style={{
                                display: 'flex',
                                justifyContent: 'end',
                                fontSize: '1rem',
                                fontWeight: '500',
                                alignItems: 'center',
                            }}
                        ></span>
                    </Form.Item>
                    {mode === 'review' && (
                        <Form.Item label={'Similar Content'}>
                            <div className="similar-content-wrapper">
                                <div className="get-similar-btn">
                                    {loading && (
                                        <div className="loading-spinner">
                                            <Spin size="small" />
                                        </div>
                                    )}
                                </div>

                                {similarContents?.data?.length === 0 &&
                                    !loading &&
                                    similarContents?.api && (
                                        <div className="no-content-message">
                                            No similar content found
                                        </div>
                                    )}
                                {similarContents?.data?.length > 0 &&
                                    !loading && (
                                        <div>
                                            {similarContents?.data?.map((e) => (
                                                <div
                                                    style={{
                                                        fontSize: '12px',
                                                        marginBottom: '.5rem',
                                                    }}
                                                >
                                                    <a
                                                        href={e?.dynamic_url}
                                                        target="blank"
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        {e?.title}
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                            </div>
                        </Form.Item>
                    )}
                    {keywords_g && (
                        <Form.Item
                            label="Keywords"
                            name="keywords"
                            required={true}
                        >
                            <div
                                className="col-md"
                                style={{ background: 'none' }}
                            >
                                {keywords?.map((keyword, index) => (
                                    <Tag
                                        key={index}
                                        closable
                                        style={{
                                            userSelect: 'none',
                                            marginBottom: '5px',
                                        }}
                                        onClose={(e) => {
                                            e.preventDefault()
                                            handleClose(index)
                                        }}
                                    >
                                        <span>{keyword}</span>
                                    </Tag>
                                ))}
                                <Input
                                    onChange={(e) =>
                                        setKeywordInput(e.target.value)
                                    }
                                    value={keywordInput}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault()
                                            addKeyword(event?.target?.value)
                                            setKeywordInput('')
                                        }
                                    }}
                                    placeholder="Type words and press enter after each word or click generate keywords"
                                />
                                {generate ? (
                                    <Button
                                        style={{ margin: '.5rem 0' }}
                                        loading
                                    >
                                        Generating...
                                    </Button>
                                ) : (
                                    <Button
                                        type="primary"
                                        ghost
                                        style={{ margin: '.5rem 0' }}
                                        onClick={() => {
                                            generateKeyWords(
                                                title,
                                                form.getFieldValue(
                                                    'shortDescription'
                                                )
                                            )
                                        }}
                                    >
                                        Generate Keywords
                                    </Button>
                                )}
                            </div>
                        </Form.Item>
                    )}
                    <Form.Item
                        required
                        value={imagePath}
                        label="Multimedia"
                        name="multimedia"
                        rules={[
                            {
                                required: !imagePath,
                                message: 'Image is required',
                            },
                        ]}
                    >
                        <div style={{ display: 'flex' }}>
                            <span
                                onClick={() => setIsImageAdding(true)}
                                style={{ marginRight: '3rem' }}
                                className="multimedia"
                            >
                                <PictureFilled style={{ color: 'gray' }} />{' '}
                                Image
                            </span>
                            <span
                                onClick={() => setIsVideoAdding(true)}
                                className="multimedia"
                            >
                                <VideoCameraFilled style={{ color: 'gray' }} />{' '}
                                Video
                            </span>
                        </div>
                    </Form.Item>
                    <Form.Item name="source" label="Source">
                        <Radio.Group
                            value={isSourceAvailable}
                            onChange={(e) =>{
                                setIsSourceAvailable(e.target.value)
                                updateSource(languageOption)
                            }
                            }
                        >
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {isSourceAvailable && (
                        <Form.Item name="source_name" label="Source Name">
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <AutoComplete
                                    options={sourcesOptions}
                                    value={sourceValue}
                                    onSearch={(text) => {
                                        setSourceValue(text)
                                        setSourcesOptions(
                                            allSourcesOptions.filter(
                                                ({ value }) =>
                                                    value
                                                        ?.toLowerCase()
                                                        .includes(
                                                            text?.toLowerCase()
                                                        )
                                            )
                                        )
                                    }}
                                    onSelect={onSelectSource}
                                    placeholder="search and select source"
                                />
                                {isPublish(
                                    userPermissions,
                                    'Content',
                                    'Short News'
                                ) && (
                                    <Button
                                        className="plus-sign"
                                        onClick={() => setShowSourceModal(true)}
                                    >
                                        <PlusOutlined />
                                    </Button>
                                )}
                            </div>
                            {sourceError && (
                                <div style={{ color: 'red' }}>
                                    Source is mandatory
                                </div>
                            )}
                        </Form.Item>
                    )}
                    {isSourceAvailable && (
                        <Form.Item label="Source Link" name="sourceLink">
                            <Input placeholder="Source UrlLink" />
                        </Form.Item>
                    )}

                    <Form.Item label="Hashtag">
                        <AutoComplete
                            options={hashTagOptions}
                            value={hashTagValue}
                            onSearch={(text) => {
                                setHashTagValue(text)
                                if (text === '') {
                                    setHashTagId()
                                }
                                setHashtagOptions(
                                    allHashTagOptions?.filter(({ value }) =>
                                        value
                                            ?.toLowerCase()
                                            ?.includes(text?.toLowerCase())
                                    )
                                )
                            }}
                            onSelect={onHashTagSelect}
                            placeholder="select hashtag"
                        />
                    </Form.Item>
                    <Form.Item label=" ">
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                justifyContent: 'center',
                            }}
                            className="buttons_shortnews"
                        >
                            {isSubmitAndDraft(
                                userPermissions,
                                'Content',
                                'Short News'
                            ) &&
                                mode !== 'review' &&
                                (roleId !== '8' ||
                                    roleId !== '25' ) && (
                                    <Button
                                        className="width-100"
                                        type="primary"
                                        onClick={() => handleSave('draft')}
                                        disabled={
                                            isLoading ||
                                            contentStatus === 'approved'
                                        }
                                    >
                                        Save
                                    </Button>
                                )}
                            {isSubmitAndDraft(
                                userPermissions,
                                'Content',
                                'Short News'
                            ) &&
                                mode !== 'review' &&
                                (roleId !== '8' ||
                                    roleId !== '25') && (
                                    <Button
                                        className="submit-buttons width-120"
                                        type="primary"
                                        onClick={() => handleSave('submit')}
                                        disabled={
                                            isLoading ||
                                            contentStatus === 'approved'
                                        }
                                    >
                                        Submit
                                    </Button>
                                )}
                            {isPublish(
                                userPermissions,
                                'Content',
                                'Short News'
                            ) &&
                                (roleId === '8' ||
                                    roleId === '25') &&
                                mode !== 'review' && (
                                    <Button
                                        className="width-120"
                                        type="primary"
                                        onClick={() => handlePublish()}
                                        disabled={isLoading}
                                    >
                                        {mode === 'review'
                                            ? 'Approve'
                                            : 'Publish'}
                                    </Button>
                                )}
                        </div>
                    </Form.Item>
                </Form>
            </div>
        )
    }
)
export default AddShortNewsFormNew
