import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isUserLoggedIn: false,
    accessToken: '',
    categories: [],
    languageData: [],
    locations: [],
    districts:{},
    mandals:{},
    constituencies:{},
    employeeId: '',
    role_id:'',
    userPermissions: {},
    employees: [],
    employeeName: '',
    popup: false,
    userLocation:{},
    employeeLanguages:[],
    rejectedReasonData:[]
}

export const AppSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        userLogin: (state, action) => {
            state.isUserLoggedIn = action?.payload?.isUserLoggedIn
            state.accessToken = action?.payload?.accessToken
            state.userPermissions = {...action?.payload?.permissions}
            state.employeeId = action?.payload?.employeeId
            state.employeeName = action?.payload?.employeeName
            state.role_id=action?.payload?.role_id
        },
        addToken: (state, action) => {
            state.accessToken = action?.payload
            localStorage.setItem('ACCESS_TOKEN', action?.payload)
        },
        
        addEmployeeId: (state, action) => {
            state.employeeId = action?.payload
            localStorage.setItem('EMPLOYEE_ID', action?.payload)
        },
        addEmployeeName: (state, action) => {
            state.employeeName = action?.payload
            localStorage.setItem('EMPLOYEE_NAME', action?.payload)
        },
        addPermissions: (state, action) => {
            state.userPermissions = action?.payload
            localStorage.setItem(
                'USER_PERMISSIONS',
                JSON.stringify(action?.payload)
            )
        },
        addlanguage: (state, action) => {
            state.languageData = action?.payload
        },

        addLocations: (state, action) => {
            state.locations = action?.payload
            const statesToDistricts = {};
            const districtsToMandals = {};
            const statesToConstituencies = {};
        
            action?.payload?.forEach(state => {
                if (!statesToDistricts[state.state_id]) {
                    statesToDistricts[state.state_id] = [];
                }
        
                if (!statesToConstituencies[state.state_id]) {
                    statesToConstituencies[state.state_id] = [];
                }
        
                state.districts.forEach(district => {
                    statesToDistricts[state.state_id].push({
                        district_id: district.district_id,
                        name: district.name,
                        local_name: district.local_name
                    });
        
                    if (!districtsToMandals[district.district_id]) {
                        districtsToMandals[district.district_id] = [];
                    }
        
                    district.mandals.forEach(mandal => {
                        districtsToMandals[district.district_id].push({
                            mandal_id: mandal.mandal_id,
                            name: mandal.name,
                            local_name: mandal.local_name
                        });
                    });
                });
        
                state.assembly_constituencies.forEach(constituency => {
                    statesToConstituencies[state.state_id].push({
                        assembly_constituency_id: constituency.assembly_constituency_id,
                        name: constituency.name,
                        local_name: constituency.local_name,
                        district_id:constituency.district_id
                    });
                });
            });
            // console.log(statesToDistricts,districtsToMandals,statesToConstituencies)
            state.districts=statesToDistricts;
            state.mandals=districtsToMandals;
            state.constituencies=statesToConstituencies;
        
        },

        addCategories: (state, action) => {
            state.categories = action?.payload
        },
        addEmployees: (state, action) => {
            state.employees = action?.payload
        },
        changePopup: (state, action) => {
            state.popup = action?.payload
        },
        addUserLocation : (state,action)=>{
            state.userLocation = action?.payload
        },
        addEmployeeLanguages : (state,action)=>{
            state.employeeLanguages=action?.payload
        },
        addRejectedReasonData : (state,action)=>{
            
            state.rejectedReasonData=action?.payload
        }
    },
})

export const {
    userLogin,
    addlanguage,
    addCategories,
    addToken,
    addEmployeeId,
    addLocations,
    addPermissions,
    addEmployees,
    addEmployeeName,
    addEmployeeLanguages,
    changePopup,
    addUserLocation,
    addRejectedReasonData
    
} = AppSlice.actions

export default AppSlice.reducer
