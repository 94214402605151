import { useState } from 'react';
import download from 'downloadjs';

export const useDownloadProgress = () => {
  const [progress, setProgress] = useState({});
  const [abortControllers, setAbortControllers] = useState({});

  const downloadFile = async (url, index) => {
    if (abortControllers[index]) {
      abortControllers[index].abort();
      setProgress(prev => {
        const newProgress = { ...prev };
        delete newProgress[index];
        return newProgress;
      });
      delete abortControllers[index];
      return;
    }

    const controller = new AbortController();
    setAbortControllers(prev => ({ ...prev, [index]: controller }));

    try {
      const response = await fetch(url, { signal: controller.signal });

      if (!response.ok) {
        console.error('Download failed:', response.statusText);
        return;
      }

      const contentLength = response.headers.get('Content-Length');
      const total = contentLength ? parseInt(contentLength, 10) : 0;

      const reader = response.body.getReader();
      let receivedLength = 0;
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        chunks.push(value);
        receivedLength += value.length;
        setProgress(prev => ({
          ...prev,
          [index]: (receivedLength / total) * 100
        }));
      }

      const blob = new Blob(chunks);
      download(blob, 'file.mp4');

      setProgress(prev => {
        const newProgress = { ...prev };
        delete newProgress[index];
        return newProgress;
      });
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Download aborted');
      } else {
        console.error('Download failed:', error);
      }
    } finally {
      setAbortControllers(prev => {
        const newControllers = { ...prev };
        delete newControllers[index];
        return newControllers;
      });
    }
  };

  return { progress, downloadFile };
};

export default useDownloadProgress;
