import {
    LoadingOutlined,
    DownloadOutlined,
} from '@ant-design/icons'
import {
    AutoComplete,
    Button,
    Col,
    DatePicker,
    Form,
    Image,
    Input,
    message,
    Modal,
    Radio,
    Row,
    Select,
    Spin,
    Tag,
} from 'antd'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import {
    getHashtagTableData,
    getImageUrl,
    getVideoUrl,
    updateUgcContent,
    getSubCategories,
    getRejectedReasons,
    getSimilarContent,
} from '../../../AadhanApi'
import {
    breakingNewsOptions,
    expiryHoursOptions,
} from './ReviewContentConstant'
import './ReviewContentForm.scss'
import { useSelector } from 'react-redux'
import AddSource from '../../Source/AddSource'
import CropperModel from '../CropperModal'
import MuxPlayer from '@mux/mux-player-react'
import ImageUploader from '../../image-uploader-crop/ImageUploader'
import Speaker from '../../Speaker'
import RejectModal from './RejectModal'
import Spellcheck from '../../Spellcheck'
import spellcheckFunction from '../../AIResponseFilter/Title&DescriptionResponseFilter'
import generateKeyWord from '../../AIResponseFilter/KeywordsResponse'
import ImageCropper from './ImageCropper'
import { addLogo } from '../../addLogo'
import SmartphonePreview from '../../SmartphonePreview/SmartphonePreview'
import { checkContentForDay } from '../../CheckContentForDay/checkContentForDay'
const ReviewContentForm = ({
    data,
    titleChange,
    descriptionChange,
    setImagePath,
    initialFormData,
    languageData,
    categories,
    accessToken,
    contentId,
    setVideoPath,
    hashTagId,
    setHashTagId,
    sourceId,
    setFileList,
    fileList,
    videoList,
    setVideoList,
    code,
    setSelectedVideoUrl,
    setSelectedImageUrl,
    selectedImageUrl,
    selectedVideoUrl,
    setRejectedVideoUrl,
    setRejectedImageUrl,
    rejectedVideoUrl,
    rejectedImageUrl,
}) => {
    const location = useLocation()
    const [form] = Form.useForm()
    const [subCategory, setSubCategory] = useState([])
    const [showSourceModal, setShowSourceModal] = useState()
    const employeeId = useSelector((state) => state.app.employeeId)
    const roleId = useSelector((state) => state.app.role_id)
    const employeeName = useSelector((state) => state.app.employeeName)
    const [shortDescription, setShortDescription] = useState(
        initialFormData?.shortDescription
    )
    const [selectedCategory, setSelectedCategory] = useState(
        initialFormData?.category_id
    )
    const [rejectionReason, setRejectionReason] = useState('')
    const [showCustomReasonInput, setShowCustomReasonInput] = useState(false)
    const [remainingWordsCount, setRemaingWordsCount] = useState(59)
    const [remainingCharCount, setRemaingCharCount] = useState(400)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [imageUrl, setImageUrl] = useState(null)
    const [hashTagOptions, setHashtagOptions] = useState([])
    const rejectionReasonData = useSelector((state)=>state.app.rejectedReasonData);
    const [allHashTagOptions, setAllHashtagOptions] = useState([])
    const [keywords, setKeywords] = useState([])
    const [keywordInput, setKeywordInput] = useState('')
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [isVideoUploading, setIsVideoUploading] = useState(false)
    const [videoPathUrl, setVideoPathUrl] = useState(
        initialFormData?.stream_video_url
    )
    const [hashData, setHashData] = useState([])
    const [hashTagValue, setHashTagValue] = useState('')
    const navigate = useNavigate()
    const inputVideoRef = useRef(null)
    const [titleError, setTitleError] = useState()
    const [title, setTitle] = useState('')
    const [fileListError, setFileListError] = useState(false)
    const [maxImageError, setMaxImageError] = useState(false)
    const [maxVideoError, setMaxVideoError] = useState(false)
    const [englishTitle, setEnglishTitle] = useState('')
    const [selectedImageRadio, setSelectedImageRadio] = useState()
    const [selectedImageRadio1, setSelectedImageRadio1] = useState()
    const [selectedVideoRadio, setSelectedVideoRadio] = useState(null)
    const [imageError, setImageError] = useState(false)
    const [isImageSelected, setIsImageSelected] = useState(false)
    const [selectedFile, setSelectedFile] = useState('')
    const [rejectionReasonError, setRejectionReasonError] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const locationsData = useSelector((state) => state.app.locations)
    const allDistricts = useSelector((state) => state.app.districts)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const allMandals = useSelector((state) => state.app.mandals)
    const [districtsData, setDistrictsData] = useState([])
    const [mandalsData, setMandalsData] = useState([])
    const [selectedMandalId, setSelectedMandalId] = useState(
        initialFormData?.mandal_id
    )
    const [mandalids, setMandalids] = useState([])
    const [selectDistricIds, setSelectedDistricIds] = useState(
        initialFormData?.district_id
    )
    const [selectedAssemblyId, setSelectedAssemblyId] = useState(
        initialFormData?.constituency_ids
    )
    const tokenRegex = /token=([^&]*)/
    const playbackIdRegex = /\/([^\.]*)\.m3u8/
    const [reason, setReason] = useState('')
    const [selectStateId, setSelectStateId] = useState('')
    const [change, setChange] = useState(0)
    const [assemblyConstituenciesData, setAssemblyConstituenciesData] =
        useState([])
    const [generate, setGenerate] = useState(false)
    const [loading, setLoading] = useState(false)
    const [similarContents, setSimilarContents] = useState({
        data: [],
        api: false,
    })
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [spellTitle, setSpellTitle] = useState(false)
    const [spellDescription, setSpellDescription] = useState(false)
    const [AIData, setAiData] = useState({})
    const [entities,setEntities] = useState("");
    const [iab,setIab] = useState('')
    useEffect(() => {
        setSelectedAssemblyId(initialFormData?.constituency_ids)
        setSelectedDistricIds(initialFormData?.district_id)
        setSelectedMandalId(initialFormData?.mandal_id)
        setSelectStateId(initialFormData?.state_id)
        setLanguageOption(
            initialFormData?.language_id !== '' ? initialFormData?.language : 1
        )
        onRadioChange({ target: { name: 'image1' } }, 0)
        // onRadioChange({ target: { name: 'video1' } }, 0)
    }, [locationsData, initialFormData, videoPathUrl])
    useEffect(() => {
        setDistrictsData(allDistricts?.[selectStateId])
    }, [selectStateId])
    const handleRejectionChanges = (value) => {
        setRejectionReasonError(false)
        setShowCustomReasonInput(value === 'Custom Reason')
        setRejectionReason(value)
    }
    const onStateChange = (id) => {
        setIsrun(1)
        form.setFieldsValue({ district: '' })
        form.setFieldValue({ assemblyconstituencies: '' })
        setMandalsData([])
        let ids

        locationsData.map((e) => {
            if (e.name === id) {
                setSelectStateId(e.state_id)
                ids = e.state_id
            }
        })
        if (ids) {
            getAssembly_Constituencies(ids)
        }
    }
    const onDistrictChange = (id) => {
        let temp = []
        districtsData.map((e) => {
            if (id.includes(e.name) && !temp.includes(e.district_id)) {
                temp.push(e.district_id)
            }
        })

        setSelectedDistricIds(temp)
        if (temp.length !== 0) {
            getMandalsList(temp[temp.length - 1])
        }
    }

    const onMandalChange = (id) => {
        let temp = []
        mandalsData.map((e) => {
            if (id.includes(e.name) && !temp.includes(e.mandal_id)) {
                temp.push(e.mandal_id)
            }
        })
        setSelectedMandalId(temp)
    }

    useEffect(() => {
        if (initialFormData?.language === languageOption) {
            handleCategory(initialFormData?.category)
        }
    }, [initialFormData?.language === languageOption, initialFormData])
    const getMandalsList = async (id) => {
        const arr = allDistricts?.[selectStateId]?.filter((e) => {
            if (form.getFieldValue('district').includes(e.name)) {
                return e.district_id
            }
        })

        let temp = []
        arr?.map((e) => {
            temp.push(...allMandals?.[e.district_id])
        })

        setMandalsData(temp)
    }

    const getAssembly_Constituencies = async (id) => {
        setAssemblyConstituenciesData(allConstituencies?.[id])
    }
    function handleAssemblyConstituencies(event) {
        let temp = []

        assemblyConstituenciesData.map((e) => {
            if (
                event.includes(e.name) &&
                !temp.includes(e.assembly_constituency_id)
            ) {
                temp.push(e.assembly_constituency_id)
            }
        })

        setSelectedAssemblyId(temp)
    }
    const [isrun, setIsrun] = useState(0)
    useEffect(() => {
        if (isrun === 0) {
            locationsData.map((e) => {
                if (initialFormData.state) {
                    if (initialFormData.state.includes(e.name)) {
                        getAssembly_Constituencies(e.state_id).then(() => {})
                    }
                }
            })

            if (initialFormData.district_id) {
                initialFormData.district_id.map((e) => {
                    getMandalsList(e)
                })
            }
        }
    }, [initialFormData, selectDistricIds])

    useEffect(() => {
        locationsData.map((e) => {
            if (initialFormData.state_name) {
                if (initialFormData.state_name.includes(e.name)) {
                    getAssembly_Constituencies(e.state_id).then(() => {
                        handleAssemblyConstituencies([e.state_id])
                    })
                }
            }
        })
    }, [change, locationsData])
    useEffect(() => {
        changeDescriptionValue(initialFormData?.shortDescription ?? '')
        handleTitleChange(initialFormData?.title)
        handleRejectionChange(initialFormData?.rejectionReason)
        handleFindSimilar(initialFormData?.title,initialFormData?.shortDescription)
       
    }, [initialFormData])

    useEffect(() => {
        form.setFieldsValue(initialFormData)
        setVideoPathUrl(form.getFieldValue('video'))
        if (
            initialFormData?.keywords !== '' &&
            initialFormData?.keywords?.split(',')?.length > 0
        ) {
            setKeywords(initialFormData?.keywords?.split(','))
        } else {
            generateKeyWords(
                initialFormData?.title,
                initialFormData?.shortDescription ?? ''
            )
        }
        if (initialFormData?.state) {
            let filteredState = locationsData
                ?.map((ele) => {
                    if (initialFormData?.state.includes(ele.state_id)) {
                        return ele
                    }
                })
                .filter((Undefined) => Undefined !== undefined)

            let filteredDistrict = []
            filteredState?.forEach((ele) => {
                filteredDistrict.push(ele.districts)
            })
            let splitDistricts = []
            filteredDistrict.forEach((ele, i) => {
                splitDistricts = [...splitDistricts, ...ele]
            })
            setDistrictsData(splitDistricts)
            if (initialFormData?.district) {
                let filteredMandals = []
                splitDistricts?.forEach((ele) => {
                    filteredMandals.push(ele.mandals)
                })

                let splitMandals = []
                filteredMandals.forEach((ele, i) => {
                    splitMandals = [...splitMandals, ...ele]
                })
                setMandalsData(splitMandals)
            }
        }
    }, [form, initialFormData, locationsData])
    
    useEffect(() => {
        if (initialFormData?.language || languageData?.[0]?.language_id) {
            updateHashtagSource(
                initialFormData?.language !== '' ? languageOption : 1
            )
        }
        setReason('')
        setRejectionReason('')
    }, [languageOption])
    const downloadImage = (imageUrl) => {
        fetch(imageUrl)
            .then((response) => response.blob())
            .then((blob) => {
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = 'image.jpg'
                const event = new MouseEvent('click')
                a.dispatchEvent(event)
                window.URL.revokeObjectURL(url)
            })
            .catch((error) => {
                console.error('Error downloading image:', error)
            })
    }
    const createRequest = (values, type) => {
        let languageName = languageData
            ?.map((ele, i) => {
                if (ele.language_id == values?.language) {
                    return ele.language_name
                }
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]

        let categoryName = categories
            ?.map((ele, i) => {
                if (
                    ele.language_name === languageName &&
                    ele.category_id === values.category
                ) {
                    return ele.category_name
                }
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]
        let mandal_ids = []
        mandalids.map((e) => {
            mandal_ids.push(e.id)
        })
        if (mandal_ids === undefined) {
            mandal_ids = []
        }
        let district_ids = []
        for (let i = 0; i < values?.district?.length; i++) {
            for (let j = 0; j < districtsData?.length; j++) {
                if (values?.district[i] === districtsData[j].name) {
                    district_ids.push(districtsData[j].district_id)
                }
            }
        }
        let state_Name = values?.state
        if (!Array.isArray(values?.state)) {
            state_Name = [values?.state]
        }
        const checkforday = checkContentForDay(title,languageOption);
        const resposeObject = {
            title: values?.title
                ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                .replace(
                    /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                    '$1 '
                ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                .replace(/(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/, '$1') // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                .trim(), // Trim leading and trailing spaces
            uploaded_by: initialFormData?.uploaded_by,
            source_id: sourceId,
            source_url: values?.sourceLink ?? '',
            category_id: values?.category,
            expiry_hours:
                values?.expiryHours === 'Never' ? 0 : values?.expiryHours,
            uploaded_date: initialFormData?.uploaded_date,
            published_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            updated_by: employeeId,
            relevancy: values?.relevancies,
            dynamic_url: '',
            is_active: true,
            content_status: type,
            language_id: languageOption,
            description:
                !values?.shortDescription.includes('*') &&
                !values?.shortDescription.includes('**')
                    ? values?.shortDescription
                          ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                          .replace(
                              /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                              '$1 '
                          ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                          .replace(
                              /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/,
                              '$1'
                          ) // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                          .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                          .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                          .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                          .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                          .trim() // Trim leading and trailing spaces
                    : values?.shortDescription,
            image_url:
                type === 'approved' ? [...selectedImageUrl] : [fileList[0]],
            rejected_image_urls: [...rejectedImageUrl],
            code,
            yt_video_url: values?.youtubeLink,
            updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            breaking_news: values?.breakingNews,
            state_name:
                values?.locationSpecific !== 'no' && state_Name[0] !== undefined
                    ? state_Name
                    : [''],

            district:
                values?.locationSpecific !== 'no'
                    ? selectDistricIds?.join(',')
                    : '',
            mandal_name:
                values?.locationSpecific !== 'no' ? values?.mandal : [''],
            district_name:
                values?.locationSpecific !== 'no' ? values?.district : [''],
            mandal:
                values?.locationSpecific !== 'no'
                    ? selectedMandalId?.join(',')
                    : '',
            state: values?.locationSpecific !== 'no' ? selectStateId : '',
            constituency_ids:
                values?.locationSpecific !== 'no' ? selectedAssemblyId : [''],
            constituency_names:
                values?.locationSpecific !== 'no'
                    ? values?.assemblyconstituencies
                    : [''],
            city: '',
            stream_video_url: selectedVideoUrl,
            rejected_stream_video_urls: rejectedVideoUrl,
            mp4_video_url: '',
            image_url_16_9: '',
            image_url_3_4: '',
            thumbnailimage_url: '',
            hashtag_id: hashTagId,
            keywords: keywords?.toString(),
            entities:entities,
            iab_category:iab,
            // disturbing_visuals: values?.disturbingVisuals,
            left_color: '',
            right_color: '',
            upvote: 0,
            linked_content_id: '',
            rejected_reason: showCustomReasonInput ? reason : rejectionReason,
            is_proof_read: true,
            proof_read_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            proof_read_by: employeeId,
            uploaded_by_name: initialFormData?.uploadedByName || employeeName,
            proof_read_by_name: employeeName,
            category_name: categoryName,
        }
        if(checkforday?.today || checkforday?.tomorrow){
            resposeObject['expiry_hours'] = checkforday?.expiry
        }
        if (location.pathname.includes('home/reviewContent')) {
            resposeObject['assigned_at'] = initialFormData?.assigned_at
            resposeObject['assigned_to'] = initialFormData?.assigned_to
        }
        if (values?.gender) {
            resposeObject['gender'] = values?.gender
        }
        if (!subCategory.find((item) => item?.pro === true)) {
            resposeObject['sub_category_id'] = null

            resposeObject['sub_category_name'] = null
        } else {
            if (!subCategory.find((item) => item?.pro === true)) {
                resposeObject['sub_category_id'] = null

                resposeObject['sub_category_name'] = null
            } else {
                const selectedSubcategory = subCategory.find(
                    (item) => item?.pro === true
                )
                resposeObject['sub_category_id'] = selectedSubcategory
                    ? selectedSubcategory.sub_category_id
                    : ''

                resposeObject['sub_category_name'] =
                    selectedSubcategory.sub_category_name
                        ? selectedSubcategory?.sub_category_name
                        : ''
            }
        }
        if (!subCategory.find((item) => item?.anti === true)) {
            resposeObject['anti_sub_category_id'] = null

            resposeObject['anti_sub_category_name'] = null
            return resposeObject
        } else {
            if (!subCategory.find((item) => item?.anti === true)) {
                resposeObject['anti_sub_category_id'] = null

                resposeObject['anti_sub_category_name'] = null
                return resposeObject
            } else {
                const selectedSubcategory = subCategory.find(
                    (item) => item?.anti === true
                )
                resposeObject['anti_sub_category_id'] = selectedSubcategory
                    ? selectedSubcategory.sub_category_id
                    : ''

                resposeObject['anti_sub_category_name'] =
                    selectedSubcategory.sub_category_name
                        ? selectedSubcategory?.sub_category_name
                        : ''

                return resposeObject
            }
        }
    }
    useEffect(() => {
        let arr = []
        const temp1 = initialFormData?.mandal_id

        const name = initialFormData?.mandal

        temp1?.map((e, index) => {
            arr.push({ name: name[index], id: e })
        })
        setMandalids(arr)

        let arr1 = []
        const temp2 = initialFormData?.district_id

        const name_ = initialFormData?.district

        temp2?.map((e, index) => {
            arr1.push({ name: name_[index], id: e })
        })
    }, [initialFormData])

    const handleSave = async (type) => {
        try {
            const validation = await form.validateFields([
                'title',
                'video',
                'language',
                'category',
                'expiryHours',
                'thumbnailImage',
                'proofRead',
                'state',
            ])
            if (validation?.errorFields?.length > 0) {
                return
            }
        } catch (e) {
            e?.errorFields?.length &&
                e.errorFields.forEach((ele) => {
                    if (
                        ele?.name === 'title' ||
                        ele?.name === 'category' ||
                        ele?.name === 'video' ||
                        ele?.name === 'title'
                    ) {
                        window.scrollTo(0, 0)
                    }
                })
            return
        }
        if (type === 'approved' && !selectedImageUrl.length) {
            setImageError(true)
            setRejectionReasonError(false)
            if (languageOption !== 1 && !englishTitle.length) {
            }
            return
        } else if (
            type === 'rejected' &&
            !rejectionReason?.length &&
            !reason?.length
        ) {
            setRejectionReasonError(true)
            setImageError(false)

            return
        }
        if (keywords.length === 0 && type !== 'rejected') {
            alert('please enter or generate keywords')
            return
        }

        setIsDisabled(true)

        const request = createRequest({ ...form.getFieldsValue() }, type)
        // if(location.pathname.includes("home/reviewContent") && type!=='rejected'){
        //     setIsModalOpen(true)
        //     setResponse(request)
        //     return
        // }

        if (initialFormData?.created_type === 'reporter') {
            request.state = data?.state
            request.state_name = data?.state_name
            request.district = data?.district
            request.district_name = data?.district_name
            request.mandal = data?.mandal
            request.mandal_name = data?.mandal_name
            request.constituency_ids = data?.constituency_ids
            request.constituency_names = data?.constituency_names
            request.hashtag_id = data?.hashTagId
        }

        const ugcContentResponse = await updateUgcContent(
            request,
            contentId,
            accessToken
        )
        if (ugcContentResponse?.status === 200) {
            
            if (location.pathname.includes('home/reviewContent')) {
                if (type === 'rejected' || type === 'approved') {
                    setTimeout(() => {
                        
                        navigate('/home/review')
                    }, 1500) // Delay is 2000 milliseconds (2 seconds)
                    message.success("UG content updated successfully")
                    return
                }
            }
            message.success("UG content updated successfully")
            navigate('/home/ug')
        } else if (
            ugcContentResponse?.data ===
            'UG Content found but no changes were made'
        ) {
        }
    }

    useEffect(() => {
        if (hashTagId) {
            setHashTagValue(
                hashData?.filter((h) => h.hashtag_id === hashTagId)?.[0]
                    ?.hashtag_name ?? ''
            )
        }
    }, [hashData])

    const onFinish = async (values) => {
        const request = createRequest(values)
        request.content_status = 'approved'

        if (true) {
            request.proof_read_by = employeeId
            request.proof_read_date = dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
            request.is_proof_read = true
        }

        const ugcContentReponse = await updateUgcContent(
            request,
            contentId,
            accessToken
        )
        if (ugcContentReponse?.status === 200) {
            navigate('/home/ug')
        } else if (
            ugcContentReponse?.data ===
            'UG Content found but no changes were made'
        ) {
            // add changes here
        }
    }

    const updateHashtagSource = async (language_id) => {
        if (language_id) {
            const hashtagDataResponse = await getHashtagTableData(
                language_id,
                '',
                accessToken
            )
            

            setHashData(hashtagDataResponse?.data)
           
            setHashtagOptions(
                hashtagDataResponse?.data?.map((option) => ({
                    value: option.hashtag_name,
                })) ?? []
            )
            setAllHashtagOptions(
                hashtagDataResponse?.data?.map((option) => ({
                    value: option.hashtag_name,
                })) ?? []
            )
        }
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const onHashTagSelect = (val) => {
        setHashTagValue(val)
        setHashTagId(
            hashData?.filter((h) => h.hashtag_name === val)?.[0]?.hashtag_id
        )
    }
    const getRemainingWords = (value, type, max) => {
        if (type === 'words') {
            const remaingWords = max - value?.split(' ')?.length
            return remaingWords
        } else {
            const remainingChar = max - value.length
            return remainingChar
        }
    }

    const changeDescriptionValue = (descriptionValue) => {
        // Clean up the description value

        let cleanedValue = descriptionValue

        if (cleanedValue?.length && languageOption === 2) {
            // document.getElementById("desc-checker").innerHTML = cleanedValue
        }
        if (cleanedValue?.split('\n')?.length <= 9) {
            form.setFieldValue('shortDescription', cleanedValue)
            const remainingWords = getRemainingWords(cleanedValue, 'words', 60)
            const remainingChars = getRemainingWords(cleanedValue, 'char', 400)
            if (remainingWords > -1 && remainingChars > -1) {
                form.setFieldValue('shortDescription', cleanedValue)
                setShortDescription(cleanedValue)
                setRemaingCharCount(remainingChars)
                setRemaingWordsCount(remainingWords)
            } else if (cleanedValue === '') {
                setRemaingCharCount(400)
                setRemaingWordsCount(59)
            } else {
                if (remainingWords < 0) {
                    const newDescription = cleanedValue
                        .split(' ')
                        .slice(0, remainingWords)
                        .join(' ')
                    changeDescriptionValue(newDescription)
                } else if (remainingChars < 0) {
                    const newDescription = cleanedValue
                        .split('')
                        .slice(0, remainingChars)
                        .join('')
                    changeDescriptionValue(newDescription)
                }
            }
        }
    }

    const handleClose = (index) => {
        const key = [...keywords]
        key.splice(index, 1)
        setKeywords(key)
    }

    const addKeyword = (keyword) => {
        setKeywords([...keywords, keyword])
    }
    const getImagePath_ = async (path) => {
        setIsImageUploading(true)
        setSelectedImageRadio1(selectedImageRadio)
        const pathUri = await getImageUrl(
            path,
            'shortnews',
            accessToken,
            languageOption 
        )

        if (pathUri?.data) {
            setMaxImageError(false)
            let url =
                pathUri?.data
            // const logoadded =await addLogo(url,'https://static.aadhan.in/mainImages/aadhan_white_logo.png' ,accessToken ,languageOption)
            const updatedFileList = [...fileList, url]

            setFileList(updatedFileList)
            setIsImageUploading(false)
        }
    }
    const handleFindSimilar = async (title,shortDescription) => {
        setLoading(true)
        if (
            !initialFormData?.title || 
            initialFormData.title.trim() === '' 
          ) {
            setLoading(false);
            return;
          }
          
        try {
            const response = await getSimilarContent(languageOption,`${title} ${shortDescription}`,accessToken)
            setSimilarContents({ data: response?.data?.data, api: true })

        } catch (error) {
            console.error('Error fetching similar content:', error)
        } finally {
            setLoading(false)
        }
    }
    const getImagePath = async (path) => {
        setIsImageUploading(true)
        setSelectedImageRadio1(selectedImageRadio)
        const pathUri = await getImageUrl(
            path,
            'shortnews',
            accessToken,
            languageOption 
        )

        if (pathUri?.data) {
            setMaxImageError(false)
            let url =
                pathUri?.data

            const updatedFileList = [...fileList]
            updatedFileList[selectedImageRadio] = url
            let imageList = updatedFileList.slice(0)
            const removedElement = imageList.splice(selectedImageRadio, 1)
            setSelectedImageUrl(removedElement)
            setRejectedImageUrl(imageList)
            setImagePath(url)
            setFileList(updatedFileList)
            setIsImageUploading(false)
            setIsImageSelected(false)
        }
    }


    // const getVideoPath = async (path) => {
    //     if (path && path?.size / (1024 * 1024) <= 50) {
    //         setIsVideoUploading(true)
    //         const pathUri = await getVideoUrl(path, accessToken)

    //         setMaxVideoError(false)
    //         if (pathUri?.data) {
    //             setVideoPath(pathUri?.data)
    //             setVideoPathUrl(pathUri?.data)

    //             setVideoList([...videoList, pathUri?.data])

    //             setIsVideoUploading(false)
    //         }
    //     } else {
    //         alert('Video Size should be less than or Equal to 50Mb')
    //         inputVideoRef.current.value = null
    //     }
    // }

    const handleTitleChange = (value) => {
        if (value) {
            let cleanedValue = value
            setTitle(cleanedValue)
            form.setFieldValue('title', cleanedValue)
            setTitleError(cleanedValue.length > 75)
        } else {
            setTitle('')
            form.setFieldValue('title', '')
            setTitleError(false)
        }
    }

    const handleRejectionChange = (value) => {
        form.setFieldValue('rejectionReason', value)
        setRejectionReason(value)
    }
    async function handleCategory(e) {
        setSelectedCategory(null)
        if (e !== null && e !== undefined) {
            setSelectedCategory(e)
            await getSubCategories(accessToken, languageOption, e).then(
                (resp) => {
                    let updatedSubCategories = resp?.data?.data
                    if(e===137 && initialFormData?.state_id!==undefined && initialFormData?.state_id){
                       updatedSubCategories = updatedSubCategories?.filter((e)=>e.state_id===initialFormData?.state_id) 
                    }
                    const data = updatedSubCategories?.map(
                        (subCat) => ({
                            ...subCat,
                            anti: false,
                            pro: false,
                        })
                    )
                    setSubCategory(data)
                }
            )
        } else {
            setSelectedCategory(e)
        }
    }
    const updateProStatus = (id) => {
        setSubCategory((prevSubCategory) =>
            prevSubCategory.map((subCat) =>
                subCat.sub_category_id === parseInt(id)
                    ? { ...subCat, pro:!subCat?.pro, anti: false }
                    : { ...subCat, pro: false }
            )
        )
    }

    const updateAntiStatus = (id) => {
        setSubCategory((prevSubCategory) =>
            prevSubCategory.map((subCat) =>
                subCat.sub_category_id === parseInt(id)
                    ? { ...subCat, anti: !subCat?.anti, pro: false }
                    : { ...subCat, anti: false }
            )
        )
    }

    const updateSources = () => {
        updateHashtagSource(languageOption)
    }

    const sourceModal = (
        <Modal
            open={showSourceModal}
            title={'Add Source'}
            onCancel={() => setShowSourceModal(false)}
            footer={[
                <Button
                    form="sourceForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Submit
                </Button>,
            ]}
        >
            <AddSource
                getSourceTable={updateSources}
                accessToken={accessToken}
                selectedLanguage={languageOption}
                setShowModal={setShowSourceModal}
            />
        </Modal>
    )
    const onRadioChangeVideo=(event,index,url)=>{
                       if(selectedVideoRadio===index){
                setSelectedVideoRadio(null)
                setSelectedVideoUrl([])
                setRejectedVideoUrl(videoList)
            }
            else{
                setSelectedVideoUrl([url])
                
                setSelectedVideoRadio(index)
                setVideoPath([url])
                const removedElement = videoList.filter((e,ind)=>ind!==index)
                setRejectedVideoUrl(removedElement)
            }
        
    }

    const onRadioChange = (event, index) => {
        if (event.target.name === 'image1') {
            setSelectedImageRadio1(index)
            setSelectedImageRadio(index)
            let updatedFileList = [...fileList]
            let imageList = updatedFileList.slice(0)
            let selectedEle = imageList.splice(index, 1)
            setSelectedFile(selectedEle)
            setSelectedImageUrl(selectedEle)
        }
    }
    const [spellCheckLoader, setSpellCheckLoader] = useState(false)
    async function spellCheck(title, description) {
        if (title !== '' || description !== '') {
            setSpellCheckLoader(true)
            const data = await spellcheckFunction(title, description)
            setSpellCheckLoader(false)
            setAiData(data)
        }
    }
    async function generateKeyWords(title, description) {
        if (title !== '' || description !== '') {
            setGenerate(true)
            const data = await generateKeyWord(title, description,languageOption)
            const keywordsArray = initialFormData?.keywords
                ? initialFormData?.keywords?.split(',')
                : []
            const newKeywords = data?.keywords?.split(',') ?? []
            const uniqueArray = [
                ...new Set([
                    ...keywordsArray,
                    ...newKeywords?.map((keyword) => keyword?.trim()), // Trim each keyword
                ]),
            ]
            const cleanKeywords = uniqueArray?.filter(
                (keyword) => keyword !== '' && !keyword?.includes('\n')
            )
            setKeywords(cleanKeywords)
            setEntities(data?.entities)
            setIab(data?.iab)
            setGenerate(false)
        }
    }
    return (
        <div id="form" style={{ width: '100%' }}>
            <Form
                form={form}
                name="basic"
                colon={false}
                scrollToFirstError
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Row gutter={16}>
                    <Col xs={24} md={14}>
                        <Form.Item
                            label="Category"
                            name="category"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter value',
                                },
                            ]}
                        >
                            <Select
                                onChange={(e) => handleCategory(e)}
                                placeholder="--Select Category--"
                            >
                                {categories
                                    ?.filter(
                                        (ct) =>
                                            ct?.language_name ===
                                            languageData?.filter(
                                                (l) =>
                                                    l.language_id ===
                                                    languageOption
                                            )?.[0]?.language_name
                                    )
                                    ?.map((option) => {
                                        return (
                                            <Select.Option
                                                key={option?.category_name}
                                                value={option?.category_id}
                                            >
                                                {option?.category_name}
                                            </Select.Option>
                                        )
                                    })}
                            </Select>
                        </Form.Item>
                        {selectedCategory &&
                            roleId !== 5 &&
                            roleId !== 3 &&
                            subCategory?.length !== 0 &&
                            form.getFieldValue('category') !== undefined && (
                                <Form.Item name="subcategory">
                                    {subCategory?.map((subcat) => {
                                        return (
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    width: '100%',
                                                    alignItems: 'center',
                                                    margin: '1% 0 1% 30%',
                                                }}
                                                key={subcat.sub_category_id}
                                            >
                                                <span
                                                    style={{
                                                        marginRight: '1rem',
                                                        maxWidth: '10rem',
                                                    }}
                                                >
                                                    {subcat.sub_category_name}
                                                </span>
                                                <div
                                                    style={{
                                                        diplay: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Radio
                                                        onClick={(e) =>
                                                            updateProStatus(
                                                                e.target.value
                                                            )
                                                        }
                                                        checked={subcat.pro}
                                                        type="radio"
                                                        name="pro"
                                                        value={
                                                            subcat.sub_category_id
                                                        }
                                                    />
                                                    <label
                                                        style={{
                                                            margin: '0 .5rem 0 0',
                                                            color: 'gray',
                                                        }}
                                                    >
                                                        pro
                                                    </label>

                                                    <Radio
                                                        onClick={(e) =>
                                                            updateAntiStatus(
                                                                e.target.value
                                                            )
                                                        }
                                                        checked={subcat.anti}
                                                        type="radio"
                                                        name="anti"
                                                        value={
                                                            subcat.sub_category_id
                                                        }
                                                    />
                                                    <label
                                                        style={{
                                                            margin: '0 0 0 0',
                                                            color: 'gray',
                                                        }}
                                                    >
                                                        anti
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </Form.Item>
                            )}
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Value is required and should be less than 75 characters',
                                },
                            ]}
                        >
                            {!spellTitle ? (
                                <div style={{ position: 'relative' }}>
                                    <Input.TextArea
                                        className={titleError ? 'error' : ''}
                                        maxLength={75}
                                        value={title}
                                        onChange={(e) => {
                                            titleChange(e.target.value)
                                            handleTitleChange(e.target.value)
                                        }}
                                        placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            marginLeft: '4px',
                                            bottom: 0,
                                        }}
                                    >
                                        <Speaker
                                            text={title}
                                            language={
                                                languageOption === 1
                                                    ? 'en-US'
                                                    : languageOption === 2
                                                    ? 'hi-IN'
                                                    : languageOption === 4
                                                    ? 'ta-IN'
                                                    : 'hi-IN'
                                            }
                                        />
                                    </span>
                                </div>
                            ) : (
                                <Spellcheck
                                    generate={spellCheckLoader}
                                    setSpellDescription={setSpellTitle}
                                    textValue={title}
                                    setTextValue={setTitle}
                                    wordsToHighlight={
                                        AIData?.misspelledTitleWords
                                            ? AIData?.misspelledTitleWords
                                            : []
                                    }
                                    correctedWords={
                                        AIData?.correctedTitleWords
                                            ? AIData?.correctedTitleWords
                                            : []
                                    }
                                />
                            )}
                            <span
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                                className="description-subtext"
                            >
                                <span
                                    style={{
                                        color:
                                            title?.length > 75
                                                ? 'red'
                                                : '#555555',
                                        fontSize: '14px',
                                        marginLeft: '10px',
                                    }}
                                >
                                    {75 - (title?.length ?? 0)} characters
                                    {title?.length >= 75 && (
                                        <span
                                            style={{
                                                color: 'red',
                                                fontStyle: 'italic',
                                            }}
                                        >
                                            {' '}
                                            - Title is too long
                                        </span>
                                    )}
                                </span>
                            </span>
                        </Form.Item>

                        <Form.Item
                            label="Short Description"
                            name="shortDescription"
                            rules={[
                                {
                                    required: true,
                                    message: 'please input description',
                                },
                            ]}
                        >
                            {!spellDescription ? (
                                <div style={{ position: 'relative' }}>
                                    <Input.TextArea
                                        value={shortDescription}
                                        rows={2}
                                        autoSize
                                        style={{ minHeight: '5rem' }}
                                        onChange={(e) => {
                                            descriptionChange(e.target.value)
                                            changeDescriptionValue(
                                                e.target.value
                                            )
                                        }}
                                        placeholder="Upto 60 words"
                                    />
                                    <span
                                        style={{
                                            position: 'absolute',
                                            marginLeft: '4px',
                                            bottom: '0',
                                        }}
                                    >
                                        <Speaker
                                            text={shortDescription}
                                            language={
                                                languageOption === 1
                                                    ? 'en-US'
                                                    : languageOption === 2
                                                    ? 'hi-IN'
                                                    : languageOption === 4
                                                    ? 'ta-IN'
                                                    : 'hi-IN'
                                            }
                                        />
                                    </span>
                                </div>
                            ) : (
                                <Spellcheck
                                    generate={spellCheckLoader}
                                    setSpellDescription={setSpellDescription}
                                    textValue={shortDescription}
                                    setTextValue={setShortDescription}
                                    wordsToHighlight={
                                        AIData?.misspelledDescription
                                            ? AIData?.misspelledDescription
                                            : []
                                    }
                                    correctedWords={
                                        AIData?.correctedDescription
                                            ? AIData?.correctedDescription
                                            : []
                                    }
                                    type={'description'}
                                />
                            )}
                            <span className="description-subtext">
                                <span
                                    style={{
                                        marginLeft:"10px",
                                        color:
                                            remainingWordsCount === 0
                                                ? 'red'
                                                : '#555555',
                                    }}
                                >
                                    {' '}
                                    {remainingWordsCount} words
                                </span>|
                                <span
                                    style={{
                                        color:
                                            remainingCharCount === 0
                                                ? 'red'
                                                : '#555555',
                                    }}
                                >
                                    {remainingCharCount} characters
                                </span>
                                |<span   style={{
                                color: remainingCharCount === 0 ? 'red' : '#555555',
                            }}>max 9 line only</span>
                                {(remainingWordsCount === 0 ||
                                    remainingCharCount === 0) && (
                                    <div
                                        className="description-subtext"
                                        style={{
                                            color: 'red',
                                            marginRight: 'auto',
                                            fontStyle: 'italic',
                                        }}
                                    >
                                        {' '}
                                        - {'Description is too long'}
                                    </div>
                                )}
                            </span>
                        </Form.Item>
                        
                        <Form.Item label={'Similar content'}>
                            <div className="similar-content-wrapper">
                                <div className="get-similar-btn">
                                    {loading && (
                                        <div className="loading-spinner">
                                            <Spin size="small" />
                                        </div>
                                    )}
                                </div>

                                {similarContents?.data?.length === 0 &&
                                    !loading &&
                                    similarContents?.api && (
                                        <div className="no-content-message">
                                            No similar content found
                                        </div>
                                    )}
                                {similarContents?.data?.length > 0 &&
                                    !loading && (
                                        <div>
                                            {similarContents?.data?.map((e) => (
                                                <div
                                                    style={{
                                                        fontSize: '12px',
                                                        marginBottom: '.5rem',
                                                    }}
                                                >
                                                    <a
                                                        href={e?.dynamic_url}
                                                        target="blank"
                                                        style={{ fontSize: 12 }}
                                                    >
                                                        {e?.title}
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                            </div>
                        </Form.Item>
                    
                        {initialFormData?.created_type !== 'reporter' && (
                            <Form.Item label="Gender" name="gender">
                                <Radio.Group>
                                    <Radio value={'male'}> Male </Radio>
                                    <Radio value={'female'}> Female </Radio>
                                </Radio.Group>
                            </Form.Item>
                        )}
                        <Form.Item label="Breaking News" name="breakingNews">
                            <Radio.Group>
                                <Radio value={true}> Yes </Radio>
                                <Radio value={false}> No </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.breakingNews !==
                                currentValues.breakingNews
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('breakingNews') === true ? (
                                    <Form.Item
                                        name="relevancies"
                                        label="Relevancy"
                                    >
                                        <Radio.Group>
                                            {breakingNewsOptions?.map(
                                                (option, index) => (
                                                    <Radio
                                                        key={index}
                                                        value={option.value}
                                                    >
                                                        {option.label}
                                                    </Radio>
                                                )
                                            )}
                                        </Radio.Group>
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                        <Form.Item
                            noStyle
                            shouldUpdate={(prevValues, currentValues) =>
                                prevValues.breakingNews !==
                                currentValues.breakingNews
                            }
                        >
                            {({ getFieldValue }) =>
                                getFieldValue('breakingNews') === true ? (
                                    <Form.Item
                                        name="expiryHours"
                                        label="Expiry Hours"
                                        rules={[{ required: true }]}
                                    >
                                        <Select style={{ width: 200 }}>
                                            {expiryHoursOptions?.map(
                                                (option, index) => (
                                                    <Select.Option
                                                        key={index}
                                                        value={option}
                                                    >
                                                        {option}
                                                    </Select.Option>
                                                )
                                            )}
                                        </Select>
                                    </Form.Item>
                                ) : null
                            }
                        </Form.Item>
                        <Form.Item
                        label="Keywords"
                        name="keywords"
                        required={true}
                        >
                            <div
                                className="col-md"
                                style={{ background: 'none' }}
                                >
                                {keywords !== null &&
                                    keywords?.map((keyword, index) => (
                                        <Tag
                                            key={index}
                                            closable
                                            style={{
                                                userSelect: 'none',
                                                marginBottom: '5px',
                                            }}
                                            onClose={(e) => {
                                                e.preventDefault()
                                                handleClose(index)
                                            }}
                                            >
                                            <span>{keyword}</span>
                                        </Tag>
                                    ))}
                                <Input
                                    onChange={(e) =>
                                        setKeywordInput(e.target.value)
                                    }
                                    value={keywordInput}
                                    onKeyPress={(event) => {
                                        if (event.key === 'Enter') {
                                            event.preventDefault()
                                            addKeyword(event?.target?.value)
                                            setKeywordInput('')
                                        }
                                    }}
                                    placeholder="Type words and press enter after each word"
                                    />

                                {keywords?.length <= 0 && (
                                    <div>
                                        {generate ? (
                                            <Button
                                                style={{ margin: '.5rem 0' }}
                                                loading
                                                >
                                                Generating...
                                            </Button>
                                        ) : (
                                            <Button
                                            type="primary"
                                            ghost
                                            style={{ margin: '.5rem 0' }}
                                            onClick={() => {
                                                generateKeyWords(
                                                    title,
                                                    shortDescription
                                                )
                                            }}
                                            >
                                                Generate Keywords
                                            </Button>
                                        )}
                                    </div>
                                )}
                            </div>
                        </Form.Item>
                        <div style={{ display: 'flex' }}>
                            <div className="image-container">
                                {fileList.length === 0 && (
                                    <div className="image-loading">
                                        <LoadingOutlined />
                                    </div>
                                )}
                                {fileList?.length > 0 &&
                                    fileList?.map((ele, index) => {
                                        return (
                                            <div
                                                className="image-block"
                                                key={index}
                                                style={{ position: 'relative' }}
                                            >
                                                <div className="image-item">
                                                    <Radio
                                                        name="image1"
                                                        style={{ zIndex: '1' }}
                                                        onClick={(event) =>
                                                            onRadioChange(
                                                                event,
                                                                index
                                                            )
                                                        }
                                                        checked={
                                                            selectedImageRadio1 ===
                                                            index
                                                        }
                                                    ></Radio>

                                                    <img
                                                        style={{
                                                            width: '100%',
                                                            position:
                                                                'absolute',
                                                            objectFit: 'cover',
                                                        }}
                                                        src={ele}
                                                    />
                                                    {imageUrl && (
                                                        <Image
                                                            src={imageUrl}
                                                            alt="Uploaded Image"
                                                        />
                                                    )}
                                                    <div className="buttons-btn">
                                                        <div
                                                            className="download-btn"
                                                            onClick={() =>
                                                                downloadImage(
                                                                    ele
                                                                )
                                                            }
                                                        >
                                                            <DownloadOutlined />
                                                        </div>
                                                        <div className="edit-btn">
                                                            <ImageCropper
                                                                setSelectedImageUrl={
                                                                    setSelectedImageUrl
                                                                }
                                                                onRadioChange={
                                                                    onRadioChange
                                                                }
                                                                imageUrl={
                                                                    fileList[
                                                                        index
                                                                    ]
                                                                }
                                                                setFileList={
                                                                    setFileList
                                                                }
                                                                accessToken={
                                                                    accessToken
                                                                }
                                                                languageOption={
                                                                    languageOption
                                                                }
                                                                index={index}
                                                                fileList={
                                                                    fileList
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                            {videoList?.length > 0 && (
                                <div className="video-container">
                                    {videoList.map((ele, index) => {
                                        return (
                                            <div
                                                className="video-block"
                                                key={index}
                                            >
                                                <div className="video-file">
                                                    <Radio
                                                        value={false}
                                                        name="video1"
                                                        onClick={(event) => {
                                                            onRadioChangeVideo(
                                                                event,
                                                                index,
                                                                ele
                                                            )
                                                        }}
                                                        checked={
                                                            selectedVideoRadio ===
                                                            index
                                                        }
                                                    ></Radio>

                                                    <MuxPlayer
                                                        style={{
                                                            height: '100%',
                                                        }}
                                                        playbackId={
                                                            ele.match(
                                                                playbackIdRegex
                                                            )[1]
                                                        }
                                                        tokens={{
                                                            playback:
                                                                ele.match(
                                                                    tokenRegex
                                                                )[1],
                                                        }}
                                                        className="video-item"
                                                    />
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            )}
                        </div>

                        <Form.Item
                            style={{ marginTop: '10px' }}
                            label=" "
                            name="image_"
                        >
                            <div style={{ display: 'flex', gap: '5px' }}>
                                <ImageUploader getImagePath={getImagePath_} />
                                <div>
                                    {isImageUploading && <LoadingOutlined />}
                                </div>
                            </div>
                        </Form.Item>
                        {/* {selectedImageUrl.length!==0 && <ImageDownloader imageUrl={selectedImageUrl?.[0]} /> } */}

                        {fileListError ? (
                            <p className="error-card">Please Upload a Image</p>
                        ) : maxImageError ? (
                            <p className="error-card">Max files allowed is 5</p>
                        ) : (
                            imageError && (
                                <p className="error-card">
                                    Please select image
                                </p>
                            )
                        )}
                        {initialFormData?.created_type !== 'reporter' && (
                            <Form.Item label="Hashtag">
                                <AutoComplete
                                    options={hashTagOptions}
                                    value={hashTagValue}
                                    onSearch={(text) => {
                                        if (text) {
                                            setHashTagValue(text)
                                            setHashtagOptions(
                                                allHashTagOptions?.filter(
                                                    ({ value }) =>
                                                        value
                                                            ?.toLowerCase()
                                                            ?.includes(
                                                                text?.toLowerCase()
                                                            )
                                                )
                                            )
                                        } else {
                                            setHashtagOptions(allHashTagOptions)
                                        }
                                    }}
                                    onSelect={onHashTagSelect}
                                    placeholder="select hashtag"
                                />
                            </Form.Item>
                        )}
                        {initialFormData?.created_type !== 'reporter' && (
                            <Form.Item
                                rules={[{ required: true }]}
                                label="Publish"
                                name="publish"
                            >
                                <DatePicker
                                    showTime
                                    format="DD/MM/YYYY h:mm A"
                                    disabledDate={(currentDate) =>
                                        currentDate < new Date()
                                    }
                                />
                            </Form.Item>
                        )}
                        {initialFormData?.created_type === 'reporter' ? (
                            <></>
                        ) : (
                            <div>
                                <Form.Item
                                    label="Location Specific"
                                    name="locationSpecific"
                                >
                                    <Radio.Group>
                                        <Radio value="yes"> Yes </Radio>
                                        <Radio value="no"> No </Radio>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues?.locationSpecific !==
                                        currentValues?.locationSpecific
                                    }
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('locationSpecific') ===
                                        'yes' ? (
                                            <Form.Item
                                                name="state"
                                                label="State"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message:
                                                            'please select an option',
                                                    },
                                                ]}
                                            >
                                                {locationsData && (
                                                    <Select
                                                        onChange={onStateChange}
                                                        placeholder="Select State"
                                                    >
                                                        {locationsData?.map(
                                                            (option) => (
                                                                <Select.Option
                                                                    key={
                                                                        option.state_id
                                                                    }
                                                                    value={
                                                                        option.name
                                                                    }
                                                                >
                                                                    {
                                                                        option.name
                                                                    }
                                                                </Select.Option>
                                                            )
                                                        )}
                                                    </Select>
                                                )}
                                            </Form.Item>
                                        ) : (
                                            form.setFieldValue('state', [])
                                        )
                                    }
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.state !== currentValues.state
                                    }
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('state') &&
                                        getFieldValue('state').length !== 0 ? (
                                            <Form.Item
                                                name="district"
                                                label="District"
                                            >
                                                <Select
                                                    placeholder="Select District"
                                                    onChange={onDistrictChange}
                                                    mode="multiple"
                                                >
                                                    {districtsData?.map(
                                                        (option) => (
                                                            <Select.Option
                                                                key={
                                                                    option.name
                                                                }
                                                                value={
                                                                    option.name
                                                                }
                                                            >
                                                                {option.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        ) : (
                                            form.setFieldValue('district', [])
                                        )
                                    }
                                </Form.Item>
                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.state !== currentValues.state
                                    }
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('state') &&
                                        getFieldValue('state').length !== 0 ? (
                                            <Form.Item
                                                name="assemblyconstituencies"
                                                label="Assembly-Constituencies"
                                            >
                                                <Select
                                                    placeholder="Select Assembly-Constituencies"
                                                    onChange={
                                                        handleAssemblyConstituencies
                                                    }
                                                    onClick={() => setChange(1)}
                                                    mode="multiple"
                                                >
                                                    {assemblyConstituenciesData?.map(
                                                        (option) => (
                                                            <Select.Option
                                                                key={
                                                                    option?.assembly_constituency_id
                                                                }
                                                                value={
                                                                    option?.name
                                                                }
                                                            >
                                                                {option?.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        ) : (
                                            form.setFieldValue(
                                                'assemblyconstituencies',
                                                []
                                            )
                                        )
                                    }
                                </Form.Item>

                                <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, currentValues) =>
                                        prevValues.state !== currentValues.state
                                    }
                                >
                                    {({ getFieldValue }) =>
                                        getFieldValue('district') &&
                                        getFieldValue('district')?.length !==
                                            0 ? (
                                            <Form.Item
                                                name="mandal"
                                                label="Mandal"
                                            >
                                                <Select
                                                    mode="multiple"
                                                    onClick={() => setChange(5)}
                                                    placeholder="Select Mandal"
                                                    onChange={onMandalChange}
                                                >
                                                    {mandalsData?.map(
                                                        (option) => (
                                                            <Select.Option
                                                                key={
                                                                    option.name
                                                                }
                                                                value={
                                                                    option.name
                                                                }
                                                            >
                                                                {option.name}
                                                            </Select.Option>
                                                        )
                                                    )}
                                                </Select>
                                            </Form.Item>
                                        ) : (
                                            form.setFieldValue('district', [])
                                        )
                                    }
                                </Form.Item>
                            </div>
                        )}
                    </Col>
                    <Col
                        span={10}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                        }}
                    >
                        <Form.Item
                            wrapperCol={{ span: 48 }}
                            style={{ marginBottom: '1.5rem' }}
                        >
                            <Button
                                style={{
                                    marginRight: '1.8rem',
                                    backgroundColor: 'gray',
                                }}
                                className="bg-color width-150"
                                type="primary"
                                onClick={() => setIsModalOpen(true)}
                                disabled={isDisabled}
                            >
                                Reject
                            </Button>
                            <Button
                                className="bg-color width-150"
                                type="primary"
                                style={{ backgroundColor: '#1677ff' }}
                                onClick={() => handleSave('approved')}
                                disabled={
                                    isDisabled ||
                                    generate ||
                                    fileList.length === 0
                                }
                            >
                                Approve
                            </Button>
                        </Form.Item>
                        <div>
                            <SmartphonePreview
                                title={title}
                                description={shortDescription}
                                image={selectedImageUrl[0]}
                                videoPath={selectedVideoUrl[0]}
                                videoList={videoList}
                            />
                        </div>
                    </Col>
                </Row>
            </Form>
            <RejectModal
                setReason={setReason}
                reason={reason}
                setShowCustomReasonInput={setShowCustomReasonInput}
                setRejectionReason={setRejectionReason}
                handleSave={handleSave}
                handleRejectionChanges={handleRejectionChanges}
                rejectionReasonData={rejectionReasonData}
                setRejectionReasonError={setRejectionReasonError}
                showCustomReasonInput={showCustomReasonInput}
                accessToken={accessToken}
                setIsDisabled={setIsDisabled}
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            />
            {isImageSelected && (
                <CropperModel
                    file={selectedFile[0]}
                    onConfirm={(croppedFile) => {
                        getImagePath(croppedFile)
                    }}
                    onDiscard={() => {
                        setSelectedFile('')
                    }}
                    onCompleted={() => {
                        setSelectedFile('')
                    }}
                    setIsImageSelected={setIsImageSelected}
                />
            )}
        </div>
    )
}

export default ReviewContentForm
