import {
    FontSizeOutlined,
    LoadingOutlined,
    PlusOutlined,
} from '@ant-design/icons'
import {
    AutoComplete,
    Button,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Radio,
    Select,
    Tag,
} from 'antd'
import Checkbox from 'antd/es/checkbox/Checkbox'
import dayjs from 'dayjs'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import {
    createShortNews,
    getHashtagTableData,
    getImageUrl,
    getSourcesTableData,
    getVideoUrl,
    updateShortNews,
    getSubCategories,
} from '../../../AadhanApi'
import ImageUploader from '../../image-uploader-crop/ImageUploader'
import { breakingNewsOptions, expiryHoursOptions } from './AddShortNewsConstant'
import './AddShortNewsForm.scss'
import {
    isPublish,
    isSubmitAndDraft,
    TableTypeFromRoutesContent,
} from '../../App.constants'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import AddSource from '../../Source/AddSource'
import Speaker from '../../Speaker'
import ImageDownloader from '../../ImageDownloader'
import Spellcheck from '../../Spellcheck'
import generateKeyWord from '../../AIResponseFilter/KeywordsResponse'
import { checkContentForDay } from '../../CheckContentForDay/checkContentForDay'

const AddShortNewsForm = ({
    titleChange,
    descriptionChange,
    setImagePath,
    initialFormData,
    languageData,
    categories,
    accessToken,
    mode,
    contentId,
    setVideoPath,
    hashTagId,
    setHashTagId,
    sourceId,
    setSourceId,
    code,
    contentStatus,
    setOtherLink,
    languageOption,
    setLanguageOption,
    pro,
    anti,
}) => {
    const [form] = Form.useForm()
    const [showSourceModal, setShowSourceModal] = useState()
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const employeeId = useSelector((state) => state.app.employeeId)
    const roleId = useSelector((state) => state.app.role_id)
    const employeeName = useSelector((state) => state.app.employeeName)
    const [shortDescription, setShortDescription] = useState(
        initialFormData?.shortDescription
    )
    const [remainingWordsCount, setRemaingWordsCount] = useState(59)
    const [remainingCharCount, setRemaingCharCount] = useState(400)

    const [hashTagOptions, setHashtagOptions] = useState([])
    const [allHashTagOptions, setAllHashtagOptions] = useState([])
    const [sourcesOptions, setSourcesOptions] = useState([])
    const [allSourcesOptions, setAllSourcesOptions] = useState([])
    const [keywords, setKeywords] = useState([])
    const [keywordInput, setKeywordInput] = useState('')
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [isVideoUploading, setIsVideoUploading] = useState(false)
    const [imagePathUrl, setImagePathUrl] = useState(initialFormData?.image_url)
    const [videoPathUrl, setVideoPathUrl] = useState(
        initialFormData?.streamVideoPath
    )
    const [entities,setEntities] = useState("");
    const [iab,setIab] = useState('')
    const [hashData, setHashData] = useState([])
    const [sourceData, setSourceData] = useState([])
    const [hashTagValue, setHashTagValue] = useState('')
    const [sourceValue, setSourceValue] = useState('')
    const navigate = useNavigate()
    const inputVideoRef = useRef(null)
    const [titleError, setTitleError] = useState()
    const [title, setTitle] = useState('')
    const [englishTitle, setEnglishTitle] = useState('')
    const [englishTitleError, setEnglishTitleError] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [sourceError, setSourceError] = useState(false)
    const location = useLocation()
    const currentTableType =
        TableTypeFromRoutesContent[location?.pathname] ?? 'shortnews'
    const locationsData = useSelector((state) => state.app.locations)
    const [districtsData, setDistrictsData] = useState([])
    const [mandalsData, setMandalsData] = useState([])

    const [assemblyConstituenciesData, setAssemblyConstituenciesData] =
        useState([])

    const [selectedStateId, setSelectedStateId] = useState(
        initialFormData?.state_id
    )
    const [selectedDistrictId, setSelectedDistrictId] = useState(
        initialFormData?.district_id
    )
    const [selectedMandalId, setSelectedMandalId] = useState(
        initialFormData.mandal_id
    )
    const [selectAssemblyId, setSelectedAssemblyId] = useState(
        initialFormData.constituency_ids
    )

    const [subCategory, setSubCategory] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(
        initialFormData?.category_id
    )
    const [keywords_g, setKeywords_g] = useState(false)
    const allDistricts = useSelector((state) => state.app.districts)
    const allConstituencies = useSelector((state) => state.app.constituencies)
    const allMandals = useSelector((state) => state.app.mandals)
    const [generate, setGenerate] = useState(false)
    const [AIData, setAiData] = useState({})

    useEffect(() => {
        handleCategory(initialFormData?.category)
    }, [locationsData])
    async function generateKeyWords(title, description) {
        if (title !== '' || description !== '') {
            setGenerate(true)
            const data = await generateKeyWord(
                title,
                description,
                languageOption
            )
            const newKeywords = data?.keywords?.split(',')
            const uniqueArray = [
                ...new Set([
                    ...newKeywords?.map((keyword) => keyword?.trim()), // Trim each keyword
                ]),
            ]
            const cleanKeywords = uniqueArray.filter(
                (keyword) => keyword !== '' && !keyword?.includes('\n')
            )
            setGenerate(false)
            if (cleanKeywords.length === 0) {
                setKeywords([])
                setKeywords_g(true)
                return {data:[],entity:'',iab_:""}
            } else {
                setEntities(data?.entities)
                setKeywords_g(false)
                setKeywords(cleanKeywords)
                setIab(data?.iab)
                return {data:cleanKeywords,entity:data?.entities,iab_:data?.iab}
            }
        }
    }

    useEffect(() => {
        // handleLanguageChange(initialFormData.language)

        form.setFieldsValue(initialFormData)
        changeDescriptionValue(initialFormData?.shortDescription ?? '')
        setImagePathUrl(form.getFieldValue('image'))
        setVideoPathUrl(initialFormData?.streamVideoPath)

        handleTitleChange(initialFormData?.title)
        handleEnglishTitleChange(initialFormData?.englishTitle)
        handleLanguageChange(
            initialFormData?.language !== '' ? initialFormData?.language : 1
        )
        if (
            initialFormData?.keywords !== '' &&
            initialFormData?.keywords?.split(',')?.length > 0
        ) {
            // setKeywords(initialFormData?.keywords?.split(','))
        }
        if (initialFormData?.state) {
            let filteredState = locationsData
                ?.map((ele) => {
                    if (initialFormData?.state?.includes(ele.state_id)) {
                        return ele
                    }
                })
                .filter((Undefined) => Undefined !== undefined)

            let filteredDistrict = []
            filteredState?.forEach((ele) => {
                filteredDistrict.push(ele?.districts)
            })
            let splitDistricts = []
            filteredDistrict.forEach((ele, i) => {
                splitDistricts = [...splitDistricts, ...ele]
            })

            setDistrictsData(splitDistricts)
            if (initialFormData?.district) {
                let filteredMandals = []
                splitDistricts?.forEach((ele) => {
                    filteredMandals.push(ele.mandals)
                })

                let splitMandals = []
                filteredMandals.forEach((ele, i) => {
                    splitMandals = [...splitMandals, ...ele]
                })
                setMandalsData(splitMandals)
            }
        }
        if (
            initialFormData.category &&
            initialFormData.category !== undefined
        ) {
            handleCategory(initialFormData?.category)
        }
        setKeywords(initialFormData?.keywords!=="" && initialFormData?.keywords!==undefined ? initialFormData?.keywords.split(',') : []);
    }, [form, initialFormData, locationsData])

    // useEffect(() => {
    //     if (initialFormData?.language || languageData?.[0]?.language_id) {
    //         updateHashtagSource(
    //             initialFormData?.language ?? languageData?.[0]?.language_id
    //         )
    //     }

    // }, [form.language])

    useEffect(() => {
        const tempDistrict = []
        initialFormData?.state_id?.forEach((e) =>
            tempDistrict.push(...(allDistricts?.[e] || []))
        )
        setDistrictsData(tempDistrict)
        const tempMandals = []
        initialFormData?.district_id?.forEach((e) =>
            tempMandals.push(...(allMandals?.[e] || []))
        )
        setMandalsData(tempMandals)

        const tempAssembly = []
        initialFormData?.state_id?.forEach((e) =>
            tempAssembly.push(...(allConstituencies?.[e] || []))
        )
        setAssemblyConstituenciesData(tempAssembly)
    }, [initialFormData])
    const onStateChange = (id) => {
        let temp = []
        form.setFieldValue(
            'state',
            id?.length <= 0 ? [] : [id?.[id.length - 1]]
        )
        id = id?.length <= 0 ? [] : [id?.[id.length - 1]]
        locationsData.filter((option) => {
            if (id.includes(option.name)) {
                temp.push(option.state_id)
            }
        })
        if (temp.length !== 0) {
            const tempDistrict = []
            temp.map((e) => tempDistrict.push(...allDistricts?.[e]))
            const field = tempDistrict.filter((e) =>
                form.getFieldValue('districts')?.includes(e.name)
            )
            const fieldValue = field.map((e) => e.name)
            const id = field.map((e) => e.district_id)
            setSelectedDistrictId(id)
            form.setFieldValue('districts', fieldValue)
            setDistrictsData(tempDistrict)
            onDistrictChange(fieldValue)
        } else {
            setDistrictsData([])
        }
        setSelectedStateId(temp)
        if (temp.length !== 0) {
            const tempConstituency = []
            temp.map((e) => tempConstituency.push(...allConstituencies?.[e]))
            const field = tempConstituency.filter((e) =>
                form.getFieldValue('assemblyconstituencies')?.includes(e.name)
            )
            const fieldValue = field.map((e) => e.name)
            const id = field.map((e) => e.assembly_constituency_id)
            setSelectedAssemblyId(id)
            form.setFieldValue('assemblyconstituencies', fieldValue)
            setAssemblyConstituenciesData(tempConstituency)
        } else {
            setAssemblyConstituenciesData([])
        }
    }

    const onDistrictChange = (id) => {
        form.setFieldValue(
            'districts',
            id.length <= 0 ? [] : [id?.[id.length - 1]]
        )
        id = id.length <= 0 ? [] : [id?.[id.length - 1]]
        let temp = []
        districtsData.filter((option) => {
            if (
                id?.includes(option.name) &&
                !temp?.includes(option.district_id)
            ) {
                temp.push(option.district_id)
            }
        })
        if (temp.length !== 0) {
            const tempMandals = []
            temp.map((e) => tempMandals.push(...allMandals?.[e]))
            const field = tempMandals.filter((e) =>
                form.getFieldValue('mandal')?.includes(e.name)
            )
            const fieldValue = field.map((e) => e.name)
            const id = field.map((e) => e.mandal_id)
            setSelectedMandalId(id)
            form.setFieldValue('mandal', fieldValue)
            setMandalsData(tempMandals)
        } else if (id.length === 0) {
            setMandalsData([])
        }

        setSelectedDistrictId(temp)
    }

    const onMandalChange = (id) => {
        let temp = []
        form.setFieldValue(
            'mandal',
            id?.length <= 0 ? [] : [id?.[id.length - 1]]
        )
        id = id?.length <= 0 ? [] : [id?.[id.length - 1]]
        mandalsData.filter((option) => {
            if (id.includes(option.name) && !temp.includes(option.mandal_id)) {
                temp.push(option.mandal_id)
            }
        })
        setSelectedMandalId(temp)
    }
    useEffect(() => {
        setSelectedStateId(initialFormData?.state_id)
        setSelectedDistrictId(initialFormData?.district_id)
        setSelectedMandalId(initialFormData?.mandal_id)
        setSelectedAssemblyId(initialFormData?.constituency_ids)
    }, [initialFormData, locationsData])

    const createRequest = (values, type) => {
        const language = languageData.filter(
            (e) => e.language_id === languageOption
        )[0].language_name
        let categoryName = categories
            ?.map((ele) => {
                if (
                    ele.category_id === values.category &&
                    ele.language_name === language
                ) {
                    return ele.category_name
                }
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]

        if (selectedStateId?.length === 0 && !selectedStateId) {
            setSelectedStateId(initialFormData.state_id)
        }
        if (selectedDistrictId?.length === 0 && !selectedDistrictId) {
            setSelectedDistrictId(initialFormData.district_id)
        }
        if (selectedMandalId?.length === 0 && !selectedMandalId) {
            setSelectedMandalId(initialFormData.mandal_id)
        }
        if (selectAssemblyId?.length === 0 && !selectAssemblyId) {
            setSelectedAssemblyId(initialFormData.constituency_ids)
        }
        
        const checkforday = checkContentForDay(title,languageOption);
        const resposeObject = {
            title: values?.title
                ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                .replace(
                    /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                    '$1 '
                ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                .replace(/(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/, '$1') // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                .trim(), // Trim leading and trailing spaces
            source_id:
                values?.source !== '' || values?.source === ' '
                    ? sourceId
                    : undefined,
            source_url: values?.sourceLink ?? '',
            category_id: values?.category,
            expiry_hours:
                values?.expiryHours == 'Never' ? 0 : values?.expiryHours,
            content_type: 'shortnews',
            // expiry_date: dayjs(values?.publish)
            //     .add(values?.expiryHours, 'hour')
            //     ?.format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
            published_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),

            relevancy: values?.relevancy,
            dynamic_url: '',
            is_active: true,
            content_status: type,
            language_id: values?.language,
            description:
                !values?.shortDescription.includes('*') &&
                !values?.shortDescription.includes('**')
                    ? values?.shortDescription
                          ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                          .replace(
                              /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                              '$1 '
                          ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                          .replace(
                              /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/,
                              '$1'
                          ) // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                          .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                          .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                          .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                          .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                          .trim() // Trim leading and trailing spaces
                    : values?.shortDescription,
            image_url: imagePathUrl,
            code,
            yt_video_url:
                values.media === 'youtubeLink' ? values?.youtubeLink : '',
            breaking_news: values?.breakingNews,
            state:
                values.locationSpecific === 'no'
                    ? ''
                    : selectedStateId?.join(','),

            constituency_ids:
                values.locationSpecific === 'no' ? [''] : selectAssemblyId,
            constituency_names:
                values.locationSpecific === 'no'
                    ? ['']
                    : values?.assemblyconstituencies,
            district:
                values.locationSpecific === 'no'
                    ? ''
                    : selectedDistrictId?.join(','),
            mandal:
                values.locationSpecific === 'no'
                    ? ''
                    : selectedMandalId?.join(','),
            district_name:
                values.locationSpecific === 'no' ? [] : values.districts,
            mandal_name: values.locationSpecific === 'no' ? [] : values.mandal,
            state_name: values.locationSpecific === 'no' ? [] : values.state,
            gender: values?.gender,
            city: '',
            stream_video_url: values.media === 'video' ? videoPathUrl : '',
            mp4_video_url:
                values.media === 'otherLink' ? values?.otherLink : '',
            image_url_16_9: '',
            image_url_3_4: '',
            thumbnailimage_url: '',
            hashtag_id: hashTagId,
            keywords: '',
            entities:'',
            iab_category:'',
            disturbing_visuals: values?.disturbingVisuals,
            left_color: '',
            right_color: '',
            upvote: 0,
            linked_content_id: '',
            uploaded_by_name: initialFormData?.uploadedByName || employeeName,
            proof_read_by_name: employeeName,
            category_name: categoryName,
        }
        if(checkforday?.today || checkforday?.tomorrow){
            resposeObject['expiry_hours'] = checkforday?.expiry
        }
        if (values?.is_celebration) {
            resposeObject['is_celebration'] = values?.is_celebration
        }
        if (!subCategory.find((item) => item?.pro === true)) {
            resposeObject['sub_category_id'] = null

            resposeObject['sub_category_name'] = null
        } else {
            if (!subCategory.find((item) => item?.pro === true)) {
                resposeObject['sub_category_id'] = null

                resposeObject['sub_category_name'] = null
            } else {
                const selectedSubcategory = subCategory.find(
                    (item) => item?.pro === true
                )
                resposeObject['sub_category_id'] = selectedSubcategory
                    ? selectedSubcategory.sub_category_id
                    : ''

                resposeObject['sub_category_name'] =
                    selectedSubcategory.sub_category_name
                        ? selectedSubcategory?.sub_category_name
                        : ''
            }
        }
        if (!subCategory.find((item) => item?.anti === true)) {
            resposeObject['anti_sub_category_id'] = null
            resposeObject['anti_sub_category_name'] = null
            return resposeObject
        }
        else {
            if (!subCategory.find((item) => item?.anti === true)) {
                resposeObject['anti_sub_category_id'] = null
                resposeObject['anti_sub_category_name'] = null
                return resposeObject
            } else {
                const selectedSubcategory = subCategory.find(
                    (item) => item?.anti === true
                )
                resposeObject['anti_sub_category_id'] = selectedSubcategory
                ? selectedSubcategory.sub_category_id
                : ''
                
                resposeObject['anti_sub_category_name'] =
                selectedSubcategory.sub_category_name
                ? selectedSubcategory?.sub_category_name
                : ''
                return resposeObject
            }
        }
    }
    const handleSave = async (type) => {
       
        setIsLoading(true)
        try {
            const validation = await form.validateFields([
                'title',
                'language',
                'category',
                'expiryHours',
                'state',
                'shortDescription',
                'image',
                'relevancy',
            ])

            if (
                validation?.errorFields?.length > 0 ||
                (form.getFieldsValue()?.sourceLink?.length && !sourceId)
            ) {
                setIsLoading(false)
                setSourceError(true)
                return
            }
        } catch (e) {
            setIsLoading(false)
            return
        }

        const request = await createRequest({ ...form.getFieldsValue() }, type)

        if (mode === 'edit') {
            request.updated_by = employeeId
            request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            request.uploaded_by = initialFormData?.uploadedBy
            request.uploaded_date = initialFormData?.uploadedDate

            const shortNewsReponse = await updateShortNews(
                request,
                contentId,
                accessToken
            )
            if (shortNewsReponse?.status === 200) {
                setIsLoading(false)
                navigate('/home/shortNews')
            }
        } else if (mode === 'add') {
            request.uploaded_by = employeeId
            request.updated_by = 0
            request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            const shortNewsReponse = await createShortNews(request, accessToken)
            if (shortNewsReponse?.status === 200) {
                setIsLoading(false)
                navigate('/home/shortNews')
            }
        }
    }

    function handleAssemblyConstituencies(id) {
        let temp = []
        form.setFieldValue(
            'assemblyconstituencies',
            id?.length <= 0 ? [] : [id?.[id.length - 1]]
        )
        id = id?.length <= 0 ? [] : [id?.[id.length - 1]]
        assemblyConstituenciesData.filter((option) => {
            if (
                id.includes(option.name) &&
                !temp.includes(option.assembly_constituency_id)
            ) {
                temp.push(option.assembly_constituency_id)
            }
        })

        setSelectedAssemblyId(temp)
    }

    useEffect(() => {
        if (hashTagId) {
            setHashTagValue(
                hashData?.filter((h) => h.hashtag_id === hashTagId)?.[0]
                    ?.hashtag_name ?? ''
            )
        }
    }, [hashData])

    useEffect(() => {
        if (sourceId) {
            setSourceValue(
                sourceData?.filter((h) => h.source_id === sourceId)?.[0]
                    ?.source_name ?? ''
            )
        }
    }, [sourceData])
    const onFinish = async (values,publish) => {
        setIsLoading(true)
        const request = await createRequest(values)
        if (true) {
            request.proof_read_by = employeeId
            request.proof_read_date = dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            )
            request.is_proof_read = true
        }

        if (mode === 'edit') {
            request.content_status = 'approved'
            request.updated_by = employeeId
            request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            request.uploaded_by = initialFormData?.uploadedBy
            request.uploaded_date = initialFormData?.uploadedDate
            if (publish && keywords.length === 0 &&  (!initialFormData?.keywords || !initialFormData?.entities || !initialFormData?.iab_category)) {
                const {data,entity,iab_} = await generateKeyWords(title, shortDescription)
                if (data.length === 0) {
                    setIsLoading(false)
                    message.error('Keyword generation failed. Please try regenerating or enter the keywords manually.');
                    return
                }
                request.keywords = data.toString();
                request.entites=entity;
                request.iab_category=iab_;
            } else {
                request.entites=entities;
                request.keywords =  request.keywords = initialFormData?.keywords==="" || initialFormData?.keywords===undefined ? keywords.toString() : initialFormData?.keywords;
                request.iab_category=iab;
            }
            const shortNewsReponse = await updateShortNews(
                request,
                contentId,
                accessToken
            )
            if (shortNewsReponse?.data === 'Short news updated successfully') {
                setIsLoading(false)
                navigate('/home/shortNews')
            }
            setTimeout(() => console.log('Initial timeout!'), 1000)
        } else if (mode === 'add') {
            if (publish && keywords.length === 0 ) {
                const {data,entity,iab_} = await generateKeyWords(title, shortDescription)
                if (data.length === 0) {
                    setIsLoading(false)
                    message.error('Keyword generation failed. Please try regenerating or enter the keywords manually.');
                    return
                }
                request.keywords = data.toString();
                request.entites=entity;
                request.iab_category=iab_;
            }
            else {
                request.entites=entities;
                request.keywords =  request.keywords = initialFormData?.keywords==="" || initialFormData?.keywords===undefined ? keywords.toString() : initialFormData?.keywords;
                request.iab_category=iab;
            } 
            request.content_status = 'approved'
            request.uploaded_by = employeeId
            request.updated_by = 0
            request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')

            const shortNewsReponse = await createShortNews(request, accessToken)
            if (shortNewsReponse?.status === 200) {
                setIsLoading(false)
                navigate('/home/shortNews')
            }
        }
    }
    const updateHashtagSource = async (language_id) => {
        if (language_id) {
            const hashtagDataResponse = await getHashtagTableData(
                language_id,
                '',
                accessToken
            )
            const sourceDataResponse = await getSourcesTableData(
                language_id,
                accessToken
            )

            setHashData(hashtagDataResponse?.data)
            setSourceData(sourceDataResponse?.data)
            setHashtagOptions(
                hashtagDataResponse?.data?.map((option) => ({
                    value: option.hashtag_name,
                })) ?? []
            )
            setAllHashtagOptions(
                hashtagDataResponse?.data?.map((option) => ({
                    value: option.hashtag_name,
                })) ?? []
            )
            setSourcesOptions(
                sourceDataResponse?.data?.map((option) => ({
                    value: option.source_name,
                })) ?? []
            )
            setAllSourcesOptions(
                sourceDataResponse?.data?.map((option) => ({
                    value: option.source_name,
                })) ?? []
            )
        }
    }

    const handleLanguageChange = (id) => {
        updateHashtagSource(id)
        setLanguageOption(id)
        // form.setFieldValue('category', undefined)
        // form.setFieldValue('subcategory', undefined)

        setSubCategory([])
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const onHashTagSelect = (val) => {
        setHashTagValue(val)
        setHashTagId(
            hashData?.filter((h) => h.hashtag_name === val)?.[0]?.hashtag_id
        )
    }

    const onSelectSource = (val) => {
        setSourceValue(val)

        setSourceId(
            sourceData?.filter((h) => h.source_name === val)?.[0]?.source_id
        )
    }
    const getRemainingWords = (value, type, max) => {
        if (type === 'words') {
            const remaingWords = max - value?.split(' ')?.length
            return remaingWords
        } else {
            const remainingChar = max - value.length
            return remainingChar
        }
    }

    const changeDescriptionValue = (descriptionValue) => {
        let cleanedValue = descriptionValue

        if (cleanedValue?.length && languageOption === 2) {
            // document.getElementById("desc-checker").innerHTML = cleanedValue
        }
        if (cleanedValue?.split('\n')?.length <= 9) {
            form.setFieldValue('shortDescription', cleanedValue)
            descriptionChange(cleanedValue)
            const remainingWords = getRemainingWords(cleanedValue, 'words', 60)
            const remainingChars = getRemainingWords(cleanedValue, 'char', 400)
            if (remainingWords > -1 && remainingChars > -1) {
                form.setFieldValue('shortDescription', cleanedValue)
                setShortDescription(cleanedValue)
                setRemaingCharCount(remainingChars)
                setRemaingWordsCount(remainingWords)
            } else if (cleanedValue === '') {
                setRemaingCharCount(400)
                setRemaingWordsCount(59)
            } else {
                if (remainingWords < 0) {
                    const newDescription = cleanedValue
                        .split(' ')
                        .slice(0, remainingWords)
                        .join(' ')
                    changeDescriptionValue(newDescription)
                } else if (remainingChars < 0) {
                    const newDescription = cleanedValue
                        .split('')
                        .slice(0, remainingChars)
                        .join('')
                    changeDescriptionValue(newDescription)
                }
            }
        }
    }

    const handleClose = (index) => {
        const key = [...keywords]
        key.splice(index, 1)
        setKeywords(key)
    }

    const addKeyword = (keyword) => {
        setKeywords([...keywords, keyword])
        setKeywords_g(true)
    }

    const getImagePath = async (path) => {
        setIsImageUploading(true)
        setIsLoading(true)
        const pathUriArr = await getImageUrl(
            path,
            'shortnews',
            accessToken,
            languageOption 
        )
        if (pathUriArr?.data) {
            setImagePath(
                
                    pathUriArr?.data
            )
            setImagePathUrl(
                
                    pathUriArr?.data
            )
            form.setFieldValue(
                'image',
                pathUriArr?.data
            )
            setIsImageUploading(false)
            setIsLoading(false)
        }
    }

    const getVideoPath = async (path) => {
        if (path && path?.size / (1024 * 1024) <= 50) {
            setIsLoading(true)
            setIsVideoUploading(true)
            const pathUri = await getVideoUrl(path, accessToken)
            if (pathUri?.data) {
                setVideoPath(pathUri?.data)
                setVideoPathUrl(pathUri?.data)
                setIsVideoUploading(false)
                setIsLoading(false)
            }
        } else {
            alert('Video Size should be less than or Equal to 50Mb')
            inputVideoRef.current.value = null
        }
    }

    const handleTitleChange = (value) => {
        if (value) {
            let cleanedValue = value
            setTitle(cleanedValue)
            titleChange(cleanedValue)
            form.setFieldValue('title', cleanedValue)
            setTitleError(cleanedValue.length > 75)
        } else {
            setTitle('')
            form.setFieldValue('title', '')
            titleChange('')
            setTitleError(false)
        }
    }

    const handleEnglishTitleChange = (value) => {
        form.setFieldValue('englishTitle', value)
        setEnglishTitle(value)
        setEnglishTitleError(value?.length > 75)
    }

    const updateSources = () => {
        updateHashtagSource(languageOption)
    }

    const sourceModal = (
        <Modal
            open={showSourceModal}
            title={'Add Source'}
            onCancel={() => setShowSourceModal(false)}
            footer={[
                <Button
                    form="sourceForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Submit
                </Button>,
            ]}
        >
            <AddSource
                getSourceTable={updateSources}
                accessToken={accessToken}
                selectedLanguage={languageOption}
                setShowModal={setShowSourceModal}
            />
        </Modal>
    )
    const handlePublish = () => {
        if (form.getFieldsValue()?.sourceLink?.length && !sourceId) {
            setSourceError(true)
            return
        }
        setSourceError(false)
        form.validateFields()?.then(
            (res) => {
                onFinish(res,true)
            },
            (error) => {
                console.log(error)
            }
        )
    }

    async function handleCategory(e) {
        setSelectedCategory(e)
    }
    async function subCategoryfunc(e, first) {
        // setSelectedCategory(null)

        if (e !== null && e !== undefined) {
            await getSubCategories(accessToken, languageOption, e).then(
                (resp) => {
                    let updatedSubCategories = resp?.data?.data
                    if(e===137 && initialFormData.state_id!==undefined && initialFormData.state_id.length!==0 && initialFormData.state_id[0]!==undefined
                         && initialFormData.state_id[0]!==''){
                       updatedSubCategories = updatedSubCategories?.filter((e)=>e?.state_id===initialFormData?.state_id?.[0]) 
                    }
                    const data = updatedSubCategories?.map(
                        (subCat) => ({
                            ...subCat,
                            anti: false,
                            pro: false,
                        })
                    )
                    setSubCategory(data)
                }
            )
        }
        if (first) {
            updateProStatus(pro)
            updateAntiStatus(anti)
        }
    }
    const updateProStatus = (id) => {
        setSubCategory((prevSubCategory) =>
            prevSubCategory.map((subCat) =>
                subCat.sub_category_id === id
                    ? { ...subCat, pro: true, anti: false }
                    : { ...subCat, pro: false }
            )
        )
    }

    const updateAntiStatus = (id) => {
        setSubCategory((prevSubCategory) =>
            prevSubCategory.map((subCat) =>
                subCat.sub_category_id === id
                    ? { ...subCat, anti: true, pro: false }
                    : { ...subCat, anti: false }
            )
        )
    }

    useEffect(() => {
        subCategoryfunc(selectedCategory, true)
    }, [languageOption, selectedCategory])
    const [spellTitle, setSpellTitle] = useState(false)
    const [spellDescription, setSpellDescription] = useState(false)
    return (
        <div id="form" style={{ width: '100%' }}>
            {sourceModal}
            <Form
                form={form}
                name="basic"
                colon={false}
                scrollToFirstError
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select language' },
                    ]}
                >
                    <Select onChange={handleLanguageChange}>
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Category"
                    name="category"
                    rules={[{ required: true, message: 'Please enter value' }]}
                >
                    <Select
                        placeholder="--Select Category--"
                        onChange={(e) => handleCategory(e)}
                    >
                        {categories
                            ?.filter(
                                (ct) =>
                                    ct?.language_name ===
                                        languageData?.filter(
                                            (l) =>
                                                l.language_id === languageOption
                                        )?.[0]?.language_name &&
                                    ct?.type_content?.includes(currentTableType)
                            )
                            ?.map((option) => {
                                return (
                                    <Select.Option
                                        key={option?.category_name}
                                        value={option?.category_id}
                                    >
                                        {option?.category_name}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Form.Item>
                {selectedCategory &&
                    roleId !== 5 &&
                    roleId !== 3 &&
                    subCategory?.length !== 0 && (
                        <Form.Item name="subcategory">
                            {subCategory?.map((subcat) => {
                                return (
                                    <div
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            alignItems: 'center',
                                            margin: '1% 0 1% 30%',
                                        }}
                                        key={subcat.sub_category_id}
                                    >
                                        <span
                                            style={{
                                                marginRight: '1rem',
                                                maxWidth: '10rem',
                                            }}
                                        >
                                            {subcat.sub_category_name}
                                        </span>
                                        <div
                                            style={{
                                                diplay: 'flex',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Radio
                                                onChange={(e) =>
                                                    updateProStatus(
                                                        e.target.value
                                                    )
                                                }
                                                checked={subcat.pro}
                                                type="radio"
                                                name="pro"
                                                value={subcat.sub_category_id}
                                            />
                                            <label
                                                style={{
                                                    margin: '0 .5rem 0 0',
                                                    color: 'gray',
                                                }}
                                            >
                                                pro
                                            </label>

                                            <Radio
                                                onChange={(e) =>
                                                    updateAntiStatus(
                                                        e.target.value
                                                    )
                                                }
                                                checked={subcat.anti}
                                                type="radio"
                                                name="anti"
                                                value={subcat.sub_category_id}
                                            />
                                            <label
                                                style={{
                                                    margin: '0 0 0 0',
                                                    color: 'gray',
                                                }}
                                            >
                                                anti
                                            </label>
                                        </div>
                                    </div>
                                )
                            })}
                        </Form.Item>
                    )}

                <Form.Item label="Gender" name="gender">
                    <Radio.Group>
                        <Radio value={'male'}> Male </Radio>
                        <Radio value={'female'}> Female </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Is Mass Event" name="is_celebration">
                    <Radio.Group>
                        <Radio value={true}> Yes </Radio>
                        <Radio value={false}> No </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Breaking News" name="breakingNews">
                    <Radio.Group>
                        <Radio value={true}> Yes </Radio>
                        <Radio value={false}> No </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.breakingNews !== currentValues.breakingNews
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('breakingNews') === true ? (
                            <Form.Item
                                name="relevancy"
                                label="Relevancy"
                                // className="custom-form-item"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please select relevancy',
                                    },
                                ]}
                            >
                                <Radio.Group>
                                    {breakingNewsOptions?.map(
                                        (option, index) => (
                                            <Radio
                                                value={option.value}
                                                key={index}
                                            >
                                                {option.label}
                                            </Radio>
                                        )
                                    )}
                                </Radio.Group>
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            whitespace: true,
                            message:
                                'Value is required and should be less than 75 characters',
                        },
                    ]}
                >
                    {!spellTitle ? (
                        <Input.TextArea
                            className={titleError ? 'error' : ''}
                            maxLength={75}
                            value={title}
                            onChange={(e) => {
                                // titleChange(e.target.value)
                                handleTitleChange(e.target.value)
                            }}
                            placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                        />
                    ) : (
                        <Spellcheck
                            generate={generate}
                            setSpellDescription={setSpellTitle}
                            textValue={title}
                            setTextValue={setTitle}
                            wordsToHighlight={
                                AIData?.misspelledTitleWords
                                    ? AIData?.misspelledTitleWords
                                    : []
                            }
                            correctedWords={
                                AIData?.correctedTitleWords
                                    ? AIData?.correctedTitleWords
                                    : []
                            }
                        />
                    )}
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        className="description-subtext"
                    >
                        <span
                            style={{
                                color: title?.length > 75 ? 'red' : '#555555',
                                fontWeight: '500',
                                fontSize: '14px',
                                marginLeft: '10px',
                            }}
                        >
                            {75 - (title?.length ?? 0)} characters
                            {title?.length >= 75 && (
                                <span
                                    style={{
                                        color: 'red',
                                        fontStyle: 'italic',
                                    }}
                                >
                                    {' '}
                                    - Title is too long
                                </span>
                            )}
                        </span>

                        <span
                            style={{
                                display: 'flex',

                                fontSize: '1rem',
                                fontWeight: '500',
                                alignItems: 'center',
                            }}
                        >
                            <Button
                                type="primary"
                                ghost={true}
                                className="format-button"
                                onClick={() => {
                                    if (title) {
                                        // Remove leading and trailing spaces within single or double quotes
                                        let cleanedValue = title.replace(
                                            /(['"])(.*?)\1/g,
                                            (match, p1, p2) =>
                                                `${p1}${p2.trim()}${p1}`
                                        )

                                        cleanedValue = cleanedValue
                                            ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                                            .replace(
                                                /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                                                '$1 '
                                            ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                                            .replace(
                                                /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/,
                                                '$1'
                                            ) // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                                            .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                                            .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                                            .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                                            .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                                            .trim() // Trim leading and trailing spaces

                                        // Remove trailing space after punctuation
                                        cleanedValue = cleanedValue.replace(
                                            /([,.!?;:])\s+$/,
                                            '$1'
                                        )

                                        setTitle(cleanedValue)
                                        titleChange(cleanedValue)
                                        form.setFieldValue(
                                            'title',
                                            cleanedValue
                                        )
                                        setTitleError(cleanedValue.length > 75)
                                    } else {
                                        setTitle('')
                                        form.setFieldValue('title', '')
                                        titleChange('')
                                        setTitleError(false)
                                    }
                                }}
                            >
                                <FontSizeOutlined />
                            </Button>

                            <span
                                style={{ marginTop: '3px', marginLeft: '5px' }}
                            >
                                <Speaker
                                    text={title}
                                    language={
                                        languageOption === 1
                                            ? 'en-US'
                                            : languageOption === 2
                                            ? 'hi-IN'
                                            : languageOption === 4
                                            ? 'ta-IN'
                                            : 'hi-IN'
                                    }
                                />
                            </span>
                        </span>
                    </span>
                </Form.Item>

                <Form.Item
                    label="Short Description"
                    name="shortDescription"
                    rules={[
                        {
                            required: true,
                            message: 'please input description',
                            whitespace: true,
                        },
                    ]}
                >
                    {/* {languageOption == 2 ?
                        <div
                            id="desc-checker"
                            contenteditable="true"
                            style={{ minHeight: '10rem' }}
                            maxLength={75}
                            value={shortDescription}
                            onChange={(e) => {
                                descriptionChange(e.target.innerHTML)
                                changeDescriptionValue(e.target.innerHTML)
                            }}
                            onBlur={(e) => textWordChecker(e.target.innerHTML, languageOption, "desc")}
                            className="text-area"
                        >
                        </div>
                        : */}
                    {!spellDescription ? (
                        <Input.TextArea
                            value={shortDescription}
                            rows={2}
                            autoSize
                            style={{ minHeight: '10rem' }}
                            onChange={(e) => {
                                // descriptionChange(e.target.value)
                                changeDescriptionValue(e.target.value)
                            }}
                            placeholder="Upto 60 words"
                        />
                    ) : (
                        <Spellcheck
                            setSpellDescription={setSpellDescription}
                            textValue={shortDescription}
                            setTextValue={setShortDescription}
                            wordsToHighlight={
                                AIData?.misspelledDescription
                                    ? AIData?.misspelledDescription
                                    : []
                            }
                            correctedWords={
                                AIData?.correctedDescription
                                    ? AIData?.correctedDescription
                                    : []
                            }
                            type={'description'}
                        />
                    )}

                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                        className="description-subtext"
                    >
                        <span
                            style={{
                                color: remainingWordsCount === 0 ? 'red' : '#555555',
                            }}
                        >
                            {' '}
                            {remainingWordsCount} words
                        </span>
                        |
                        <span
                            style={{
                                color: remainingCharCount === 0 ? 'red' : '#555555',
                            }}
                        >
                            {remainingCharCount} characters
                        </span>
                        |<span   style={{
                                color: remainingCharCount === 0 ? 'red' : '#555555',
                            }}>max 9 line only</span>
                    </span>
                    
                    <span
                        style={{
                            display: 'flex',
                            justifyContent: 'end',
                            fontSize: '1rem',
                            fontWeight: '500',
                            alignItems: 'center',
                        }}
                    >
                        {(remainingWordsCount===0 || remainingCharCount===0) && <div className='description-subtext' style={{ color: 'red',marginRight:"auto", fontStyle: 'italic' }}> - {'Description is too long'}</div>}
                        <Button
                            type="primary"
                            ghost={true}
                            className="format-button"
                            onClick={() => {
                                if (
                                    !shortDescription.includes('*') &&
                                    !shortDescription.includes('**')
                                ) {
                                    let cleanedValue = shortDescription

                                        ?.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
                                        .replace(
                                            /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])\s*(?=\S)/g,
                                            '$1 '
                                        ) // Add space after punctuation if not preceded by quote and not followed by a digit or quote
                                        .replace(
                                            /(?<!['"“‘])\s*([,.!?;:])(?!\d|['"”’])$/,
                                            '$1'
                                        ) // Remove space before punctuation at the end if not preceded by quote and not followed by a digit or quote
                                        .replace(/([₹$])\s*(\d)/g, '$1$2') // Remove space between currency symbol and number
                                        .replace(/(\d)\s+(\d)/g, '$1$2') // Remove space between digits
                                        .replace(/(?<!\.)\.\s*(?=\.)/g, '.') // Ensure continuous dots remain intact
                                        .replace(/(\.{2,})(?=\S)/g, '$1') // Leave continuous dots intact
                                        .trim() // Trim leading and trailing spaces

                                    if (
                                        cleanedValue?.split('\n')?.length <= 9
                                    ) {
                                        form.setFieldValue(
                                            'shortDescription',
                                            cleanedValue
                                        )
                                        descriptionChange(cleanedValue)
                                        const remainingWords =
                                            getRemainingWords(
                                                cleanedValue,
                                                'words',
                                                60
                                            )
                                        const remainingChars =
                                            getRemainingWords(
                                                cleanedValue,
                                                'char',
                                                400
                                            )
                                        if (
                                            remainingWords > -1 &&
                                            remainingChars > -1
                                        ) {
                                            form.setFieldValue(
                                                'shortDescription',
                                                cleanedValue
                                            )
                                            setShortDescription(cleanedValue)
                                            setRemaingCharCount(remainingChars)
                                            setRemaingWordsCount(remainingWords)
                                        } else if (cleanedValue === '') {
                                            setRemaingCharCount(400)
                                            setRemaingWordsCount(59)
                                        } else {
                                            if (remainingWords < 0) {
                                                const newDescription =
                                                    cleanedValue
                                                        .split(' ')
                                                        .slice(
                                                            0,
                                                            remainingWords
                                                        )
                                                        .join(' ')
                                                changeDescriptionValue(
                                                    newDescription
                                                )
                                            } else if (remainingChars < 0) {
                                                const newDescription =
                                                    cleanedValue
                                                        .split('')
                                                        .slice(
                                                            0,
                                                            remainingChars
                                                        )
                                                        .join('')
                                                changeDescriptionValue(
                                                    newDescription
                                                )
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            <FontSizeOutlined />
                        </Button>
                        <span style={{ marginTop: '3px', marginLeft: '5px' }}>
                            <Speaker
                                text={shortDescription}
                                language={
                                    languageOption === 1
                                        ? 'en-US'
                                        : languageOption === 2
                                        ? 'hi-IN'
                                        : languageOption === 4
                                        ? 'ta-IN'
                                        : 'hi-IN'
                                }
                            />
                        </span>
                    </span>
                </Form.Item>
                {keywords_g && (
                    <Form.Item label="Keywords" name="keywords" required={true}>
                        <div className="col-md" style={{ background: 'none' }}>
                            {keywords?.map((keyword, index) => (
                                <Tag
                                    key={index}
                                    closable
                                    style={{
                                        userSelect: 'none',
                                        marginBottom: '5px',
                                    }}
                                    onClose={(e) => {
                                        e.preventDefault()
                                        handleClose(index)
                                    }}
                                >
                                    <span>{keyword}</span>
                                </Tag>
                            ))}
                            <Input
                                onChange={(e) =>
                                    setKeywordInput(e.target.value)
                                }
                                value={keywordInput}
                                onKeyPress={(event) => {
                                    if (event.key === 'Enter') {
                                        event.preventDefault()
                                        addKeyword(event?.target?.value)
                                        setKeywordInput('')
                                    }
                                }}
                                placeholder="Type words and press enter after each word or click generate keywords"
                            />
                            {generate ? (
                                <Button style={{ margin: '.5rem 0' }} loading>
                                    Generating...
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    ghost
                                    style={{ margin: '.5rem 0' }}
                                    onClick={() => {
                                        generateKeyWords(
                                            title,
                                            form.getFieldValue(
                                                'shortDescription'
                                            )
                                        )
                                    
                                    }}
                                >
                                    Generate Keywords
                                </Button>
                            )}
                        </div>
                    </Form.Item>
                )}

                <Form.Item
                    style={{ marginBottom: '0px' }}
                    label="Image"
                    name="image"
                    rules={[
                        { required: true, message: 'Please select an Image' },
                    ]}
                >
                    <div style={{ display: 'flex', gap: '5px' }}>
                        {}
                        <ImageUploader getImagePath={getImagePath} />

                        <div>{isImageUploading && <LoadingOutlined />}</div>
                    </div>
                    {mode === 'edit' && (
                        // <a href={form.getFieldValue('image')} target="_blank">
                        //     Uploaded Image
                        // </a>
                        <ImageDownloader
                            imageUrl={form.getFieldValue('image')}
                        />
                    )}
                </Form.Item>
                <Form.Item label=" " name="representationalImage">
                    <Checkbox>
                        Image used for representational purpose only
                    </Checkbox>
                </Form.Item>
                <Form.Item label="Disturbing Visuals" name="disturbingVisuals">
                    <Radio.Group>
                        <Radio value="true"> Yes </Radio>
                        <Radio value="false"> No </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Media" name="media">
                    <Radio.Group>
                        <Radio disabled={true} value="video">
                            Video
                        </Radio>
                        <Radio value="youtubeLink"> Youtube Link </Radio>
                        <Radio value="otherLink">Other Link</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.media !== currentValues.media
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('media') === 'video' ? (
                            <Form.Item name="video" label="Video">
                                <div style={{ display: 'flex', gap: '5px' }}>
                                    <input
                                        accept="video/mp4"
                                        ref={inputVideoRef}
                                        disabled={true}
                                        onChange={(e) => {
                                            getVideoPath(e.target.files?.[0])
                                        }}
                                        type="file"
                                    />
                                    <div>
                                        {isVideoUploading && (
                                            <LoadingOutlined />
                                        )}
                                    </div>
                                </div>

                                {initialFormData?.r2_video_url ? (
                                    // <a
                                    //     href={initialFormData?.r2_video_url}
                                    //     target="_blank"
                                    // >
                                    //     Dowload Video
                                    // </a>
                                    <></>
                                ) : (
                                    ''
                                )}
                                <div>Max size 50Mb</div>
                            </Form.Item>
                        ) : getFieldValue('media') === 'youtubeLink' ? (
                            <Form.Item
                                label="Youtube Link"
                                name="youtubeLink"
                                rules={[
                                    {
                                        type: 'url',
                                        message: 'Please enter valid link',
                                    },
                                ]}
                            >
                                <Input
                                    placeholder="Youtube Link"
                                    onChange={(e) =>
                                        setVideoPath(e.target.value)
                                    }
                                />
                            </Form.Item>
                        ) : (
                            getFieldValue('media') === 'otherLink' && (
                                <Form.Item
                                    label="Other Link"
                                    name="otherLink"
                                    rules={[
                                        {
                                            type: 'url',
                                            message: 'Please enter valid link',
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Other Link"
                                        onChange={(e) =>
                                            setOtherLink(e?.target?.value)
                                        }
                                    />
                                </Form.Item>
                            )
                        )
                    }
                </Form.Item>
                {
                    <Form.Item name="source" label="Source">
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <AutoComplete
                                options={sourcesOptions}
                                value={sourceValue}
                                onSearch={(text) => {
                                    setSourceValue(text)
                                    setSourcesOptions(
                                        allSourcesOptions.filter(({ value }) =>
                                            value
                                                ?.toLowerCase()
                                                .includes(text?.toLowerCase())
                                        )
                                    )
                                }}
                                onSelect={onSelectSource}
                                placeholder="search and select source"
                            />
                            {isPublish(
                                userPermissions,
                                'Content',
                                'Short News'
                            ) && (
                                <Button
                                    className="plus-sign"
                                    onClick={() => setShowSourceModal(true)}
                                >
                                    <PlusOutlined />
                                </Button>
                            )}
                        </div>
                        {sourceError && (
                            <div style={{ color: 'red' }}>
                                Source is mandatory
                            </div>
                        )}
                    </Form.Item>
                }
                {
                    <Form.Item label="Source Link" name="sourceLink">
                        <Input placeholder="Source UrlLink" />
                    </Form.Item>
                }

                <Form.Item label="Hashtag">
                    <AutoComplete
                        options={hashTagOptions}
                        value={hashTagValue}
                        onSearch={(text) => {
                            setHashTagValue(text)
                            if (text === '') {
                                setHashTagId()
                            }
                            setHashtagOptions(
                                allHashTagOptions?.filter(({ value }) =>
                                    value
                                        ?.toLowerCase()
                                        ?.includes(text?.toLowerCase())
                                )
                            )
                        }}
                        onSelect={onHashTagSelect}
                        placeholder="select hashtag"
                    />
                </Form.Item>
                <Form.Item
                    name="expiryHours"
                    label="Expiry Hours"
                    rules={[{ required: true }]}
                >
                    <Select style={{ width: 200 }}>
                        {expiryHoursOptions?.map((option, index) => (
                            <Select.Option key={index} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'please select publish date',
                        },
                    ]}
                    label="Publish"
                    name="publish"
                >
                    <DatePicker
                        showTime
                        format="DD/MM/YYYY h:mm A"
                        disabledDate={(currentDate) => currentDate < new Date()}
                    />
                </Form.Item>
                <Form.Item label="Location Specific" name="locationSpecific">
                    <Radio.Group>
                        <Radio value="yes"> Yes </Radio>
                        <Radio value="no"> No </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.locationSpecific !==
                        currentValues?.locationSpecific
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('locationSpecific') === 'yes' ? (
                            <Form.Item
                                name="state"
                                label="State"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please select an option',
                                    },
                                ]}
                            >
                                {locationsData && (
                                    <Select
                                        onChange={onStateChange}
                                        placeholder="Select State"
                                        mode="multiple"
                                    >
                                        {locationsData?.map((option) => (
                                            <Select.Option
                                                key={option.state_id}
                                                value={option.name}
                                            >
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        ) : (
                            form.setFieldValue('state', [])
                        )
                    }
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.state !== currentValues.state
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('state') &&
                        getFieldValue('locationSpecific') === 'yes' ? (
                            <Form.Item name="districts" label="District">
                                <Select
                                    placeholder="Select District"
                                    onChange={onDistrictChange}
                                    mode="multiple"
                                >
                                    {districtsData?.map((option) => (
                                        <Select.Option
                                            key={option.district_id}
                                            value={option.name}
                                        >
                                            {option.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            form.setFieldValue('district', [])
                        )
                    }
                </Form.Item>
                {/* editing -------------- */}
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.state !== currentValues.state
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('state') &&
                        getFieldValue('locationSpecific') === 'yes' ? (
                            <Form.Item
                                name="assemblyconstituencies"
                                label="Assembly-Constituencies"
                            >
                                <Select
                                    placeholder="Select Assembly-Constituencies"
                                    onChange={handleAssemblyConstituencies}
                                    mode="multiple"
                                >
                                    {assemblyConstituenciesData?.map(
                                        (option) => (
                                            <Select.Option
                                                key={
                                                    option.assembly_constituency_id
                                                }
                                                value={option.name}
                                            >
                                                {option.name}
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Form.Item>
                        ) : (
                            form.setFieldValue('assemblyconstituencies', [])
                        )
                    }
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.state !== currentValues.state
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('districts') &&
                        getFieldValue('locationSpecific') === 'yes' &&
                        getFieldValue('districts').length !== 0 ? (
                            <Form.Item name="mandal" label="Mandal">
                                <Select
                                    placeholder="Select Mandal"
                                    onChange={onMandalChange}
                                    mode="multiple"
                                >
                                    {mandalsData?.map((option) => (
                                        <Select.Option
                                            key={option.mandal_id}
                                            value={option.name}
                                        >
                                            {option.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            form.setFieldValue('district', [])
                        )
                    }
                </Form.Item>
                {/* {isPublish(userPermissions, 'Content', 'Short News') &&
                    languageOption !== 2 && (
                        <Form.Item
                            label="Proof read"
                            name="proofRead"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an option',
                                },
                            ]}
                            // className="custom-form-item"
                        >
                            <Radio.Group>
                                <Radio value={true}> Yes </Radio>
                                <Radio value={false}> No </Radio>
                            </Radio.Group>
                        </Form.Item>
                    )} */}

                <Form.Item wrapperCol={{ span: 48 }}>
                    {isSubmitAndDraft(
                        userPermissions,
                        'Content',
                        'Short News'
                    ) && (
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            onClick={() => handleSave('draft')}
                            disabled={isLoading || contentStatus === 'approved'}
                        >
                            Draft
                        </Button>
                    )}
                    {isSubmitAndDraft(
                        userPermissions,
                        'Content',
                        'Short News'
                    ) && (
                        <Button
                            className="bg-color width-150 submit-buttons"
                            type="primary"
                            onClick={() => handleSave('submit')}
                            disabled={isLoading || contentStatus === 'approved'}
                        >
                            Submit
                        </Button>
                    )}
                    {isPublish(userPermissions, 'Content', 'Short News') && (
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            onClick={() => handlePublish()}
                            disabled={isLoading}
                        >
                            Publish
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}
export default AddShortNewsForm
