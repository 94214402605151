import dayjs from 'dayjs';
import axios from 'axios';

const today = dayjs().format('YYYY-MM-DD')
export const getVideoFeed = async (page = 1, query, date = today, category, language = 'hindi') => {
    const url = `${process.env.REACT_APP_API_URL}other-videos/?language=${language}${query ? `&query=${query}` : ''}${date || category !== "special" ? `&created_at_date=${date ? date : today}` : ''}${category && category?.toLowerCase() !== 'all' ? `&tags=${category}` : ''}&page=${page}`
    try {
        const response = await axios({ url })
        return response?.data
    }
    catch (err) {
        console.log("error: ", err)
    }
}

export const getFormatedDate = (date) => {
    const newDate = dayjs(date).format('MMM D, YYYY h:mm A')
    return newDate
} 