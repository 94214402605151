//list of tamil words to match for today or tomorrow
export const todayVariationstamil = [
    'இன்று',
    'இன்றே',
    'இன்றைய',
    'இன்றிரவு',
]
export const tomorrowVariationstamil = ['நாளை']

//list of telugu words to match for today or tomorrow
export const todayVariationsTelugu = [
    'ఈరోజు',
    'ఈ రోజు',
    'నేడు',
    'ఇవాళ',
    'ఇవ్వాళ',
    'ఈ నాడు',
    'ఇదే రోజు',
    'నేటి',
    'నేడే', 
]

export const tomorrowVariationsTelugu = ['రేపు','రేపటి','రేపే']

//list of hindi words to match for today or tomorrow
export const todayVariationsHindi = ['आज', 'अभी']
export const tomorrowVariationsHindi = ['कल', 'अगले दिन']
export const toss = ['కాసేపట్లో', 'టాస్', 'டாஸ்', 'टॉस']