import {
    Button,
    DatePicker,
    Form,
    Input,
    Radio,
    Select,
    message,
} from 'antd'
import ImageUploader from '../../image-uploader-crop/ImageUploader'
import './AddPollForm.scss'
import {
    PlusOutlined,
    LoadingOutlined,
    MinusOutlined,
} from '@ant-design/icons'
import { useEffect, useState } from 'react'
import {
    expiryHoursOptions,
    isPublish,
    isSubmitAndDraft,
    TableTypeFromRoutesContent,
} from '../../App.constants'
import dayjs from 'dayjs'
import {
    getImageUrl,
    createPolls,
    updatePolls,
    getSubCategories,
} from '../../../AadhanApi'
import { useNavigate, useLocation} from 'react-router'
import { useSelector } from 'react-redux'
const AddPollForm = ({
    questions,
    setImagePath,
    setQuestions,
    categories,
    languageData,
    accessToken,
    initialFormData,
    mode,
    contentId,
    contentStatus,
    code,
}) => {
    const [form] = Form.useForm()
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const employeeId = useSelector((state) => state.app.employeeId)
    const employeeName = useSelector((state) => state.app.employeeName)
    const [validateQuestions, setValidateQuestions] = useState()
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )
    const [messageApi, contextHolder] = message.useMessage()
    const [subCategory, setSubCategory] = useState([])
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [imagePathUrl, setImagePathUrl] = useState('')
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [englishTitle, setEnglishTitle] = useState('')
    const [englishTitleError, setEnglishTitleError] = useState(false)
    const location = useLocation()
    const [mapOptions, setMapOptions] = useState([])
    const currentTableType =
        TableTypeFromRoutesContent[location?.pathname] ?? 'shortnews'

    const [subCategoryOption, setSubCategoryOption] = useState([])

    useEffect(() => {
        form.setFieldsValue(initialFormData)
        setImagePathUrl(form.getFieldValue('image'))
        handleLanguageChange(initialFormData?.language, 'first')
        handleEnglishTitleChange(initialFormData?.englishTitle)
        // handleCategoryChange(initialFormData?.category)
    }, [form, initialFormData])
    
    useEffect(() => {
        handleCategoryChange(initialFormData?.category)
    }, [initialFormData])

    const isQuestionsValid = () => {
        return (
            questions.question !== '' &&
            questions.options.every((option) => option !== '')
        )
    }

    const handleEnglishTitleChange = (value) => {
        form.setFieldValue('englishTitle', value)
        setEnglishTitle(value)
        setEnglishTitleError(value?.length > 75)
    }

    const createRequest = (values, type) => {
        let languageName = languageData
            ?.map((ele, i) => {
                if (ele.language_id == values?.language) {
                    return ele.language_name
                }
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]
        let categoryName = categories
            ?.map((ele, i) => {
                if (
                    ele.language_name == languageName &&
                    ele.category_id == values.category
                ) {
                    return ele.category_name
                }
            })
            .filter((Undefined) => Undefined !== undefined)?.[0]

        const req = {
            title: questions?.question,
            source_id: 1,
            source_url: '',
            category_id: values?.category,
            content_type: 'poll',
            dynamic_url: '',
            expiry_hours:
                values?.expiryHours == 'Never' ? 0 : values?.expiryHours,
            published_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            state: '',
            district: '',
            city: '',
            is_active: true,
            content_status: type,
            code,
            language_id: values?.language,
            image_url: imagePathUrl,
            question: questions.question,
            options: generateAdditionalProps(questions?.options),
            options_subcategories: generateSubcategory(),
            thumbnailimage_url: '',
            scheduled_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            left_color: '',
            right_color: '',
            uploaded_by_name: initialFormData?.uploadedByName || employeeName,
            proof_read_by_name: employeeName,
            category_name: categoryName,
        }
        if (values?.subcategory !== '' && values?.subcategory !== undefined) {
            req.sub_category_name = values?.subcategory
            const id = subCategory.filter(
                (e) => e.sub_category_name === values?.subcategory
            )
            req.sub_category_id = id[0]?.sub_category_id
        } else {
            req.sub_category_name = ''
            req.sub_category_id = 0
        }
        return req
    }
    const handleSave = async (type) => {
        try {
            const validation = await form.validateFields([
                'language',
                'category',
                'image',
                'expiryHours',
                'englishTitle',
            ])
            setValidateQuestions(true)
            if (validation?.errorFields?.length > 0) {
                return
            }
        } catch (e) {
            e?.errorFields?.length &&
                e.errorFields.forEach((ele) => {
                    if (
                        ele?.name === 'title' ||
                        ele?.name === 'category' ||
                        ele?.name === 'image'
                    ) {
                        window.scrollTo(0, 0)
                    }
                })
            return
        }
        setIsLoading(true)
        if (isQuestionsValid()) {
            const request = createRequest({ ...form.getFieldsValue() }, type)
            if (mode === 'edit') {
                request.updated_by = employeeId
                request.updated_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.uploaded_by = initialFormData?.uploadedBy
                request.uploaded_date = initialFormData?.uploadedDate
                try {
                    const pollResponse = await updatePolls(
                        request,
                        contentId,
                        accessToken
                    )
                    if (pollResponse?.status == 200) {
                        setIsLoading(false)
                        navigate('/home/poll')
                    }
                } catch (err) {
                    setIsLoading(false)
                    console.log(err)
                    messageApi.open({
                        type: 'error',
                        content: 'Failed to Update Polls, Please try again',
                        duration: 5,
                    })
                }
            } else if (mode === 'add') {
                request.uploaded_by = employeeId
                request.updated_by = 0
                request.uploaded_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                try {
                    const pollResponse = await createPolls(request, accessToken)
                    if (pollResponse?.status === 200) {
                        setIsLoading(false)
                        navigate('/home/poll')
                    }
                } catch (err) {
                    setIsLoading(false)
                    console.log(err)
                    messageApi.open({
                        type: 'error',
                        content: 'Failed to Create Polls, Please try again',
                        duration: 5,
                    })
                }
            }
        }
    }

    const onFinish = async (values) => {
        setIsLoading(true)
        setValidateQuestions(true)
        if (isQuestionsValid()) {
            const request = createRequest(values)
            request.content_status = 'approved'
            if (true) {
                request.proof_read_by = employeeId
                request.proof_read_date = dayjs(values?.publish).format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.is_proof_read = true
            }
            if (mode === 'edit') {
                request.updated_by = employeeId
                request.updated_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.uploaded_by = initialFormData?.uploadedBy
                request.uploaded_date = initialFormData?.uploadedDate
                try {
                    const pollResponse = await updatePolls(
                        request,
                        contentId,
                        accessToken
                    )
                    if (pollResponse?.status == 200) {
                        setIsLoading(false)
                        navigate('/home/poll')
                    }
                } catch (err) {
                    console.log(err)
                    setIsLoading(false)
                    messageApi.open({
                        type: 'error',
                        content: 'Failed to Update Polls, Please try again',
                        duration: 5,
                    })
                }
            } else if (mode === 'add') {
                request.uploaded_by = employeeId
                request.updated_by = 0
                request.uploaded_date = dayjs().format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                try {
                    const pollResponse = await createPolls(request, accessToken)
                    if (pollResponse?.status == 200) {
                        setIsLoading(false)
                        navigate('/home/poll')
                    }
                } catch (err) {
                    console.log(err)
                    setIsLoading(false)
                    messageApi.open({
                        type: 'error',
                        content: 'Failed to Create Polls, Please try again',
                        duration: 5,
                    })
                }
            }
        }
    }
    useEffect(() => {
        handleLanguageChange(languageOption, '')
    }, [languageOption])

    const handleLanguageChange = async (id, time) => {
        setLanguageOption(id)

        if (
            time !== 'first' &&
            form.getFieldValue('category') !== null &&
            form.getFieldValue('category') !== undefined
        ) {
            await getSubCategories(
                accessToken,
                id,
                form.getFieldValue('category')
            ).then((resp) => {
                setSubCategory(resp.data.data)
                let optionData = questions?.options?.reduce(
                    (acc, e, index) => {
                        const data = resp.data.data.map((subCat) => ({
                            ...subCat,
                            anti: false,
                            pro: false,
                        }))
                        acc[index] = data
                        return acc
                    },
                    {}
                )
                const data = initialFormData?.options_subcategories;
                    let ind = 0;
    
                    for (let key in data) {
                        // Handle pro status
                        if (data[key]?.subcategory_id !== 0) {
                            const target = optionData?.[ind];
                            const updated = target?.map((subCat) =>
                                subCat.sub_category_id === data[key]?.subcategory_id
                                    ? { ...subCat, pro: true, anti: false }
                                    : { ...subCat, pro: false }
                            );
                            optionData = {
                                ...optionData,
                                [ind]: updated,
                            };
                        }
    
                        // Handle anti status
                        if (data[key]?.anti_subcategory_id !== 0) {
                            const target = optionData?.[ind];
                            const updated = target?.map((subCat) =>
                                subCat.sub_category_id === data[key]?.anti_subcategory_id
                                    ? { ...subCat, anti: true, pro: false }
                                    : { ...subCat, anti: false }
                            );
                            optionData = {
                                ...optionData,
                                [ind]: updated,
                            };
                        }
    
                        ind++;
                    }
    
                    setSubCategoryOption(optionData);

                setSubCategoryOption(optionData)
            })
        }
    }

    const generateAdditionalProps = (arr) => {
        const obj = {}
        arr?.forEach((element, index) => {
            const key = `additionalProp${index + 1}`
            obj[key] = element
        })
        return obj
    }
    const generateSubcategory = () => {
        let obj = {}
        let index = 1
        for (let key in subCategoryOption) {
            const keyprob = `additionalProp${index}`
            let anti_id = 0
            let pro_id = 0
            subCategoryOption?.[key].map((e) => {
                if (e?.anti) {
                    anti_id = e?.sub_category_id
                } else if (e?.pro) {
                    pro_id = e?.sub_category_id
                }
            })
            obj[keyprob] = {
                subcategory_id: pro_id,
                anti_subcategory_id: anti_id,
            }
            index++
        }
        return obj
    }
    const onFinishFailed = (errorInfo) => {
        setValidateQuestions(true)
        errorInfo?.errorFields?.length &&
            errorInfo.errorFields.forEach((ele) => {
                if (
                    ele?.name === 'title' ||
                    ele?.name === 'category' ||
                    ele?.name === 'image'
                ) {
                    window.scrollTo(0, 0)
                }
            })
    }

    const getImagePath = async (path) => {
        setIsLoading(true)
        setIsImageUploading(true)
        const pathUriArr = await getImageUrl(path, 'poll', accessToken,languageOption)
        setIsLoading(false)
        if (pathUriArr?.data) {
            setImagePath(
                pathUriArr?.data
            )
            setImagePathUrl(
                pathUriArr?.data
            )
            setIsImageUploading(false)
        }
    }

    const onRemoveButton = (index) => {
        const newOptions = [...questions.options]
        newOptions?.splice(index, 1)
        setQuestions({ ...questions, options: [...newOptions] })
        let data = subCategoryOption
        let update = {}
        let ind = 0
        for (let key in data) {
            if (parseInt(key) !== index) {
                update[ind] = data[key]
                ind++
            }
        }

        setSubCategoryOption(update)
    }
    const onInputChange = (value, index) => {
        const newOptions = [...questions.options]
        newOptions[index] = value
        setQuestions({ ...questions, options: [...newOptions] })
    }

    const handleCategoryChange = async (e) => {
        if (e !== null && e !== undefined) {
            await getSubCategories(accessToken, languageOption, e).then(
                (resp) => {
                    const updatedSubCategories = resp?.data?.data;
                    setSubCategory(updatedSubCategories);
    
                    // Initialize optionData based on questions.options
                    let optionData = questions?.options?.reduce((acc, e, index) => {
                        const data = resp.data.data.map((subCat) => ({
                            ...subCat,
                            anti: false,
                            pro: false,
                        }));
                        acc[index] = data;
                        return acc;
                    }, {});
    
                    // Extract initial form data
                    const data = initialFormData?.options_subcategories;
                    let ind = 0;
    
                    for (let key in data) {
                        // Handle pro status
                        if (data[key]?.subcategory_id !== 0) {
                            const target = optionData?.[ind];
                            const updated = target?.map((subCat) =>
                                subCat.sub_category_id === data[key]?.subcategory_id
                                    ? { ...subCat, pro: true, anti: false }
                                    : { ...subCat, pro: false }
                            );
                            optionData = {
                                ...optionData,
                                [ind]: updated,
                            };
                        }
    
                        // Handle anti status
                        if (data[key]?.anti_subcategory_id !== 0) {
                            const target = optionData?.[ind];
                            const updated = target?.map((subCat) =>
                                subCat.sub_category_id === data[key]?.anti_subcategory_id
                                    ? { ...subCat, anti: true, pro: false }
                                    : { ...subCat, anti: false }
                            );
                            optionData = {
                                ...optionData,
                                [ind]: updated,
                            };
                        }
    
                        ind++;
                    }
    
                    setSubCategoryOption(optionData);
                }
            );
        }
    };
    

    const updateProStatus = (id, index) => {
        const target = subCategoryOption?.[index]
        const updated = target?.map((subCat) =>
            subCat.sub_category_id === id
                ? { ...subCat, pro: true, anti: false }
                : { ...subCat, pro: false }
        )
        const newSubCategoryOption = { ...subCategoryOption, [index]: updated }
        setSubCategoryOption(newSubCategoryOption)
    }

    const updateAntiStatus = (id, index) => {
        const target = subCategoryOption?.[index]
        const updated = target?.map((subCat) =>
            subCat.sub_category_id === id
                ? { ...subCat, anti: true, pro: false }
                : { ...subCat, anti: false }
        )
        const newSubCategoryOption = { ...subCategoryOption, [index]: updated }
        setSubCategoryOption(newSubCategoryOption)
    }
    const handlePublish = () => {
        form.validateFields()?.then(
            (res) => {
                onFinish(res)
            },
            (error) => {
                error?.errorFields?.length &&
                    error.errorFields.forEach((ele) => {
                        if (
                            ele?.name == 'title' ||
                            ele?.name == 'category' ||
                            ele?.name == 'image'
                        ) {
                            window.scrollTo(0, 0)
                        }
                    })
                console.log(error)
            }
        )
    }
   
    return (
        <div id="form" style={{ width: '100%' }}>
            {contextHolder}
            <Form
                form={form}
                name="basic"
                colon={false}
                scrollToFirstError
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                initialValues={initialFormData}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select language' },
                    ]}
                >
                    <Select
                        placeholder="--Select Language--"
                        onChange={handleLanguageChange}
                    >
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Category"
                    name="category"
                    rules={[{ required: true, message: 'Please enter value' }]}
                >
                    <Select
                        onChange={handleCategoryChange}
                        placeholder="--Select Category--"
                    >
                        {categories
                            ?.filter(
                                (ct) =>
                                    ct?.language_name ===
                                        languageData?.filter(
                                            (l) =>
                                                l.language_id === languageOption
                                        )?.[0]?.language_name &&
                                    ct?.type_content?.includes(currentTableType)
                            )
                            ?.map((option) => {
                                return (
                                    <Select.Option
                                        key={option?.category_name}
                                        value={option?.category_id}
                                    >
                                        {option?.category_name}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.category !== currentValues.category
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('category') &&
                        subCategory.length !== 0 ? (
                            <Form.Item name="subcategory">
                                <Radio.Group
                                    style={{
                                        marginLeft: '35%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'start',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {subCategory?.map((e) => {
                                        return (
                                            <Radio
                                                style={{ margin: '.25rem' }}
                                                value={e.sub_category_name}
                                                key={e.sub_category_id}
                                            >
                                                {e.sub_category_name}
                                            </Radio>
                                        )
                                    })}
                                </Radio.Group>
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>
                <Form.Item
                    name="expiryHours"
                    label="Expiry Hours"
                    rules={[{ required: true }]}
                >
                    <Select style={{ width: 200 }}>
                        {expiryHoursOptions?.map((option, index) => (
                            <Select.Option key={index} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Image"
                    name="image"
                    required
                    rules={[
                        { required: true, message: 'Please select an Image' },
                    ]}
                >
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <ImageUploader getImagePath={getImagePath} />
                        <div>{isImageUploading && <LoadingOutlined />}</div>
                    </div>
                </Form.Item>
                <div
                    style={{
                        width: '100%',
                        marginBottom: '10px',
                        backgroundColor: 'white',
                        border: '1px solid #e2e2e2',
                    }}
                >
                    <div style={{ padding: '10px' }}>
                        <h5>Question</h5>
                    </div>
                    <div style={{ padding: '10px' }}>
                        <Input.TextArea
                            maxLength={70}
                            style={{ minHeight: '5rem' }}
                            className={
                                (validateQuestions &&
                                    questions.question === '') ||
                                questions.question?.length == 70
                                    ? 'error'
                                    : null
                            }
                            value={questions.question}
                            onChange={(e) => {
                                setQuestions({
                                    ...questions,
                                    question: e.target.value,
                                })
                                if (questions?.question?.length) {
                                    setIsLoading(false)
                                }
                            }}
                            placeholder="Enter questioin here..."
                        />
                        <span className="description-subtext">
                            <span
                                style={{
                                    color:
                                        questions.question?.length === 70
                                            ? 'red'
                                            : null,
                                }}
                            >
                                {' '}
                                {70 - (questions.question?.length ?? 0)}{' '}
                                characters
                            </span>
                        </span>
                        {validateQuestions && questions.question === '' && (
                            <span style={{ color: 'red' }}>
                                Please enter Question
                            </span>
                        )}
                        <div style={{ padding: '10px' }}>
                            {questions?.options?.map((value, index) => (
                                <div key={index} style={{ margin: '10px' }}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            border: 'solid 1px lightblue',
                                            borderRadius: '5px',
                                            padding: '.5rem',
                                        }}
                                    >
                                        <div style={{ display: 'flex' }}>
                                            <Input
                                                className={
                                                    validateQuestions &&
                                                    value === ''
                                                        ? 'error'
                                                        : null
                                                }
                                                value={value}
                                                placeholder="option"
                                                onChange={(e) => {
                                                    onInputChange(
                                                        e.target.value,
                                                        index
                                                    )
                                                    if (
                                                        e?.target?.value?.length
                                                    ) {
                                                        setIsLoading(false)
                                                    }
                                                }}
                                            />
                                            {questions?.options?.length > 2 && (
                                                <Button
                                                    shape="circle"
                                                    onClick={() =>
                                                        onRemoveButton(index)
                                                    }
                                                    style={{
                                                        marginLeft: '1rem',
                                                    }}
                                                    icon={
                                                        <MinusOutlined
                                                            style={{
                                                                color: 'red',
                                                            }}
                                                        />
                                                    }
                                                ></Button>
                                            )}
                                        </div>
                                        {subCategoryOption[index] &&
                                            subCategoryOption[index]?.length !==
                                                0 && (
                                                <Form.Item
                                                    style={{ margin: '0' }}
                                                    name="subcategory"
                                                >
                                                    {subCategoryOption[
                                                        index
                                                    ]?.map((subcat) => {
                                                        return (
                                                            <div
                                                                style={{
                                                                    display:
                                                                        'flex',
                                                                    width: '100%',
                                                                    alignItems:
                                                                        'center',
                                                                    margin: '2% 0 0 5%',
                                                                }}
                                                                key={
                                                                    subcat.sub_category_id
                                                                }
                                                            >
                                                                <span
                                                                    style={{
                                                                        marginRight:
                                                                            '1rem',
                                                                        maxWidth:
                                                                            '10rem',
                                                                    }}
                                                                >
                                                                    {
                                                                        subcat.sub_category_name
                                                                    }
                                                                </span>
                                                                <div
                                                                    style={{
                                                                        diplay: 'flex',
                                                                        alignItems:
                                                                            'center',
                                                                    }}
                                                                >
                                                                    <Radio
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            updateProStatus(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                                index
                                                                            )
                                                                        }
                                                                        checked={
                                                                            subcat.pro
                                                                        }
                                                                        type="radio"
                                                                        name="pro"
                                                                        value={
                                                                            subcat.sub_category_id
                                                                        }
                                                                    />
                                                                    <label
                                                                        style={{
                                                                            margin: '0 .5rem 0 0',
                                                                            color: 'gray',
                                                                        }}
                                                                    >
                                                                        pro
                                                                    </label>

                                                                    <Radio
                                                                        onChange={(
                                                                            e
                                                                        ) =>
                                                                            updateAntiStatus(
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                                index
                                                                            )
                                                                        }
                                                                        checked={
                                                                            subcat.anti
                                                                        }
                                                                        type="radio"
                                                                        name="anti"
                                                                        value={
                                                                            subcat.sub_category_id
                                                                        }
                                                                    />
                                                                    <label
                                                                        style={{
                                                                            color: 'gray',
                                                                        }}
                                                                    >
                                                                        anti
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </Form.Item>
                                            )}
                                    </div>
                                    {validateQuestions && value === '' && (
                                        <span style={{ color: 'red' }}>
                                            Please enter Option
                                        </span>
                                    )}
                                </div>
                            ))}
                            {questions?.options?.length < 4 && (
                                <div
                                    onClick={() => {
                                        setQuestions({
                                            ...questions,
                                            options: [...questions.options, ''],
                                        })
                                        setSubCategoryOption({
                                            ...subCategoryOption,
                                            [questions?.options?.length]:
                                                subCategory,
                                        })
                                    }}
                                    style={{ textAlign: 'center' }}
                                >
                                    <Button
                                        shape="circle"
                                        style={{ marginLeft: '10px' }}
                                        icon={<PlusOutlined />}
                                    ></Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* {isPublish(userPermissions, 'Content', 'Poll') &&
                    languageOption !== 2 && (
                        <Form.Item
                            label="Proof read"
                            name="proofRead"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an option',
                                },
                            ]}
                            className="custom-form-item"
                        >
                            <Radio.Group>
                                <Radio value={true}> Yes </Radio>
                                <Radio value={false}> No </Radio>
                            </Radio.Group>
                        </Form.Item>
                    )} */}

                <Form.Item
                    rules={[{ required: true }]}
                    label="Publish"
                    name="publish"
                >
                    <DatePicker
                        showTime
                        format="DD/MM/YYYY h:mm A"
                        disabledDate={(currentDate) => currentDate < new Date()}
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 24 }}>
                    {isSubmitAndDraft(userPermissions, 'Content', 'Poll') && (
                        <Button
                            className="bg-color"
                            type="primary"
                            onClick={() => handleSave('draft')}
                            disabled={isLoading || contentStatus == 'approved'}
                        >
                            Draft
                        </Button>
                    )}
                    {isSubmitAndDraft(userPermissions, 'Content', 'Poll') && (
                        <Button
                            className="bg-color submit-buttons"
                            type="primary"
                            onClick={() => handleSave('submit')}
                            disabled={isLoading || contentStatus === 'approved'}
                        >
                            Submit
                        </Button>
                    )}
                    {isPublish(userPermissions, 'Content', 'Poll') && (
                        <Button
                            className="bg-color"
                            type="primary"
                            onClick={handlePublish}
                            disabled={isLoading}
                        >
                            Publish
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}
export default AddPollForm
