export const TEXT_START_X = 120
export const TEXT_END_X = 200
export const LINE_HEIGHT = 23

export const languageContent = {
    1: {
        heading: "Today's Panchangam",
        labels: [
            'Good Time',
            'Rahu Kalam',
            'Yama Gandam',
            'Kuligai',
            'Sulam',
            'Nakshatram',
            'Thithi',
            'Yogam',
            'Chandrashtamam',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Noto Serif',
                fontSize: '23px',
            },
            labels: {
                fontFamily: 'Sree Krushnadevaraya',
                fontSize: '16px',
            },
            values: { fontFamily: 'Roboto Condensed', fontSize: '16px' },
        },
    },
    2: {
        heading: 'నేటి పంచాంగం',
        labels: [
            'శుభ సమయం',
            'రాహు కాలం',
            'యమగండం',
            'కులిక గడియం',
            'శూలం',
            'నక్షత్రం',
            'తిథి',
            'యోగం',
            'చంద్రాష్టమం',
        ],
        fontProperties: {
            heading: { fontFamily: 'Suravaram', fontSize: '35px' },
            labels: {
                fontFamily: 'Sree Krushnadevaraya',
                fontSize: '17px',
            },
            values: { fontFamily: 'Roboto Condensed', fontSize: '16px' },
        },
    },
    4: {
        heading: 'தினசரி பஞ்சாங்கம்',
        labels: [
            'நல்ல நேரம்',
            'இராகு காலம்',
            'எமகண்டம்',
            'குளிகை',
            'சூலம்',
            'நட்சத்திரம்',
            'திதி',
            'யோகம்',
            'சந்திராஷ்டமம்',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Tamil Sangam MN',
                fontSize: '21px',
            },
            labels: {
                fontFamily: 'Tamil Sangam MN',
                fontSize: '12px',
            },
            values: { fontFamily: 'Roboto Condensed', fontSize: '16px' },
        },
    },
    3: {
        heading: 'दैनिक पंचांग',
        labels: [
            'शुभ समय',
            'राहु काल',
            'यमगंड',
            'कुलिक',
            'शूल',
            'नक्षत्र',
            'तिथि',
            'योग',
            'चंद्राष्टम',
        ],
        fontProperties: {
            heading: {
                fontFamily: 'Devanagari Sangam MN',
                fontSize: '30px',
            },
            labels: {
                fontFamily: 'Devanagari Sangam MN',
                fontSize: '16px',
            },
            values: {
                fontFamily: 'Roboto Condensed',
                fontSize: '16px',
            },
        },
    },
}

export const allMonths = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
]
