import { Button, DatePicker, Form, Input, Radio, Select, message } from 'antd'
import ImageUploader from '../../image-uploader-crop/ImageUploader'
import './AddQuiz.scss'
import { CloseOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import {
    expiryHoursOptions,
    isPublish,
    isSubmitAndDraft,
    TableTypeFromRoutesContent
} from '../../App.constants'
import { createQuiz, getImageUrl, updateQuiz } from '../../../AadhanApi'
import { useNavigate, useLocation } from 'react-router'
import { useSelector } from 'react-redux'

const AddQuizForm = ({
    questions,
    setImagePath,
    setQuestions,
    initialFormData,
    accessToken,
    languageData,
    categories,
    contentId,
    mode,
    contentStatus,
    code
}) => {
    const [form] = Form.useForm()
    const employeeId = useSelector((state) => state.app.employeeId)
    const employeeName = useSelector((state) => state.app.employeeName)
    const [validateQuestions, setValidateQuestions] = useState()
    const [selectedAnswerIndex, setSelectedAnswerIndex] = useState()
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [imagePathUrl, setImagePathUrl] = useState('')
    const navigate = useNavigate()
    const [messageApi, contextHolder] = message.useMessage()
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const [isLoading,setIsLoading] = useState(false);
    const [englishTitle, setEnglishTitle] = useState('')
    const [englishTitleError, setEnglishTitleError] = useState(false)
    const location = useLocation()
    const currentTableType =
        TableTypeFromRoutesContent[location?.pathname] ?? 'shortnews'

    useEffect(() => {
        form.setFieldsValue(initialFormData)
        setSelectedAnswerIndex(initialFormData?.selectedAnswerIndex)
        handleEnglishTitleChange(initialFormData?.englishTitle)
    }, [form, initialFormData])

    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )

    const handleEnglishTitleChange = (value) => {
        form.setFieldValue('englishTitle', value)
        setEnglishTitle(value)
        setEnglishTitleError(value?.length > 75)
    }


    useEffect(() => {
        form.setFieldsValue(initialFormData)
        setImagePathUrl(form.getFieldValue('image'))
        handleLanguageChange(initialFormData.language)
    }, [form, initialFormData])

    const createRequest = (values, type) => {
        let languageName = languageData?.map((ele, i) => {
            if(ele.language_id == values?.language) {
                return (
                    ele.language_name
                )
            }
        }).filter(Undefined => Undefined !== undefined)?.[0]
        let categoryName = categories?.map((ele, i) => {
            if((ele.language_name == languageName) && (ele.category_id == values.category)) {
        
                return (
                    ele.category_name
                )
            }
        }).filter(Undefined => Undefined !== undefined)?.[0]

        return {
            title: questions?.question,
            source_id: 1,
            source_url: '',
            category_id: values?.category,
            content_type: 'quiz',
            dynamic_url: '',
            expiry_hours: values?.expiryHours == 'Never' ? 0 : values?.expiryHours,
            published_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            state: '',
            district: '',
            city: '',
            is_active: true,
            content_status: type,
            code,
            language_id: values?.language,
            image_url: imagePathUrl,
            quiz_answer_id: selectedAnswerIndex,
            question: questions.question,
            options: generateAdditionalProps(questions.options),
            thumbnailimage_url: '',
            scheduled_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            left_color: '',
            right_color: '',
            uploaded_by_name: initialFormData?.uploadedByName || employeeName,
            proof_read_by_name: employeeName,
            category_name: categoryName
        }
    }
 
    const handleSave = async (type) => {
        try {
            const validation = await form.validateFields(['language','category', 'image', 'expiryHours','englishTitle'])
            setValidateQuestions(true)
            if (validation?.errorFields?.length > 0) {
                return
            }
        } catch (e) {
            e?.errorFields?.length && e.errorFields.forEach(ele => {
                if(ele?.name == 'title' || ele?.name == 'category' || ele?.name == 'image') {
                    window.scrollTo(0, 0);
                }
            })
            return
        }
        setIsLoading(true)
        if (isQuestionsValid()) {
            const request = createRequest({ ...form.getFieldsValue() }, type)
            if (mode === 'edit') {
                request.updated_by = employeeId
                request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                request.uploaded_by = initialFormData?.uploadedBy 
                request.uploaded_date = initialFormData?.uploadedDate               
                try {
                    const quizResponse = await updateQuiz(
                        request,
                        contentId,
                        accessToken
                    )
                    if (quizResponse?.data === 'quiz updated successfully') {
                        setIsLoading(false)
                        navigate('/home/quiz')
                    }
                } catch (err) {
                    setIsLoading(false)
                    console.log(err)
                    messageApi.open({
                        type: 'error',
                        content: 'Failed to Update Quiz',
                        duration: 5,
                    })
                }
            } else if (mode === 'add') {
                request.uploaded_by = employeeId
                request.updated_by = 0
                request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                try {
                    const quizResponse = await createQuiz(request, accessToken)
                    if (quizResponse?.data === 'Quiz inserted successfully') {
                        setIsLoading(false)
                        navigate('/home/quiz')
                    }
                } catch (err) {
                    setIsLoading(false)
                    console.log(err)
                    messageApi.open({
                        type: 'error',
                        content: 'Failed to Create Quiz',
                        duration: 5,
                    })
                }
            }
        }
    }

    const onFinish = async (values) => {
        setIsLoading(true)
        setValidateQuestions(true)
        if (isQuestionsValid()) {
            const request = createRequest(values)
            request.content_status = 'approved'
            if (true) {
                request.proof_read_by = employeeId
                request.proof_read_date = dayjs(values?.publish).format(
                    'YYYY-MM-DDTHH:mm:ss.SSSZ'
                )
                request.is_proof_read = true
            }

            if (mode === 'edit') {
                request.updated_by = employeeId
                request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                request.uploaded_by = initialFormData?.uploadedBy
                request.uploaded_date = initialFormData?.uploadedDate
                try {
                    const quizResponse = await updateQuiz(
                        request,
                        contentId,
                        accessToken
                    )
                    if (quizResponse?.data === 'quiz updated successfully') {
                        setIsLoading(false)
                        navigate('/home/quiz')
                    }
                } catch (err) {
                    setIsLoading(false)
                    console.log(err)
                    messageApi.open({
                        type: 'error',
                        content: 'Failed to Update Quiz',
                        duration: 5,
                    })
                }
            } else if (mode === 'add') {
                request.uploaded_by = employeeId
                request.updated_by = 0
                request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
                try {
                    const quizResponse = await createQuiz(request, accessToken)
                    if (quizResponse?.data === 'Quiz inserted successfully') {
                        setIsLoading(false)
                        navigate('/home/quiz')
                    }
                } catch (err) {
                    console.log(err)
                    setIsLoading(false)
                    messageApi.open({
                        type: 'error',
                        content: 'Failed to Create Quiz',
                        duration: 5,
                    })
                }
            }
        }
    }
    const handleLanguageChange = (id) => {
        setLanguageOption(id)
    }

    const isQuestionsValid = () => {
        return (
            questions.question !== '' &&
            questions.options.every((option) => option !== '')
        )
    }

    const generateAdditionalProps = (arr) => {
        const obj = {}
        arr?.forEach((element, index) => {
            const key = `additionalProp${index + 1}`
            obj[key] = element
        })
        return obj
    }

    const onFinishFailed = (errorInfo) => {
        errorInfo?.errorFields?.length && errorInfo.errorFields.forEach(ele => {
            if(ele?.name == 'title' || ele?.name == 'category') {
                window.scrollTo(0, 0);
            }
        })
        setValidateQuestions(true)
    }

    const getImagePath = async (path) => {
        setIsImageUploading(true)
        setIsLoading(true)
        const languageName =
            languageOption === 1
                ? 'English'
                : languageOption === 2
                ? 'Telugu'
                : languageOption === 3
                ? 'Hindi'
                : 'Tamil'

        const cat = categories
            ?.map((ele) => {
                if (
                    ele.language_name === languageName &&
                    ele.category_id === form.getFieldValue('category')
                ) {
                    return ele.category_name
                }
                return undefined
            })
            .filter((categoryName) => categoryName !== undefined)?.[0]

        try {
            const pathUriArr = await getImageUrl(path, "quiz", accessToken,languageOption)
            setIsLoading(false)
            if (pathUriArr?.data) {
                setImagePath(
                    pathUriArr?.data
                )
                setImagePathUrl(
                    pathUriArr?.data
                )
                setIsImageUploading(false)
            }
        } catch (err) {
            setIsLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'failed to upload Image',
                duration: 5,
            })
        }
    }

    const onRemoveButton = (index) => {
        const newOptions = [...questions.options]
        newOptions.splice(index, 1)
        setQuestions({ ...questions, options: [...newOptions] })
    }

    const onInputChange = (value, index) => {
        const newOptions = [...questions.options]
        newOptions[index] = value
        setQuestions({ ...questions, options: [...newOptions] })
    }

    const handlePublish = () => {
        form.validateFields()?.then(
            (res) => {
                onFinish(res)
            },
            (error) => {
                error?.errorFields?.length && error.errorFields.forEach(ele => {
                    if(ele?.name == 'title' || ele?.name == 'category' || ele?.name == 'image') {
                        window.scrollTo(0, 0);
                    }
                })
                console.log(error)
            }
        )
    }

    return (
        <div id="form" style={{ width: '100%' }}>
            {contextHolder}
            <Form
                form={form}
                name="basic"
                colon={false}
                scrollToFirstError
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                initialValues={initialFormData}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select language' },
                    ]}
                >
                    <Select
                        placeholder="--Select Language--"
                        onChange={handleLanguageChange}
                    >
                        {languageData.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Category"
                    name="category"
                    rules={[{ required: true, message: 'Please enter value' }]}
                >
                    <Select placeholder="--Select Category--">
                        {categories
                            ?.filter(
                                (ct) =>
                                    ct?.language_name ===
                                    languageData?.filter(
                                        (l) => l.language_id === languageOption
                                    )?.[0]?.language_name && ct?.type_content?.includes(currentTableType)
                            )
                            ?.map((option) => (
                                <Select.Option
                                    key={option.category_name}
                                    value={option.category_id}
                                >
                                    {option.category_name}
                                </Select.Option>
                            ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    name="expiryHours"
                    label="Expiry Hours"
                    rules={[{ required: true }]}
                >
                    <Select style={{ width: 200 }}>
                        {expiryHoursOptions.map((option, index) => (
                            <Select.Option key={index} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Image"
                    name="image"
                    required
                    rules={[
                        { required: true, message: 'Please select an Image' },
                    ]}
                >
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <ImageUploader getImagePath={getImagePath} />
                        <div>{isImageUploading && <LoadingOutlined />}</div>
                    </div>
                </Form.Item>
                
                <div
                    style={{
                        width: '100%',
                        backgroundColor: 'white',
                        border: '1px solid #e2e2e2',
                    }}
                >
                    <div style={{ padding: '10px' }}>
                        <h5>Question</h5>
                    </div>
                    <div style={{ padding: '10px' }}>
                        <Input.TextArea
                            maxLength={70}
                            style={{ minHeight: '5rem' }}
                            className={
                                (validateQuestions &&
                                    questions?.question === '') ||
                                    questions.question?.length == 70
                                    ? 'error'
                                    : null
                            }
                            value={questions.question}
                            onChange={(e) => {
                                setQuestions({
                                    ...questions,
                                    question: e.target.value,
                                });
                                if(questions?.question?.length) {
                                    setIsLoading(false)
                                }
                            }}
                            placeholder="Enter question here..."
                        />
                        <span className="description-subtext">
                            <span
                                style={{
                                    color:
                                        questions.question?.length === 70
                                            ? 'red'
                                            : null,
                                }}
                            >
                                {' '}
                                {70 - (questions.question?.length ?? 0)}{' '}
                                characters
                            </span>
                        </span>
                        {validateQuestions && questions.question === '' && (
                            <span style={{ color: 'red' }}>
                                Please enter Question
                            </span>
                        )}
                        <div style={{ padding: '10px' }}>
                            <Radio.Group
                                value={selectedAnswerIndex}
                                onChange={(e) =>
                                    setSelectedAnswerIndex(e?.target?.value)
                                }
                            >
                                {questions?.options?.map((value, index) => (
                                    <div key={index} style={{ margin: '10px' }}>
                                        <div style={{ display: 'flex' }}>
                                            <Radio value={index}></Radio>
                                            <Input
                                                className={
                                                    validateQuestions &&
                                                        value === ''
                                                        ? 'error'
                                                        : null
                                                }
                                                value={value}
                                                placeholder="option"
                                                onChange={(e) => {
                                                        onInputChange(
                                                            e.target.value,
                                                            index
                                                        )
                                                        if(e?.target?.value?.length) {
                                                            setIsLoading(false)
                                                        }
                                                    }
                                                }
                                            />
                                            {questions.options.length > 2 && (
                                                <Button
                                                    onClick={() =>
                                                        onRemoveButton(index)
                                                    }
                                                    style={{
                                                        marginLeft: '10px',
                                                    }}
                                                    icon={<CloseOutlined />}
                                                ></Button>
                                            )}
                                        </div>
                                        {validateQuestions && value === '' && (
                                            <span style={{ color: 'red' }}>
                                                Please enter Option
                                            </span>
                                        )}
                                    </div>
                                ))}
                            </Radio.Group>
                            {questions.options.length < 4 && (
                                <div
                                    onClick={() => {
                                        setQuestions({
                                            ...questions,
                                            options: [...questions.options, ''],
                                        })
                                    }}
                                    style={{ textAlign: 'center' }}
                                >
                                    <Button
                                        shape="circle"
                                        style={{ marginLeft: '10px' }}
                                        icon={<PlusOutlined />}
                                    ></Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {/* {isPublish(userPermissions, 'Content', 'Quiz') && languageOption !== 2 && (
                    <Form.Item
                        label="Proof read"
                        name="proofRead"
                        rules={[
                            {
                                required: true,
                                message: 'Please select an option',
                            },
                        ]}
                        className="custom-form-item"
                    >
                        <Radio.Group>
                            <Radio value={true}> Yes </Radio>
                            <Radio value={false}> No </Radio>
                        </Radio.Group>
                    </Form.Item>
                )} */}

                <Form.Item
                    label="Publish"
                    name="publish"
                    rules={[{ required: true }]}
                    style={{ marginTop: '1rem' }}
                >
                    <DatePicker
                        showTime
                        format="DD/MM/YYYY h:mm A"
                        disabledDate={(currentDate) => currentDate < new Date()}
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ span: 48 }}>
                    {isSubmitAndDraft(userPermissions, 'Content', 'Quiz') && (
                        <Button
                            className="bg-color"
                            type="primary"
                            onClick={() => handleSave('draft')}
                            disabled={isLoading || (contentStatus == 'approved')}
                        >
                            Draft
                        </Button>
                    )}
                    {isSubmitAndDraft(userPermissions, 'Content', 'Quiz') && (
                        <Button
                            className="bg-color submit-buttons"
                            type="primary"
                            onClick={() => handleSave('submit')}
                            disabled={isLoading || (contentStatus == 'approved')}
                        >
                            Submit
                        </Button>
                    )}
                    {isPublish(userPermissions, 'Content', 'Quiz') && (
                        <Button
                            className="bg-color"
                            type="primary"
                            onClick={() => handlePublish()}
                            disabled={isLoading}
                        >
                            Publish
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}
export default AddQuizForm
