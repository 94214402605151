import { useEffect, useState } from 'react'
import SmartphonePreview from '../SmartphonePreview/SmartphonePreview'
import { useLocation, useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { getHtmlContentByContentId } from '../../AadhanApi'
import AddHtmlContentForm from './AddHtmlContentForm/AddHtmlContentForm'
import { Button } from 'antd'
import { CloseSquareFilled } from '@ant-design/icons'

const AddHtmlContent = () => {
    const location = useLocation()
    const [hashTagId, setHashTagId] = useState()
    const [sourceId, setSourceId] = useState()
    const [imagePath, setImagePath] = useState('')
    const [code, setCode] = useState('')
    const [contentStatus, setContentStatus] = useState('')
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('contentId')
    const mode = searchparam.get('mode')
    const languageData = useSelector((state) => state.app.languageData)
    const locationsData = useSelector((state) => state.app.locations)
    const categories = useSelector((state) => state.app.categories)
    const accessToken = useSelector((state) => state.app.accessToken)
    const employeeId = useSelector((state) => state.app.employeeId)
    const [weburl, setWebUrl] = useState()
    const mediaTypes = ['LiveScore', 'Card', 'video', 'InhouseAd']
    const [mediaType, setMediaType] = useState(mediaTypes[0])
    const subTypes = ['cricket', 'election', 'exam Results']
    const [subType, setSubType] = useState('')
    const navigate = useNavigate()
    const [initialFormData, setInitialFormData] = useState({
        language: languageData?.[0]?.language_id,
        breakingNews: false,
        relevancy: 'veryImportant',
        disturbingVisuals: 'no',
        media: 'video',
        publish: dayjs(),
        locationSpecific: 'no',
        proofRead: '',
        expiryHours: 'Never',
    })

    const updateFormData = async (contentId, accessToken) => {
        const response = await getHtmlContentByContentId(contentId, accessToken)
        if (response?.data) {
            setImagePath(response?.data?.[0]?.image_url)
            setCode(response?.data?.[0].code)
            setHashTagId(response?.data?.[0]?.hashtag_id)
            setSourceId(response?.data?.[0]?.source_id)
            setWebUrl(response?.data?.[0]?.web_url)
            setMediaType(response?.data?.[0]?.media_type)
            setSubType(response?.data?.[0]?.html_type)
            setContentStatus(response?.data?.[0]?.content_status)
            setInitialFormData({
                ...initialFormData,
                language: response?.data?.[0]?.language_id,
                breakingNews: response?.data?.[0]?.breaking_news,
                relevancy: response?.data?.[0]?.relevancy,
                category: response?.data?.[0]?.category_id,
                disturbingVisuals: response?.data?.[0]?.disturbing_visuals,
                publish: dayjs(
                    dayjs(response?.data?.[0]?.published_date).format(
                        'YYYY-MM-DD HH:mm:ss'
                    )
                ),
                keywords:response?.data?.[0]?.keywords,
                locationSpecific:
                    response?.data?.[0]?.state !== 'null' &&  response?.data?.[0]?.state !=="" &&  response?.data?.[0]?.state !== undefined  ? 'yes' : 'no',
                expiryHours:
                    response?.data?.[0]?.expiry_hours == 0
                        ? 'Never'
                        : response?.data?.[0]?.expiry_hours,
                title: response?.data?.[0]?.title,
                description: response?.data?.[0]?.description,
                shortDescription: response?.data?.[0]?.description,
                image: response?.data?.[0]?.image_url,
                district: response?.data?.[0]?.district?.split(','),
                web_url: response?.data?.[0]?.web_url,
                media_type: response?.data?.[0]?.media_type,
                youtubeLink:
                    response?.data?.[0]?.yt_video_url === 'null'
                        ? ''
                        : response?.data?.[0]?.yt_video_url,
                source: response?.data?.[0]?.source_id,
                sourceLink: response?.data?.[0]?.source_url,
                proofRead:
                    response?.data?.[0]?.content_status !== 'draft' &&
                    response?.data?.[0]?.content_status !== 'submit'
                        ? response?.data?.[0]?.is_proof_read
                        : '',
                state: response?.data?.[0]?.state?.split(','),
                uploadedBy: response?.data?.[0]?.uploaded_by,
                uploadedDate: response?.data?.[0]?.uploaded_date,
            })
        }
    }

    useEffect(() => {
        if (contentId) {
            updateFormData(contentId, accessToken)
        }
    }, [contentId])

    return (
        <div style={{padding:"0 1rem"}}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                  <h3>
                {mode === 'edit' ? 'Edit Html Content' : 'Add Html Content'}
            </h3>
                <Button
                    onClick={() => navigate(-1)}
                    ghost={true}
                    style={{ border: 'none' }}
                    type="primary"
                    icon={
                        <CloseSquareFilled
                            style={{ color: 'LightCoral', fontSize: '1.8rem' }}
                        />
                    }
                ></Button>
            </div>
            <hr></hr>
            
            <div className="add-ad-container row">
                <div className="col-md">
                    <AddHtmlContentForm
                        setImagePath={setImagePath}
                        initialFormData={initialFormData}
                        languageData={languageData}
                        categories={categories}
                        accessToken={accessToken}
                        mode={mode}
                        setWebUrl={setWebUrl}
                        contentId={contentId}
                        hashTagId={hashTagId}
                        sourceId={sourceId}
                        setHashTagId={setHashTagId}
                        setSourceId={setSourceId}
                        locationsData={locationsData}
                        employeeId={employeeId}
                        code={code}
                        mediaTypes={mediaTypes}
                        setMediaType={setMediaType}
                        mediaType={mediaType}
                        subTypes={subTypes}
                        setSubType={setSubType}
                        subType={subType}
                        contentStatus={contentStatus}
                    />
                </div>
                <div className="col-md">
                    <SmartphonePreview weburl={weburl} image={imagePath} />
                </div>
            </div>
        </div>
    )
}

export default AddHtmlContent
