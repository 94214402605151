import React, { useEffect, useState } from 'react'
import {
    languageContent,
    TEXT_START_X,
    TEXT_END_X,
    LINE_HEIGHT,
} from './Constants'
import PanchangamTemplate from '../../assets/Panchangam/panchangam.jpeg'

function PanchangamCanvas({
    selectedLanguage,
    selectedMonth,
    selectedDay,
    inputValues,
}) {
    const [canvasRef, setCanvasRef] = useState(null)
    const [ctx, setCtx] = useState(null)
    const [fontsLoaded, setFontsLoaded] = useState(false)

    useEffect(() => {
        const initializeCanvas = async () => {
            try {
                const fontFamilies = Object.values(languageContent)
                    .map((content) => [
                        `${content.fontProperties.heading.fontSize} ${content.fontProperties.heading.fontFamily}`,
                        `${content.fontProperties.labels.fontSize} ${content.fontProperties.labels.fontFamily}`,
                        `${content.fontProperties.values.fontSize} ${content.fontProperties.values.fontFamily}`,
                    ])
                    .flat()

                await Promise.all(
                    fontFamilies.map((font) => document.fonts.load(font))
                )

                setFontsLoaded(true)

                if (canvasRef) {
                    const context = canvasRef.getContext('2d')
                    setCtx(context)

                    const img = new Image()
                    img.src = PanchangamTemplate

                    img.onload = () => {
                        canvasRef.width = 288
                        canvasRef.height = 624
                        context.drawImage(
                            img,
                            0,
                            0,
                            canvasRef.width,
                            canvasRef.height
                        )
                        renderCanvasContent()
                    }
                }
            } catch (error) {
                console.error('Error loading fonts:', error)
            }
        }

        initializeCanvas()
    }, [])

    useEffect(() => {
        if (canvasRef && fontsLoaded) {
            const context = canvasRef.getContext('2d')
            setCtx(context)
            renderCanvasContent()
        }
    }, [
        canvasRef,
        selectedLanguage,
        selectedMonth,
        selectedDay,
        inputValues,
        fontsLoaded,
    ])

    const renderCanvasContent = () => {
        if (!ctx || !canvasRef || !fontsLoaded) return

        const img = new Image()
        img.src = PanchangamTemplate
        img.onload = () => {
            canvasRef.width = 288
            canvasRef.height = 624
            ctx.drawImage(img, 0, 0, canvasRef.width, canvasRef.height)
            renderInitialText()
            renderInputValues()
        }
    }

    const renderInitialText = () => {
        if (!ctx) return

        const content = languageContent[selectedLanguage]

        // Heading
        ctx.fillStyle = '#FFD700'
        ctx.font = `bold ${content.fontProperties.heading.fontSize} ${content.fontProperties.heading.fontFamily}`
        if (selectedLanguage === 1) {
            ctx.fillText(content.heading, 22, 90)
        } else if (selectedLanguage === 2) {
            ctx.fillText(content.heading, 56, 95)
        } else if (selectedLanguage === 3) {
            ctx.fillText(content.heading, 70, 95)
        } else {
            ctx.fillText(content.heading, 26, 90)
        }

        // Date
        const formattedDate = `${String(selectedDay).padStart(2, '0')}-${String(
            selectedMonth + 1
        ).padStart(2, '0')}-24`
        ctx.fillStyle = '#FFFFFF'
        ctx.font = `15px Sree Krushnadevaraya`
        ctx.fillText(formattedDate, 116, 116)

        // Labels
        ctx.fillStyle = '#FFD700'
        content.labels.forEach((label, index) => {
            const y = 160 + index * 30
            ctx.font = `bold ${content.fontProperties.labels.fontSize} ${content.fontProperties.labels.fontFamily}`
            ctx.fillText(label, 10, y)
        })
    }

    const renderInputValues = () => {
        if (!ctx || !canvasRef) return

        const content = languageContent[selectedLanguage]

        // Create a temporary canvas to draw our text
        const tempCanvas = document.createElement('canvas')
        tempCanvas.width = canvasRef.width
        tempCanvas.height = canvasRef.height
        const tempCtx = tempCanvas.getContext('2d')

        // Render heading
        tempCtx.fillStyle = '#FFD700'
        const headingFontSize = content.fontProperties.heading.fontSize
        tempCtx.font = `bold ${headingFontSize} ${content.fontProperties.heading.fontFamily}`

        let headingX, headingY
        if (selectedLanguage === 1) {
            headingX = 22
            headingY = 90
        } else if (selectedLanguage === 2) {
            headingX = 56
            headingY = 95
        } else if (selectedLanguage === 3) {
            headingX = 70
            headingY = 95
        } else {
            headingX = 26
            headingY = 90
        }
        tempCtx.fillText(content.heading, headingX, headingY)

        // Render date
        const formattedDate = `${String(selectedDay).padStart(2, '0')}-${String(
            selectedMonth + 1
        ).padStart(2, '0')}-24`
        tempCtx.fillStyle = '#FFFFFF'
        tempCtx.font = '15px Sree Krushnadevaraya'
        tempCtx.fillText(formattedDate, 116, 116)

        const FIXED_GAP_BETWEEN_ENTRIES = 30 // Adjust this value as needed

        let currentY = 160 // Starting Y position

        Object.entries(inputValues).forEach(([index, value]) => {
            const labelY = currentY

            // Render label
            tempCtx.fillStyle = '#FFD700'
            tempCtx.font = `bold ${content.fontProperties.labels.fontSize} ${content.fontProperties.labels.fontFamily}`
            tempCtx.fillText(content.labels[index], 10, labelY)

            // Render input value
            tempCtx.fillStyle = '#FFFFFF'
            tempCtx.font = `${content.fontProperties.values.fontSize} ${content.fontProperties.values.fontFamily}`

            // Split text by manual line breaks
            const manualLines = value.split('/')
            let lastLineY = currentY

            manualLines.forEach((manualLine, lineIndex) => {
                const words = manualLine.trim().split(' ')
                let line = ''
                let testLine = ''
                let lineCount = 0

                words.forEach((word) => {
                    testLine = line + word + ' '
                    const metrics = tempCtx.measureText(testLine)
                    if (
                        metrics.width > TEXT_END_X - TEXT_START_X &&
                        line !== ''
                    ) {
                        tempCtx.fillText(
                            line,
                            TEXT_START_X,
                            currentY + lineCount * LINE_HEIGHT
                        )
                        lastLineY = currentY + lineCount * LINE_HEIGHT
                        line = word + ' '
                        lineCount++
                    } else {
                        line = testLine
                    }
                })

                if (line !== '') {
                    tempCtx.fillText(
                        line,
                        TEXT_START_X,
                        currentY + lineCount * LINE_HEIGHT
                    )
                    lastLineY = currentY + lineCount * LINE_HEIGHT
                }

                if (lineIndex < manualLines.length - 1) {
                    currentY += LINE_HEIGHT
                    lastLineY = currentY
                }
            })

            // Update currentY for the next label, using the last line's Y position
            currentY = lastLineY + FIXED_GAP_BETWEEN_ENTRIES
        })

        // Clear the main canvas (only the area where we draw text)
        ctx.clearRect(0, 150, canvasRef.width, canvasRef.height - 150)

        // Redraw the original image
        const img = new Image()
        img.src = PanchangamTemplate
        ctx.drawImage(img, 0, 0, canvasRef.width, canvasRef.height)

        // Overlay our text from the temporary canvas
        ctx.drawImage(tempCanvas, 0, 0)
    }

    return (
        <canvas
            ref={(canvas) => {
                setCanvasRef(canvas)
                if (canvas && fontsLoaded) {
                    const context = canvas.getContext('2d')
                    setCtx(context)
                    renderCanvasContent()
                }
            }}
            style={{
                width: '18rem',
                height: '39rem',
            }}
        />
    )
}

export default PanchangamCanvas
