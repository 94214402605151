import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router'
import { Card, Row, Col, message, Button, Spin } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import HoroscopeModal from './HoroscopeModal'
import { useSelector } from 'react-redux'
// import { useNavigate } from 'react-router-dom'
import './HoroscopeMain.scss'
import DropdownsForGrid from './DropdownsForGrid'
import HoroscopeCanvas from './HoroscopeCanvas'
import {
    getHoroscopeCards,
    deleteHoroscopeCard,
    createCard,
    updateCard,
} from '../../AadhanApi'
import dayjs from 'dayjs'

const { Meta } = Card

function GridHoroPage() {
    const location = useLocation()
    const {
        language: initialLanguage,
        month: initialMonth,
        day: initialDay,
        uploadedDates: initialUploadedDates,
        zodiacData: zodiacData,
    } = location.state || {}
    const languageData = useSelector((state) => state.app.languageData)
    const [language, setLanguage] = useState(
        initialLanguage || languageData?.[0]?.language_id
    )
    const [month, setMonth] = useState(initialMonth || null)
    const [day, setDay] = useState(initialDay || null)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [currentHoroscope, setCurrentHoroscope] = useState('')
    const [modalText, setModalText] = useState('')
    const [savedImages, setSavedImages] = useState([])
    const [currentHoroId, setCurrentHoroId] = useState()
    const [fullDescText, setFullDescText] = useState('')
    const [uploadedDates, setUploadedDates] = useState(
        initialUploadedDates || {}
    )
    const [cardDate, setCardDate] = useState()
    const [isOverlayVisible, setIsOverlayVisible] = useState(false)
    const [entryId, setEntryId] = useState(null)
    const [isSubmitted, setIsSubmitted] = useState(false)

    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/home/daily-horoscope', {
            state: {
                gridLanguage: language,
                gridMonth: month,
                gridDay: day,
            },
        })
    }

    useEffect(() => {
        fetchHoroscopes()
        checkSubmissionStatus()
    }, [language, month, day])

    const checkSubmissionStatus = () => {
        const submissionKey = `${language}-${month}-${day}`
        const status = localStorage.getItem(submissionKey)
        setIsSubmitted(status === 'submitted')
        if (status === 'submitted') {
            const storedEntryId = localStorage.getItem(
                `${submissionKey}-entryId`
            )
            setEntryId(storedEntryId)
        }
    }
    const resetSubmissionState = () => {
        const submissionKey = `${language}-${month}-${day}`
        localStorage.removeItem(submissionKey)
        localStorage.removeItem(`${submissionKey}-entryId`)
        setIsSubmitted(false)
        setEntryId(null)
    }

    const fetchHoroscopes = async () => {
        function formatDateForBackend(monthName, day) {
            const currentYear = new Date().getFullYear()
            const monthMap = {
                january: 0,
                february: 1,
                march: 2,
                april: 3,
                may: 4,
                june: 5,
                july: 6,
                august: 7,
                september: 8,
                october: 9,
                november: 10,
                december: 11,
            }

            let monthIndex

            if (typeof monthName === 'string') {
                const lowercaseMonth = monthName.toLowerCase()
                monthIndex = monthMap[lowercaseMonth]
            } else if (typeof monthName === 'number') {
                monthIndex = monthName
            } else {
                return 'Invalid Date'
            }

            if (monthIndex === undefined || monthIndex < 0 || monthIndex > 11) {
                return 'Invalid Date'
            }

            const parsedDay = parseInt(day, 10)
            if (isNaN(parsedDay) || parsedDay < 1 || parsedDay > 31) {
                return 'Invalid Date'
            }

            const formattedDate = dayjs()
                .year(currentYear)
                .month(monthIndex)
                .date(parsedDay)
                .format('YYYY-MM-DD')

            return formattedDate
        }
        const monthValue = month
        const dayValue = day
        const result = formatDateForBackend(monthValue, dayValue)
        try {
            const token =
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtYW5pZGVlcEBhYWRoYW4uaW4iLCJzY29wZXMiOiJBZG1pbmlzdHJhdG9yIiwiZXhwIjoxNzI4ODY1ODA2fQ.55ikRerD1cTj0LOwYt4P6rRzMiuRA5NnC7K_4uNWGvA'
            const response = await getHoroscopeCards(token, language, result)
            if (response.data.success && response.data.horoscopes.length > 0) {
                const fetchedHoroscopes = response.data.horoscopes
                const newUploadedDates = {}
                setSavedImages(
                    fetchedHoroscopes.map((horoscope) => {
                        newUploadedDates[horoscope.zodiac_sign.name] =
                            horoscope.uploaded_date
                        return {
                            id: horoscope.code,
                            title: horoscope.zodiac_sign.name,
                            src: horoscope.zodiac_sign.url,
                            fullDesc: horoscope.zodiac_sign.full_desc,
                            shortDesc: horoscope.zodiac_sign.short_desc,
                            date: horoscope.date,
                            uploadedDate: horoscope.uploaded_date,
                        }
                    })
                )
                setUploadedDates(newUploadedDates)
            } else {
                setSavedImages([])
            }
        } catch (error) {
            console.error('Failed to fetch horoscopes:', error)
            setSavedImages([])
        }
    }

    const showEditModal = (image) => {
        try {
            setCurrentHoroId(image.id)
            setCurrentHoroscope(image.title)
            setModalText(image.shortDesc)
            setFullDescText(image.fullDesc)
            setCardDate(image.date)
            setIsModalOpen(true)
        } catch (error) {
            console.error('Error opening edit modal:', error)
            message.error('Failed to open edit modal. Please try again.')
        }
    }

    const date = dayjs(cardDate)
    const monthName = date.format('MMMM')
    const dayName = date.format('DD')

    const handleDeleteAll = async (imageId) => {
        setIsOverlayVisible(true)
        try {
            const token =
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtYW5pZGVlcEBhYWRoYW4uaW4iLCJzY29wZXMiOiJBZG1pbmlzdHJhdG9yIiwiZXhwIjoxNzI4ODY1ODA2fQ.55ikRerD1cTj0LOwYt4P6rRzMiuRA5NnC7K_4uNWGvA'
            for (const image of savedImages) {
                await deleteHoroscopeCard(image.id, token)
            }
            console.log('Horoscope card deleted')
            message.success('All horoscope cards deleted successfully')
            resetSubmissionState()
        } catch (error) {
            console.error('Failed to delete horoscope card:', error)
            message.error(
                'Failed to delete all horoscope cards. Please try again.'
            )
        }
        fetchHoroscopes()
        setIsOverlayVisible(false)
    }

    const LoadingOverlay = () => (
        <div className="loading-overlay">
            <div className="loading-content">
                <Spin size="large" />
                <p>Deleting Horoscope Cards...</p>
            </div>
        </div>
    )

    const handleImageExport = useCallback((newImage) => {
        fetchHoroscopes()
        setIsModalOpen(false)
    }, [])

    const handleSubmitBtn = async () => {
        const months = {
            january: 0,
            february: 1,
            march: 2,
            april: 3,
            may: 4,
            june: 5,
            july: 6,
            august: 7,
            september: 8,
            october: 9,
            november: 10,
            december: 11,
        }

        const getMonthIndex = (month) => {
            if (!isNaN(month)) {
                return parseInt(month, 10)
            } else {
                return months[month.toLowerCase()]
            }
        }

        const selectedDate = dayjs()
            .year(new Date().getFullYear())
            .month(getMonthIndex(month))
            .date(day)
            .hour(6)
            .minute(0)
            .second(0)
            .millisecond(0)
        const publishedDate = selectedDate.format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        const currentDate = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
        console.log(currentDate)

        // console.log(publishedDate)
        // console.log(currentDate)

        const cardData = {
            title: 'Horoscope Card',
            source_id: 0,
            source_url: '',
            category_id: 144,
            content_type: 'card',
            expiry_hours: 18,
            published_date: publishedDate,
            popular_card: false,
            dynamic_url: '',
            is_active: true,
            content_status: 'approved',
            language_id: language,
            image_urls: savedImages.map((image) => ({
                uid: '',
                lastModified: {},

                lastModifiedDate: '',
                name: '',
                size: null,
                type: 'image/jpeg',
                percent: null,
                originFileObj: {},
                status: '',
                response: image.src,
                thumbUrl: '',
            })),

            thumbnailimage_url: '',
            code: '',
            state: '',
            district: '',
            city: '',
            keywords: '',
            uploaded_by_name: 'Aadhan Bot',
            proof_read_by_name: 'Aadhan Bot',
            category_name: 'భక్తి',
            proof_read_by: '383',
            proof_read_date: publishedDate,
            is_proof_read: true,
            uploaded_by: '437',
            updated_by: 0,
            uploaded_date: currentDate,
        }

        try {
            const token =
                'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJtYW5pZGVlcEBhYWRoYW4uaW4iLCJzY29wZXMiOiJBZG1pbmlzdHJhdG9yIiwiZXhwIjoxNzI4ODY1ODA2fQ.55ikRerD1cTj0LOwYt4P6rRzMiuRA5NnC7K_4uNWGvA'
            let response

            if (isSubmitted && entryId) {
                response = await updateCard(cardData, entryId, token)
                if (response.data === 'Card updated successfully') {
                    message.success('Card updated successfully!')
                } else {
                    message.error('Failed to update card. Please try again.')
                }
            } else {
                response = await createCard(cardData, token)
                if (response.data.message === 'Card inserted successfully') {
                    setEntryId(response.data.entry_id)
                    message.success('Card saved successfully!')
                } else {
                    message.error('Failed to save card. Please try again.')
                }
            }

            if (
                response.data === 'Card updated successfully' ||
                response.data.message === 'Card inserted successfully'
            ) {
                const submissionKey = `${language}-${month}-${day}`
                localStorage.setItem(submissionKey, 'submitted')
                localStorage.setItem(
                    `${submissionKey}-entryId`,
                    response.data.entry_id || entryId
                )
                setIsSubmitted(true)
                setEntryId(response.data.entry_id || entryId)
            }
        } catch (error) {
            console.error('Error saving or updating card:', error)
            message.error('An error occurred while saving/updating the card.')
        }
    }

    return (
        <div className="container_main">
            {isOverlayVisible && <LoadingOverlay />}
            <div className="arrowandtitle">
                <svg
                    className="svg-arrow"
                    height="35px"
                    id="Layer_1"
                    style={{
                        enableBackground: 'new 0 0 512 512',
                        marginRight: '0px',
                        marginLeft: '-8px',
                        cursor: 'pointer',
                    }}
                    onClick={handleClick}
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="35px"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                    <polygon
                        points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 "
                        fill="black"
                    />
                </svg>
                <h1 className="horo-title">Horoscope Carousel</h1>
            </div>
            <hr className="line-grid" aria-hidden="true"></hr>
            <div className="gridDropdownsAndSumbitBtn">
                <DropdownsForGrid
                    language={language}
                    setLanguage={setLanguage}
                    month={month}
                    setMonth={setMonth}
                    day={day}
                    setDay={setDay}
                />
                <div>
                    <Button
                        type="primary"
                        className="deleteAllBtn"
                        onClick={handleDeleteAll}
                    >
                        Delete Carousel
                    </Button>
                    <Button
                        type="primary"
                        className="submitBtn"
                        onClick={handleSubmitBtn}
                    >
                        {isSubmitted && entryId
                            ? 'Update Carousel'
                            : 'Save to Cards'}
                    </Button>
                </div>
            </div>
            <Row gutter={[16, 16]} className="grid-content">
                {savedImages.map((image) => (
                    <Col xs={24} sm={12} md={8} lg={6} key={image.id}>
                        <Card
                            hoverable
                            className="card-el"
                            cover={
                                <img
                                    key={image.id}
                                    alt={image.title}
                                    src={image.src}
                                />
                            }
                        >
                            <div className="titleAndEdit">
                                <Meta
                                    title={image.title}
                                    className="titleInGrid"
                                />
                                {image.title !== 'Cover Page' && (
                                    <EditOutlined
                                        key="edit"
                                        onClick={() => showEditModal(image)}
                                    />
                                )}
                            </div>
                        </Card>
                    </Col>
                ))}
            </Row>
            <HoroscopeModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                currentHoroscope={currentHoroscope}
                modalText={modalText}
                setModalText={setModalText}
                onImageExport={handleImageExport}
                currentHoroId={currentHoroId}
                fullDescText={fullDescText}
                uploadedDate={uploadedDates[currentHoroscope]}
                fetchHoroscopes={fetchHoroscopes}
                month={month}
                day={day}
                cardDate={cardDate}
                language={language}
                zodiacData={zodiacData}
            >
                <HoroscopeCanvas
                    modalText={modalText}
                    month={monthName}
                    day={dayName}
                    currentHoroscope={currentHoroscope}
                    onImageExport={handleImageExport}
                    zodiacData={zodiacData}
                />
            </HoroscopeModal>
        </div>
    )
}

export default GridHoroPage
