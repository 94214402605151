import React, { useState } from 'react';
import { Upload, Button, Spin, message, Card, Progress, Typography } from 'antd';
import { UploadOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { getVideoUrl } from '../../../AadhanApi';

const { Text } = Typography;

const ShortNewsVideoUpload = ({ setVideoPath, videoPath, accessToken }) => {
    const [isVideoUploading, setIsVideoUploading] = useState(false);
    const [uploadPercent, setUploadPercent] = useState(0);

    const beforeUpload = (file) => {
        const isMp4 = file.type === 'video/mp4';
        const isLt50MB = file.size / 1024 / 1024 <= 50;

        if (!isMp4) {
            message.error('You can only upload MP4 video files!');
        }
        if (!isLt50MB) {
            message.error('Video must be smaller than 50MB!');
        }

        return isMp4 && isLt50MB;
    };

    const handleVideoUpload = async ({ file, onProgress }) => {
        if (beforeUpload(file)) {
            setIsVideoUploading(true);
            setUploadPercent(0);
            try {
                const pathUri = await getVideoUrl(file, accessToken, (event) => {
                    console.log((event.loaded / event.total) * 100)
                    if (event.lengthComputable) {
                        setUploadPercent(Math.round((event.loaded / event.total) * 100));
                        if (onProgress) {
                            onProgress({ percent: (event.loaded / event.total) * 100 });
                        }
                    }
                });

                if (pathUri?.data) {
                    setVideoPath({ stream_url: pathUri?.data, yt_url: "" });
                    message.success('Video uploaded successfully!');
                }
            } catch (error) {
                message.error('Error uploading video!');
                console.error('Error uploading video:', error);
            } finally {
                setIsVideoUploading(false);
                setUploadPercent(100);
            }
        }
    };

    return (
        <Card
            title="Upload Your Video"
            bordered={true}
            style={{ maxWidth: 500, margin: '0 auto', textAlign: 'center' }}
        >
            <Upload
                accept="video/mp4"
                customRequest={handleVideoUpload}
                showUploadList={false}
                beforeUpload={beforeUpload}
            >
                <Button 
                    icon={<UploadOutlined />} 
                    disabled={isVideoUploading} 
                    style={{ marginBottom: 16 }}
                >
                    {isVideoUploading ? 'Uploading...' : 'Upload Video'}
                </Button>
            </Upload>

            {isVideoUploading && (
                <div style={{ marginBottom: 16 }}>
                    <Spin />
                    {/* <Progress percent={uploadPercent} /> */}
                </div>
                
            )}
            

            

            
        </Card>
    );
};

export default ShortNewsVideoUpload;
