import React, { useEffect, useState } from 'react';
import { Button, Pagination, Spin, DatePicker, Select, Progress } from 'antd';
import Search from 'antd/es/input/Search'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import LazyLoad from 'react-lazyload';
import dayjs from 'dayjs';
import VideoPlayerWithThumbnail from '../VideoLibrary/VideoPlayerWithThumbnail'
import { getFormatedDate } from './service';
import { getOtherVideosFeed } from '../../AadhanApi';
import { useDownloadProgress } from './Utils';
import styles from './styles/main.module.css';

const categoriesData = [
  {
    label: <span>Tags</span>,
    title: 'Tags',
    options: [
      { value: 'entertainment', label: "Entertainment" },
      { value: 'science-tech', label: "Science and Tech" },
      { value: 'national', label: "National" },
      { value: 'sports', label: "Sports" },
      { value: 'politics', label: "Politics" },
    ],
  },
  {
    label: <span>Special Tags</span>,
    title: 'Special Tags',
    options: [
      { value: 'short_videos', label: "Short Videos" },
      { value: 'special', label: "Specials" }
    ],
  }
]

const OtherVideos = () => {
  const [page, setPage] = useState({
    currentPage: 1,
    startPage: 1,
    total: 10000,
    pageSize: 18
  })
  const [search, setSearch] = useState("")
  const [dateObj, setDateObj] = useState(null)
  const [category, setCategory] = useState(null)

  //Pagination
  const onPageChange = (page) => {
    setPage(prev => ({ ...prev, currentPage: page }))
  }

  //Date Picker
  const handleDateOnChange = (date, dateString) => {
    setDateObj(dateString)
    setPage(prev => ({ ...prev, currentPage: 1 }))
  };

  //API
  const otherVideosQuery = useQuery({
    queryKey: ['othervideos', page?.currentPage, search, dateObj, category],
    queryFn: () => getOtherVideosFeed(search, category, dateObj, page?.currentPage),
    // enabled: !!search
  })
  const { data, isLoading, isSuccess } = otherVideosQuery;
  // console.log("data: ", data, data?.total_hits)

  useEffect(() => {
    if (isSuccess) {
      setPage(prev => ({ ...prev, total: (parseInt(data?.total_hits) - 10) }))
    }
  }, [isSuccess, search, data?.total_hits])

  const { progress, downloadFile } = useDownloadProgress()
  return (
    <div className={`${styles.container}`}>
      <div className={`${styles.filters}`}>
        <h4>Hindi</h4>
        <div className={`${styles.sub_filters}`}>
          <Select
            value={category}
            onChange={(e) => { setCategory(e); onPageChange(1) }}
            style={{ minWidth: 180 }}
            placeholder="Select category"
            options={categoriesData}
            allowClear
          />
          {category !== "special" ?
            <DatePicker
              defaultValue={dayjs()}
              onChange={handleDateOnChange}
            /> : null}
          <Search
            style={{ width: '270px', marginLeft: 'auto' }}
            placeholder="Search by title or keywords..."
            enterButton="Search"
            size="medium"
            onChange={(e) => { setSearch(e.target.value); onPageChange(1) }}
            value={search}
            onSearch={(e) => { setSearch(e); onPageChange(1) }}
          ></Search>
        </div>
      </div>
      <hr />
      <div className={`${styles.widgets}`}>
        <div className={`${styles.pagination}`}>
          <Pagination
            current={page?.currentPage}
            total={page?.total}
            pageSize={page?.pageSize}
            onChange={onPageChange}
            className='pagination_class'
            showSizeChanger={false}
          />
        </div>
      </div>
      {isLoading ?
        <div className={`${styles.spinner}`}>
          <Spin indicator={<LoadingOutlined spin />} size="large" />
        </div>
        :
        <div className="video_container">
          {!isLoading &&
            data?.data?.map((e, index) => {
              const formatedDate = getFormatedDate(e?.created_at)
              const originalUrl = e?.thumbnail
              const baseUrl = originalUrl.substring(0, originalUrl.lastIndexOf('/') + 1); // Get everything up to the last slash
              const fileName = originalUrl.substring(originalUrl.lastIndexOf('/') + 1);
              const encodedUrl = baseUrl + encodeURIComponent(fileName);

              const videoUrl = e?.video_preview?.replace("videos_watermark", "videos");
              return (
                <div className={'video_box'} key={index}>
                  <div className="video">
                    <LazyLoad
                      key={index}
                      height={200}
                      offset={100}
                    >
                      <VideoPlayerWithThumbnail
                        videoUrl={videoUrl}
                        thumbnailUrl={encodedUrl}
                      />
                    </LazyLoad>
                  </div>
                  <div className={`${styles.video_title_container}`}>
                    <div className={`${styles.video_title_subCont}`}>
                      <div className={`${styles.video_title}`}>
                        <div href={e.dynamic_url} target="_">
                          {e?.title}
                        </div>
                        <div className={`${styles.flex_end} ${styles.gap}`}>
                          <div
                            className={`${styles.flex_center}`}
                          >
                            <svg width={'16'} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-6">
                              <path d="M12.75 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM7.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM8.25 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM9.75 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM10.5 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM12 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM12.75 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM14.25 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 17.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 15.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5ZM15 12.75a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0ZM16.5 13.5a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z" />
                              <path fillRule="evenodd" d="M6.75 2.25A.75.75 0 0 1 7.5 3v1.5h9V3A.75.75 0 0 1 18 3v1.5h.75a3 3 0 0 1 3 3v11.25a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3V7.5a3 3 0 0 1 3-3H6V3a.75.75 0 0 1 .75-.75Zm13.5 9a1.5 1.5 0 0 0-1.5-1.5H5.25a1.5 1.5 0 0 0-1.5 1.5v7.5a1.5 1.5 0 0 0 1.5 1.5h13.5a1.5 1.5 0 0 0 1.5-1.5v-7.5Z" clipRule="evenodd" />
                            </svg>
                          </div>
                          <div style={{ fontSize: "12px" }}>
                            <span>
                              {formatedDate}
                            </span>
                          </div>
                        </div>
                        <div className={`${styles.tags}`}>
                          {e?.tags?.map((t) => {
                            return (
                              <div key={t?.name}>{t?.name}</div>
                            )
                          })}
                        </div>
                      </div>
                      <Button
                        variant='outlined'
                        color='primary'
                        className={`${styles.download_btn}`}
                        onClick={(event) => {
                          event.preventDefault()
                          event.stopPropagation()
                          downloadFile(videoUrl, index)
                        }}
                        icon={progress[index] !== undefined ?
                          <Progress
                            type="circle"
                            percent={Math.floor(progress[index])}
                            size={18}
                            strokeWidth={20}
                            trailColor="#e6f4ff"
                          /> : <DownloadOutlined />}
                      >
                      </Button>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>

      }
      {data && data?.data?.length === 0 || !data ? (
        <div className="no_data">No Videos</div>
      ) : null}
    </div>
  )
}

export default OtherVideos