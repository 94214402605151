import { Form, Input, Button, message } from 'antd'
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons'
import './Login.scss'
import { useNavigate } from 'react-router'
import { doUserLogin, getEmployeeDetails} from '../../AadhanApi'
import { useEffect, useState } from 'react'
import AadhanIcon from '../../assets/Aadhan.svg'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from '../../store/slice/AppSlice'

const Login = () => {
    const navigate = useNavigate()
    const [formValues, setFormValues] = useState()
    const [isLoginLoading, setisLoginLoading] = useState(false)
    const dispatch = useDispatch()
    const isUserLoggedIn = useSelector((state) => state.app.isUserLoggedIn)
    const [isUserAlreadyLoggedIn, setIsUserAlreadyLoggedIn] =
        useState(isUserLoggedIn)
    const [messageApi, contextHolder] = message.useMessage()

    useEffect(() => {
        const token = localStorage.getItem('ACCESS_TOKEN')
        const permission1 = JSON.parse(localStorage.getItem('USER_PERMISSIONS'))?.Dashboard
        const permission2 = JSON.parse(localStorage.getItem('USER_PERMISSIONS'))?.['Editor Dashboard']
        if (token) {
            setIsUserAlreadyLoggedIn(true)
            const emp_id = localStorage.getItem('ROLE_ID')
            if(emp_id){
                if((emp_id==='1' || emp_id==='8') && permission2?.['Editor Review']?.length!==0 ){
                
                    navigate('/home/editorDashboard')
                }
                else if((emp_id==='21' || emp_id==='3') && permission1?.['Dashboard']?.length!==0 ){
                    navigate('/home/review')
                }
                else{
                    navigate('/home/shortNews')
                }
            }
            else{
                navigate('/home/shortNews')
            }
            
        }
    },[])

    const onFinish = async (values) => {
        setisLoginLoading(true)
        if (values?.email && values?.password) {
            try {

                const result = await doUserLogin(
                    values?.email,
                    values?.password,
                    '',
                    '',
                    ''
                )
                if (result?.data?.access_token) {
                    localStorage.setItem('ACCESS_TOKEN', result?.data?.access_token)
                    localStorage.setItem('EMPLOYEE_ID', result?.data?.employee_id)
                    localStorage.setItem('EMPLOYEE_NAME', result?.data?.employee_name)
                    localStorage.setItem('ROLE_ID', result?.data?.role_id)
                    localStorage.setItem(
                        'USER_PERMISSIONS',
                        // JSON.stringify({...result?.data?.permissions?.[0],Content:{...result?.data?.permissions?.[0].Content,"Add Short News":result?.data?.permissions?.[0]?.Content?.["Short News"]}})
                        JSON.stringify({...result?.data?.permissions?.[0]})
                    )
                    const res = await getEmployeeDetails(result?.data?.employee_id,result?.data?.access_token)
                    localStorage.setItem('is_Available',res?.data?.[0]?.is_availdable===true ? true : '')
                   
                    dispatch(
                        userLogin({
                            isUserLoggedIn: true,
                            accessToken: result?.data?.access_token,
                            // permissions: {...result?.data?.permissions?.[0],Content:{...result?.data?.permissions?.[0].Content,"Add Short News":result?.data?.permissions?.[0]?.Content?.["Short News"]}},
                            permissions: {...result?.data?.permissions?.[0]},
                            employeeId: result?.data?.employee_id,
                            employeeName: result?.data?.employee_name,
                            role_id:result?.data?.role_id
                            
                        })
                    )
                    setFormValues({ ...values, accessToken: result?.data?.access_token })
                    setisLoginLoading(false)
                    if((  result?.data?.role_id===1 ||  result?.data?.role_id===8 ) && result?.data?.permissions?.[0]?.['Editor Dashboard']?.['Editor Dashboard']?.length!==0){
                        navigate('/home/editorDashboard')
                    }
                    else if(( result?.data?.role_id===3 ||  result?.data?.role_id===21) && result?.data?.permissions?.[0]?.Dashboard?.['Dashboard']?.length!==0){
                        navigate('/home/review')
                    }
                    else{
                        navigate('/home/shortNews')
                    }
                   
                } else {
                    setFormValues({ ...values, accessToken: undefined })
                    setisLoginLoading(false)
                    dispatch(
                        userLogin({
                            isUserLoggedIn: false,
                            accessToken: '',
                        })
                    )
                    messageApi.open({
                        type: 'error',
                        content: 'failed',
                        duration: 5,
                    })
                }

            }
            catch (err) {
                console.log('login failed :::', err)
                setisLoginLoading(false)
                messageApi.open({
                    type: 'error',
                    content: err?.response?.data?.detail,
                    duration: 5,
                })
            }

        }
    }
    return (
        <>
            {contextHolder}
            {!isUserAlreadyLoggedIn && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}
                >
                    <div className="login-box">
                        <img
                            style={{ width: '7rem', marginBottom: '2rem' }}
                            src={AadhanIcon}
                            alt="login logo"
                        />
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onFinish}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        <UserOutlined className="site-form-item-icon" />
                                    }
                                    placeholder="Email"
                                />
                            </Form.Item>
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password!',
                                    },
                                ]}
                            >
                                <Input
                                    prefix={
                                        <LockOutlined className="site-form-item-icon" />
                                    }
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                    style={{ textAlign: 'center' }}
                                >
                                    {isLoginLoading ? (
                                        <LoadingOutlined
                                            style={{
                                                fontSize: '1rem',
                                                justifyContent: 'center',
                                                display: 'flex',
                                            }}
                                        />
                                    ) : (
                                        'Log in'
                                    )}
                                </Button>                               
                            </Form.Item>
                            <Form.Item>
                                <a
                                    className="login-form-forgot"
                                    href="/reset-password"
                                    target="_blank"
                                >
                                    Forgot password
                                </a>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            )}
        </>
    )
}

export default Login
