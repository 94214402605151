import { LoadingOutlined } from '@ant-design/icons'
import {
    AutoComplete,
    Button,
    DatePicker,
    Form,
    Input,
    Radio,
    Select,
    Tag,
} from 'antd'

import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router'
import {
    createHtmlContent,
    getHashtagTableData,
    getSourcesTableData,
    updateHtmlContent,
    getImageUrl,
} from '../../../AadhanApi'
import {
    breakingNewsOptions,
    expiryHoursOptions,
} from './AddHtmlContentConstant'
import './AddHtmlContentForm.scss'
import {
    isPublish,
    isSubmitAndDraft,
    TableTypeFromRoutesContent,
} from '../../App.constants'
import { useSelector } from 'react-redux'
import ImageUploader from '../../image-uploader-crop/ImageUploader'
import ReactSelect from 'react-select'
const AddHtmlContentForm = ({
    initialFormData,
    languageData,
    categories,
    accessToken,
    mode,
    contentId,
    hashTagId,
    setHashTagId,
    sourceId,
    setSourceId,
    locationsData,
    setWebUrl,
    employeeId,
    code,
    setMediaType,
    mediaTypes,
    mediaType,
    setSubType,
    subTypes,
    subType,
    setImagePath,
}) => {
    const [form] = Form.useForm()
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const [languageOption, setLanguageOption] = useState(
        languageData?.[0]?.language_id
    )

    const [hashTagOptions, setHashtagOptions] = useState([])
    const [allHashTagOptions, setAllHashtagOptions] = useState([])
    const [sourcesOptions, setSourcesOptions] = useState([])
    const [allSourcesOptions, setAllSourcesOptions] = useState([])
    const [keywords, setKeywords] = useState([])
    const [keywordInput, setKeywordInput] = useState('')
    const [imagePathUrl, setImagePathUrl] = useState(initialFormData?.image_url)
    const [videoPathUrl, setVideoPathUrl] = useState(initialFormData?.video_url)
    const [isImageUploading, setIsImageUploading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [hashData, setHashData] = useState([])
    const [sourceData, setSourceData] = useState([])
    const [hashTagValue, setHashTagValue] = useState('')
    const [sourceValue, setSourceValue] = useState('')
    const navigate = useNavigate()
    const [districts, setDistricts] = useState([])
    const [sourceError, setSourceError] = useState(false)

    const location = useLocation()
    const currentTableType =
        TableTypeFromRoutesContent[location?.pathname] ?? 'shortnews'

    useEffect(() => {
        form.setFieldsValue(initialFormData)
        // const sl =  locationsData.filter((e)=>initialFormData?.state.includes(e.state_id)).map((e)=>e.name)

        form.setFieldValue('subtype', subType)
        setImagePathUrl(form.getFieldValue('image'))
        handleLanguageChange(initialFormData.language)
        if (initialFormData.keywords && initialFormData.keywords !== '') {
            setKeywords(initialFormData.keywords.split(','))
        }
    }, [form, initialFormData])

    useEffect(() => {
        if (hashTagId !== '' && hashTagId !== null) {
            const temp = hashData.filter((e) => e.hashtag_id === hashTagId)

            setHashTagValue({ ...temp?.[0], label: temp?.[0]?.hashtag_name })
        }
    }, [hashTagId, hashData])
    useEffect(() => {
        if (initialFormData?.language || languageData?.[0]?.language_id) {
            updateHashtagSource(
                initialFormData?.language ?? languageData?.[0]?.language_id
            )
        }
    }, [form.language])

    const createRequest = (values, type) => {
        return {
            title: values?.title,
            description: values?.description ?? '',
            source_id:
                sourceData?.filter((h) => h.source_name === values.source)?.[0]
                    ?.source_id ?? 0,
            source_url: values?.sourceLink ?? '',
            category_id: values?.category,
            content_type: 'html',
            web_url: values?.web_url,
            media_type: mediaType,
            html_type: subType,
            image_url: imagePathUrl,
            expiry_hours:
                values?.expiryHours == 'Never' ? 0 : values?.expiryHours,
            published_date: dayjs(values?.publish).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ'
            ),
            state: getIdsOfStates(values.state)?.join(),
            district: values?.district?.join(),
            city: '',
            is_active: true,
            content_status: type,
            code,
            language_id: values?.language,
            hashtag_id: hashTagId,
            keywords: keywords.join(),
            disturbing_visuals: values?.disturbingVisuals,
            breaking_news: values?.breakingNews,
            left_color: '',
            right_color: 's',
            upvote: 0,
            linked_content_id: '',
            relevancy: values?.relevancy,
            position: 0,
            is_skippable: true,
        }
    }

    const getIdsOfStates = (stateNameArr) => {
        return stateNameArr?.map((stateName) => {
            const location = locationsData?.find(
                (location) => location.state_id === stateName
            )

            // Return location.state_id if location is found, otherwise return null or a default value
            return location ? location.state_id : ''
        })
    }

    const handleSave = async (type) => {
        setIsLoading(true)
        try {
            const validation = await form.validateFields([
                'title',
                'language',
                'category',
                'expiryHours',
                'state',
                'web_url',
                'publish',
            ])
            if (
                validation?.errorFields?.length > 0 ||
                (form.getFieldsValue()?.sourceLink?.length && !sourceId)
            ) {
                setIsLoading(false)
                setSourceError(true)
                return
            }
        } catch (e) {
            setIsLoading(false)
            e?.errorFields?.length &&
                e.errorFields.forEach((ele) => {
                    if (
                        ele?.name == 'title' ||
                        ele?.name == 'category' ||
                        ele?.name == 'web_url'
                    ) {
                        window.scrollTo(0, 0)
                    }
                })
            return
        }

        const request = createRequest({ ...form.getFieldsValue() }, type)
        if (mode === 'edit') {
            request.updated_by = employeeId
            request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            request.uploaded_by = initialFormData?.uploadedBy
            request.uploaded_date = initialFormData?.uploadedDate
            const htmlResponse = await updateHtmlContent(
                request,
                contentId,
                accessToken
            )
            if (htmlResponse?.status === 200) {
                setIsLoading(false)
                navigate('/home/html')
            }
        } else if (mode === 'add') {
            request.uploaded_by = employeeId
            request.updated_by = 0
            request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            const htmlResponse = await createHtmlContent(request, accessToken)
            if (htmlResponse?.status === 200) {
                setIsLoading(false)
                navigate('/home/html')
            }
        }
    }

    // useEffect(() => {
    //     if (hashTagId) {
    //         setHashTagValue(
    //             hashData?.filter((h) => h.hashtag_id === hashTagId)?.[0]
    //                 ?.hashtag_name ?? ''
    //         )
    //     }
    // }, [hashData])

    useEffect(() => {
        if (sourceId) {
            setSourceValue(
                sourceData?.filter((h) => h.source_id === sourceId)?.[0]
                    ?.source_name ?? ''
            )
        }
    }, [sourceData])

    useEffect(() => {
        if (
            locationsData &&
            locationsData?.length > 0 &&
            initialFormData?.state
        ) {
            handleStateChangeById(initialFormData?.state)
            let stateArr = []
            initialFormData.state?.forEach((state_id) => {
                const location = locationsData?.find(
                    (location) => location.state_id == state_id
                )
                if (location) stateArr.push(location.state_name)
            })
            form.setFieldValue('state', initialFormData.state)

            handleStateChange(
                initialFormData.state ? initialFormData.state : []
            )
        }
        form.setFieldValue(
            'source',
            sourceData?.filter(
                (h) => initialFormData.source === h.source_id
            )?.[0]?.source_name ?? ''
        )
    }, [locationsData, initialFormData.state, sourceData])

    const onFinish = async (values) => {
        if (form.getFieldsValue()?.sourceLink?.length && !sourceId) {
            setSourceError(true)
            return
        }
        setSourceError(false)
        setIsLoading(true)
        const request = createRequest(values)
        request.content_status = 'approved'
        request.proof_read_by = employeeId
        request.proof_read_date = dayjs(values?.publish).format(
            'YYYY-MM-DDTHH:mm:ss.SSSZ'
        )
        request.is_proof_read =true
        if (mode === 'edit') {
            request.updated_by = employeeId
            request.updated_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            request.uploaded_by = initialFormData?.uploadedBy
            request.uploaded_date = initialFormData?.uploadedDate
            const htmlResponse = await updateHtmlContent(
                request,
                contentId,
                accessToken
            )
            if (htmlResponse?.status === 200) {
                setIsLoading(false)
                navigate('/home/html')
            }
        } else if (mode === 'add') {
            request.uploaded_by = employeeId
            request.updated_by = 0
            request.uploaded_date = dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
            const htmlResponse = await createHtmlContent(request, accessToken)
            if (htmlResponse?.status === 200) {
                setIsLoading(false)
                navigate('/home/html')
            }
        }
    }

    const updateHashtagSource = async (language_id) => {
        if (language_id) {
            const hashtagDataResponse = await getHashtagTableData(
                language_id,
                '',
                accessToken
            )
            const sourceDataResponse = await getSourcesTableData(
                language_id,
                accessToken
            )

            setHashData(hashtagDataResponse?.data)
            setSourceData(sourceDataResponse?.data)
            setHashtagOptions(
                hashtagDataResponse?.data?.map((option) => ({
                    value: option.hashtag_name,
                    label: option.hashtag_name,
                })) ?? []
            )
            setAllHashtagOptions(
                hashtagDataResponse?.data?.map((option) => ({
                    value: option.hashtag_name,
                    label: option.hashtag_name,
                })) ?? []
            )
            setSourcesOptions(
                sourceDataResponse?.data?.map((option) => ({
                    value: option.source_name,
                })) ?? []
            )
            setAllSourcesOptions(
                sourceDataResponse?.data?.map((option) => ({
                    value: option.source_name,
                })) ?? []
            )
        }
    }

    const handleLanguageChange = (id) => {
        updateHashtagSource(id)
        setLanguageOption(id)
    }

    const handleMediaTypeChange = (type) => {
        setMediaType(type)
    }

    const handleSubTypeChange = (type) => {
        setSubType(type)
    }

    const onFinishFailed = (errorInfo) => {
        errorInfo?.errorFields?.length &&
            errorInfo.errorFields.forEach((ele) => {
                if (
                    ele?.name == 'title' ||
                    ele?.name == 'category' ||
                    ele?.name == 'web_url'
                ) {
                    window.scrollTo(0, 0)
                }
            })
        console.log('Failed:', errorInfo)
    }

    const onSelectSource = (val) => {
        setSourceValue(val)
        setSourceId(
            sourceData?.filter((h) => h.source_name === val)?.[0]?.source_id
        )
    }

    const handleClose = (index) => {
        const key = [...keywords]
        key.splice(index, 1)
        setKeywords(key)
    }

    const addKeyword = (keyword) => {
        setKeywords([...keywords, keyword])
    }

    const handleStateChange = (e) => {
        if (e.length === 0) {
            setDistricts([])
            form.setFieldValue('district', [])
            return
        }
        let districts = []
        e?.map((state_name) => {
            const location = locationsData.find(
                (location) => location.state_id === state_name
            )

            if (location) {
                ;(location.districts ? location.districts : []).forEach(
                    (district) => {
                        districts.push(district)
                    }
                )
            }
        })
        setDistricts(districts)
    }

    const handleStateChangeById = (ids) => {
        if (ids.length === 0) {
            setDistricts([])
            form.setFieldValue('district', [])
            return
        }
        let districts = []
        ids?.map((id) => {
            const location = locationsData.find((lk) => lk.state_id == id)
            if (location) {
                Object.values(location.district ?? []).forEach((district) => {
                    districts.push(district)
                })
            }
        })
        setDistricts(districts)
    }

    const getImagePath = async (path) => {
        setIsImageUploading(true)
        setIsLoading(true)
        const pathUriArr = await getImageUrl(path, 'html', accessToken,languageOption)
        if (pathUriArr?.data) {
            setImagePath(
                pathUriArr?.data
            )
            setImagePathUrl(
                pathUriArr?.data
            )
            form.setFieldValue(
                'image',
                pathUriArr?.data
            )
            setIsImageUploading(false)
            setIsLoading(false)
        }
    }

    const onHashTagSelect = (selectedOption) => {
        const selectedHashTagId = hashData?.filter(
            (h) => h.hashtag_name === selectedOption.value
        )?.[0]?.hashtag_id
        setHashTagValue(selectedOption)
        // Assuming you have a setHashTagId function defined somewhere in your component
        if (typeof setHashTagId === 'function') {
            setHashTagId(selectedHashTagId)
        } else {
            console.error('setHashTagId is not a function')
        }
    }

    const onSearch = (inputValue) => {
        if (inputValue) {
            setHashtagOptions(
                allHashTagOptions?.filter(({ value }) =>
                    value?.toLowerCase()?.includes(inputValue?.toLowerCase())
                )
            )
        } else {
            setHashtagOptions(allHashTagOptions)
        }
    }

    return (
        <div id="form" style={{ width: '100%' }}>
            <Form
                form={form}
                name="basic"
                colon={false}
                scrollToFirstError
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 18 }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="Language"
                    name="language"
                    rules={[
                        { required: true, message: 'Please select language' },
                    ]}
                >
                    <Select onChange={handleLanguageChange}>
                        {languageData?.map((option) => (
                            <Select.Option
                                key={option?.language_id}
                                value={option?.language_id}
                            >
                                {option?.language_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    label="Category"
                    name="category"
                    rules={[{ required: true, message: 'Please enter value' }]}
                >
                    <Select placeholder="--Select Category--">
                        {categories
                            ?.filter(
                                (ct) =>
                                    ct?.language_name ===
                                        languageData?.filter(
                                            (l) =>
                                                l.language_id === languageOption
                                        )?.[0]?.language_name &&
                                    ct?.type_content?.includes(currentTableType)
                            )
                            ?.map((option) => {
                                return (
                                    <Select.Option
                                        key={option?.category_name}
                                        value={option?.category_id}
                                    >
                                        {option?.category_name}
                                    </Select.Option>
                                )
                            })}
                    </Select>
                </Form.Item>

                <Form.Item label="Breaking News" name="breakingNews">
                    <Radio.Group>
                        <Radio value={true}> True </Radio>
                        <Radio value={false}> False </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.breakingNews !== currentValues.breakingNews
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('breakingNews') === true ? (
                            <Form.Item name="relevancy" label="Relevancy">
                                <Select placeholder="--Select Category--">
                                    {breakingNewsOptions?.map(
                                        (option, index) => (
                                            <Select.Option
                                                key={index}
                                                value={option.value}
                                            >
                                                {option.label}
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Form.Item>
                        ) : null
                    }
                </Form.Item>

                <Form.Item
                    label="Title"
                    name="title"
                    rules={[
                        {
                            required: true,
                            message:
                                'Value is required and should be less than 75 characters',
                        },
                    ]}
                >
                    <Input.TextArea
                        maxLength={75}
                        placeholder="Title of the article (max 75 chars) in regional language (ex:tamil,telugu)"
                    />
                </Form.Item>

                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: false,
                        },
                    ]}
                >
                    <Input.TextArea
                        maxLength={75}
                        placeholder="Description of the article upto 60 words"
                    />
                </Form.Item>

                <Form.Item
                    label="Web URL"
                    name="web_url"
                    rules={[
                        {
                            required: true,
                            message: 'This is required field',
                        },
                        {
                            type: 'url',
                            message: 'Please enter valid link',
                        },
                    ]}
                >
                    <Input
                        onChange={(e) => {
                            setWebUrl(e.target.value)
                        }}
                    />
                </Form.Item>

                <Form.Item label="Type" name="media_type">
                    <Select
                        onChange={handleMediaTypeChange}
                        value={mediaType}
                        defaultValue={mediaTypes[0]}
                    >
                        {mediaTypes?.map((option) => (
                            <Select.Option key={option} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item label="Subtype" name="subtype">
                    <Select onChange={handleSubTypeChange} value={subType}>
                        {subTypes?.map((option) => (
                            <Select.Option key={option} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item
                    style={{ marginBottom: '0px' }}
                    label="Image"
                    name="image"
                >
                    <div style={{ display: 'flex', gap: '5px' }}>
                        <ImageUploader getImagePath={getImagePath} />
                        <div>{isImageUploading && <LoadingOutlined />}</div>
                    </div>
                    {mode === 'edit' && (
                        <a href={form.getFieldValue('image')} target="_blank">
                            Uploaded Image
                        </a>
                    )}
                </Form.Item>
                {(
                    <Form.Item name="source" label="Source">
                        <AutoComplete
                            options={sourcesOptions}
                            value={sourceValue}
                            onSearch={(text) =>
                                setSourcesOptions(
                                    allSourcesOptions.filter(({ value }) =>
                                        value
                                            ?.toLowerCase()
                                            .includes(text?.toLowerCase())
                                    )
                                )
                            }
                            onSelect={onSelectSource}
                            placeholder="search and select source"
                        />
                        {sourceError && (
                            <div style={{ color: 'red' }}>
                                Source is mandatory
                            </div>
                        )}
                    </Form.Item>
                )}
                {(
                    <Form.Item label="Source Link" name="sourceLink">
                        <Input placeholder="Source UrlLink" />
                    </Form.Item>
                )}
                <Form.Item label="Keywords" name="keywords">
                    <div className="col-md" style={{ background: 'none' }}>
                        {keywords?.map((keyword, index) => (
                            <Tag
                                key={index}
                                closable
                                style={{
                                    userSelect: 'none',
                                    marginBottom: '5px',
                                }}
                                onClose={(e) => {
                                    e.preventDefault()
                                    handleClose(index)
                                }}
                            >
                                <span>{keyword}</span>
                            </Tag>
                        ))}
                        <Input
                            onChange={(e) => setKeywordInput(e.target.value)}
                            value={keywordInput}
                            onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault()
                                    addKeyword(event?.target?.value)
                                    setKeywordInput('')
                                }
                            }}
                            placeholder="Type words and press enter after each word"
                        />
                    </div>
                </Form.Item>

                <Form.Item label="Hashtag">
                    <ReactSelect
                        options={hashTagOptions}
                        value={hashTagValue}
                        onInputChange={onSearch}
                        onChange={onHashTagSelect}
                        placeholder="Select hashtag"
                        isSearchable
                    />
                </Form.Item>
                <Form.Item
                    name="expiryHours"
                    label="Expiry Hours"
                    rules={[{ required: true }]}
                >
                    <Select style={{ width: 200 }}>
                        {expiryHoursOptions?.map((option, index) => (
                            <Select.Option key={index} value={option}>
                                {option}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item
                    rules={[{ required: true }]}
                    label="Publish"
                    name="publish"
                >
                    <DatePicker
                        showTime
                        format="DD/MM/YYYY h:mm A"
                        disabledDate={(currentDate) => currentDate < new Date()}
                    />
                </Form.Item>
                <Form.Item label="Location Specific" name="locationSpecific">
                    <Radio.Group>
                        <Radio value="yes"> Yes </Radio>
                        <Radio value="no"> No </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues?.locationSpecific !==
                        currentValues?.locationSpecific
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('locationSpecific') === 'yes' ? (
                            <Form.Item
                                name="state"
                                label="State"
                                rules={[
                                    {
                                        required: true,
                                        message: 'please select an option',
                                    },
                                ]}
                            >
                                {locationsData && (
                                    <Select
                                        onChange={handleStateChange}
                                        placeholder="Select State"
                                        mode="multiple"
                                    >
                                        {locationsData?.map((option, index) => (
                                            <Select.Option
                                                key={index}
                                                value={option.state_id}
                                            >
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        ) : (
                            form.setFieldValue('state', [])
                        )
                    }
                </Form.Item>
                <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, currentValues) =>
                        prevValues.state !== currentValues.state
                    }
                >
                    {({ getFieldValue }) =>
                        getFieldValue('state') &&
                        getFieldValue('state')?.length !== 0 ? (
                            <Form.Item name="district" label="District">
                                <Select
                                    placeholder="Select District"
                                    mode="multiple"
                                >
                                    {districts?.map((option, index) => (
                                        <Select.Option
                                            key={index}
                                            value={option.name}
                                        >
                                            {option.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        ) : (
                            form.setFieldValue('district', [])
                        )
                    }
                </Form.Item>

                {/* {isPublish(userPermissions, 'Content', 'HTML') &&
                    languageOption !== 2 && (
                        <Form.Item
                            label="Proof read"
                            name="proofRead"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select an option',
                                },
                            ]}
                            className="custom-form-item"
                        >
                            <Radio.Group>
                                <Radio value={true}> Yes </Radio>
                                <Radio value={false}> No </Radio>
                            </Radio.Group>
                        </Form.Item>
                    )} */}

                <Form.Item wrapperCol={{ span: 48 }}>
                    {isSubmitAndDraft(userPermissions, 'Content', 'HTML') && (
                        <Button
                            className="bg-color"
                            type="primary"
                            onClick={() => handleSave('draft')}
                            disabled={isLoading}
                        >
                            Draft
                        </Button>
                    )}
                    {isSubmitAndDraft(userPermissions, 'Content', 'HTML') && (
                        <Button
                            className="bg-color submit-buttons"
                            type="primary"
                            onClick={() => handleSave('submit')}
                            disabled={isLoading}
                        >
                            Submit
                        </Button>
                    )}
                    {isPublish(userPermissions, 'Content', 'HTML') && (
                        <Button
                            className="bg-color"
                            type="primary"
                            htmlType="submit"
                            disabled={isLoading}
                        >
                            Publish
                        </Button>
                    )}
                </Form.Item>
            </Form>
        </div>
    )
}
export default AddHtmlContentForm
