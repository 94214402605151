import { Route, Routes, useLocation, useNavigate } from 'react-router';
import AddShortNewsNew from '../AddShortNews/AddShortNewsNew';
import { Tabs } from 'antd';
import "./writerDashboard.scss"
import { useEffect } from 'react';
const { TabPane } = Tabs;
export const WriterDashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const mode = searchParams.get('mode');

  const handleTabChange = (key) => {
    switch (key) {
      case '1':
        navigate('/home/writer-dashboard?mode=add');
        break;
      case '2':
        navigate('/home/writer-dashboard?mode=activity');
        break;
      default:
        break;
    }
  };
  useEffect(()=>{
    navigate("?mode=add")
  },[])
  
  const activeKey = mode === 'activity' ? '2' : '1';
  return (
    <>
      <Tabs
        activeKey={activeKey}
        onChange={handleTabChange}
        style={{ height: '2rem', padding: '0 1px', margin: '0 1.5rem 0 1rem' }}
        className="writer_tab"
      >
        <TabPane tab={<span style={{ color: 'gray', fontWeight: 'bold' }}>Add Content</span>} key="1" />
        <TabPane tab={<span style={{ fontWeight: 'bold', color: 'gray' }}>Your Activity</span>} key="2" />
      </Tabs>

      <Routes>
        <Route path="/home/writer-dashboard" element={<AddShortNewsNew />} />
        <Route path="/home/writer-dashboard/activity" element={<div>Your Activity Content Here</div>} />
      </Routes>
      {mode === 'activity' ? (
        <div>Your Activity Content Here</div>
      ) : (
        <AddShortNewsNew />
      )}
    </>
  );
};
